import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Address } from "../../../domain/models/address";
import { Contact } from "../../../domain/models/contact";
import { Doctor } from "../../../domain/models/doctor";
import { DoctorMaster } from "../../../domain/models/doctor-master";
import { PostOffice } from "../../../domain/models/post-office";
import { Result } from "../../../domain/models/result";
import { FetchPostalAddressForPincode } from "../../../domain/usages/fetch-postal-address-for-pincode";
import { UpdateDoctorPersonalDetails } from "../../../domain/usages/update-doctor-personal-details";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import TonalFixedButton from "../../components/common/buttons/tonal-fixed-button";
import {
  InputVariant,
  NumberField,
  Option,
  SelectField,
  TextField,
} from "../../components/inputs/index";
import PincodeToPostalField from "../../data-driven/common/pincode-to-postal-field";

type DoctorPersonalDetails = {
  _id: string;
  full_name: string;
  addresses: Address[];
  gender: string;
  age: string;
  mobile: string;
  email: string;
};

const convertDoctorToDetails = (doctor: Doctor) => {
  const details: DoctorPersonalDetails = {} as DoctorPersonalDetails;
  if (doctor.contacts && doctor.contacts.length > 0) {
    let mobile = doctor.contacts.find((c) => c.type == "mobile");
    let email = doctor.contacts.find((c) => c.type == "email");
    if (mobile) details.mobile = mobile.value;
    if (email) details.email = email.value;
  }
  details._id = doctor._id;
  details.full_name = doctor.full_name;
  details.gender = doctor.gender;
  details.age = doctor.age;
  details.addresses = doctor.addresses;
  return details;
};

type Props = {
  id: string;
  doctor: Doctor;
  master: DoctorMaster;
  updatePersonalDetails: UpdateDoctorPersonalDetails;
  fetchPostalAddressForPincode: FetchPostalAddressForPincode;
  successCallback: Function;
};
const DoctorPersonalDetailsForm: React.FC<Props> = ({
  id,
  doctor,
  master,
  updatePersonalDetails,
  fetchPostalAddressForPincode,
  successCallback,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [postOffice, setPostOffice] = useState<PostOffice>();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<DoctorPersonalDetails>({
    defaultValues: convertDoctorToDetails(doctor),
  });
  const storage = LocalJsonStorage.getInstance();
  const location = storage.get("lat-long");

  const onSubmit = useCallback(
    async (data: DoctorPersonalDetails) => {
      setLoading(true);
      const contacts = [];
      const address: Address = data.addresses[0];
      if (data.mobile) {
        contacts.push({ value: data.mobile, type: "mobile" } as Contact);
      }
      if (data.email) {
        contacts.push({ value: data.email, type: "email" } as Contact);
      }
      if (postOffice) {
        address.district = postOffice.District;
        address.state = postOffice.State;
        address.country = postOffice.Country;
        address.lat_long = location;
      }
      let result: Result = await updatePersonalDetails.update(id, {
        full_name: data.full_name,
        addresses: data.addresses,
        contacts: contacts,
        age: data.age,
        gender: data.gender,
      });
      if (!result.errors) {
        Swal.fire("Doctor details updated successfully.", "", "success");
        successCallback();
      } else {
        setLoading(false);
      }
    },
    [updatePersonalDetails, postOffice]
  );

  return (
    <>
      <div className="p-4">
        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              placeholder="Doctor Name"
              error={error && error.message}
              label="doctor Name"
            />
          )}
          rules={{ required: { value: true, message: "Name is required" } }}
        />

        <Controller
          name="age"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectField
              value={{ value: value, key: value } as Option}
              onChange={(value: any) => onChange(value.value)}
              options={master.ageGroups.map(
                (ag) => ({ key: ag.name, value: ag.name } as Option)
              )}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Age"
              label="Age"
            />
          )}
          rules={{
            required: { value: true, message: "Age is required" },
          }}
        />

        <Controller
          name="gender"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectField
              value={{ value: value, key: value } as Option}
              onChange={(value: any) => onChange(value.value)}
              options={master.genders.map(
                (ag) => ({ key: ag.name, value: ag.name } as Option)
              )}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Gender"
              label="Gender"
            />
          )}
          rules={{
            required: { value: true, message: "Gender is required" },
          }}
        />

        <Controller
          name="mobile"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              error={error && error.message}
              onChange={onChange}
              placeholder="Doctor's Mobile No."
              label="Doctor's Mobile No."
            />
          )}
          rules={{
            pattern: {
              value: /^[6-9]{1}[0-9]{9}$/,
              message: "Invalid mobile",
            },
          }}
        />

        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              error={error && error.message}
              onChange={onChange}
              placeholder="Doctor's Email id"
              label="Doctor's Email id"
            />
          )}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email",
            },
          }}
        />

        <Controller
          name="addresses.0.pincode"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PincodeToPostalField
              value={value}
              fetchPostalAddressForPincode={fetchPostalAddressForPincode}
              onChange={(postOffice: PostOffice) => {
                setPostOffice(postOffice);
                onChange(postOffice.pincode);
              }}
              error={error && error.message}
            />
          )}
          rules={{
            pattern: {
              value: /^[1-9]{1}[0-9]{5}$/,
              message: "Invalid pincode",
            },
            required: { value: true, message: "Pincode is required" },
          }}
        />

        <Controller
          name="addresses.0.line"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Building/Street"
              label="Building/Street"
            />
          )}
          rules={{
            required: { value: true, message: "Address line is required" },
          }}
        />

        <Controller
          name="addresses.0.landmark"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Landmark"
              label="Landmark"
            />
          )}
          rules={{ required: { value: true, message: "Landmark is required" } }}
        />
      </div>
      <TonalFixedButton
        icon={faSave}
        onClick={handleSubmit(onSubmit)}
        text="Click here to save details"
      />
    </>
  );
};

export default DoctorPersonalDetailsForm;
