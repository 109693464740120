import React, { useEffect, useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { Chemist } from "../../../domain/models/chemist";
import { Image } from "../../../domain/models/image";
import { UpdateDoctorChemist } from "../../../domain/usages/update-doctor-chemist";
import { Button } from "../../ga-components/buttons";
import ButtonColor from "../../ga-components/buttons/button-color";
import { NumberField, TextField } from "../inputs/index";
import Swal from "sweetalert2";
import { Result } from "../../../domain/models/result";
import { UpdateChemistVerification } from "../../../domain/usages/update-chemist-verification";
import { Constants } from "../../../common/Constants";
import Utils from "../../../common/Utils";
import SweetAlertMessageType from "../../../common/constants-types/sweet-alert-message";
import ClinicChemistVerificationModal from "../../pages/sections/doctor/modal/ClinicChemistVerificationModal";

type ChemistDetails = {
  _id: string;
  full_name: string;
  mobile: string;
  no_of_staff: number;
  billboard: Image;
  image: string;
};

const convertChemistToDetails = (chemist: Chemist) => {
  const chemistDetils: ChemistDetails = {} as ChemistDetails;
  chemistDetils._id = chemist._id;
  chemistDetils.full_name = chemist.full_name;
  chemistDetils.mobile = chemist.mobile;
  chemistDetils.no_of_staff = chemist.no_of_staff;
  if (chemist.billboard && chemist.billboard.length > 0)
    chemistDetils.image = chemist.billboard[0].image_path;
  return chemistDetils;
};

type Props = {
  chemist: Chemist;
  id: string;
  updateChemist: UpdateDoctorChemist;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  updateChemistVerification: UpdateChemistVerification;
};
const DoctorChemistInformationForm: React.FC<Props> = ({
  chemist,
  id,
  updateChemist,
  setLoading,
  updateChemistVerification,
}) => {
  const { handleSubmit, control, setValue } = useForm<ChemistDetails>({
    mode: "onChange",
    defaultValues: convertChemistToDetails(chemist),
  });
  const [modalOn, setModalOn] = useState(false);
  const [isChemistRejected, setIsChemistRejected] = useState(false);

  useEffect(() => {
    setValue("full_name", chemist.full_name);
    setValue("mobile", chemist.mobile);
    setValue("no_of_staff", chemist.no_of_staff);
  }, [chemist]);

  const update = useCallback(
    async (data: any) => {
      setLoading(true);

      let result: Result = await updateChemist.update(id, chemist._id, {
        full_name: data.full_name,
        mobile: data.mobile,
        no_of_staff: data.no_of_staff,
        billboard: data.billboard,
      });
      if (!result.errors) {
        setLoading(false);
        Swal.fire(
          Constants.CHEMIST_UPDATE_SUCCESS,
          "",
          SweetAlertMessageType.SUCCESS
        );
      } else {
        Swal.fire(
          Constants.CHEMIST_UPDATE_FAILED,
          "",
          SweetAlertMessageType.ERROR
        );
      }
    },
    [updateChemist, chemist]
  );

  const updateStatus = useCallback(
    async (status, rejectReason) => {
      setLoading(true);
      let result: Result = await updateChemistVerification.update(chemist._id, {
        verification_status: status,
        rejection_reason: rejectReason.value,
      });
      if (!result.errors) {
        setLoading(false);
        Swal.fire(
          Constants.CHEMIST_VERFICATION_SUCCESS,
          "",
          SweetAlertMessageType.SUCCESS
        );
      } else {
        Swal.fire(
          Constants.CHEMIST_VERFICATION_FAILED,
          "",
          SweetAlertMessageType.ERROR
        );
      }
    },
    [updateChemistVerification, chemist]
  );

  const onAppove = () => {
    setModalOn(true);
    setIsChemistRejected(false);
  };

  const onReject = () => {
    setModalOn(true);
    setIsChemistRejected(true);
  };

  return (
    <>
      <div className="p-4">
        {Utils.isChemistPending(chemist.verification_status) ? (
          <div className="gap-x-5">
            <Button
              onClick={onAppove}
              color={ButtonColor.SUCCESS}
              text="Approve"
              className="hover:bg-secondary hover:-translate-y-1 transform transition  focus:bg-secondary_low focus:text-primary mr-2"
            />
            <Button
              onClick={onReject}
              color={ButtonColor.ERROR}
              text="Reject"
              className="hover:bg-secondary hover:-translate-y-1 transform transition  focus:bg-secondary_low focus:text-primary"
            />
          </div>
        ) : null}

        {Utils.isChemistVerified(chemist.verification_status) && (
          <>
            <span className="text-green-600 text-lg">
              <strong>Chemist Approved</strong>
            </span>
          </>
        )}

        {Utils.isChemistRejected(chemist.verification_status) && (
          <>
            <span className="text-red-600 text-lg mr-2">
              <strong>Chemist Rejected</strong>
            </span>
            <br />
            <span>
              <strong className="mr-2">Reason:</strong>
              {chemist.rejection_reason}
            </span>
          </>
        )}

        {modalOn && (
          <ClinicChemistVerificationModal
            setModalOn={setModalOn}
            successCallback={updateStatus}
            showRejectMessage={isChemistRejected}
            reasonsForRejection={[
              Constants.IMAGE_NOT_CLEAR,
              Constants.NOT_SHOP_IMAGE,
            ]}
            data={chemist.full_name}
          />
        )}

        <div className="mt-5">
          <span>
            <strong>Chemist Details:</strong>
          </span>
        </div>
        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={error && error.message}
              placeholder="Chemist Name"
              label="Chemist Name"
            />
          )}
          rules={{
            required: {
              value: true,
              message: Constants.FIELD_VALIDATION_ERROR,
            },
          }}
        />

        <Controller
          name="mobile"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              error={error && error.message}
              onChange={onChange}
              placeholder="Mobile No."
              label="Mobile No."
            />
          )}
          rules={{
            pattern: {
              value: /^[6-9]{1}[0-9]{9}$/,
              message: "Invalid mobile",
            },
            required: {
              value: true,
              message: Constants.FIELD_VALIDATION_ERROR,
            },
          }}
        />

        <Controller
          name="no_of_staff"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              error={error && error.message}
              onChange={onChange}
              placeholder="No of Staff"
              label="No of Staff"
            />
          )}
          rules={{
            required: {
              value: true,
              message: Constants.FIELD_VALIDATION_ERROR,
            },
          }}
        />
      </div>
      {Utils.isChemistPending(chemist.verification_status) && (
        <Button
          onClick={handleSubmit(update)}
          color={ButtonColor.PRIMARY}
          text="SAVE CHEMIST DETAILS"
        />
      )}
    </>
  );
};

export default DoctorChemistInformationForm;
