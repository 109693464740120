import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import withTheme from "../../../ga-components/theme/withTheme";

export enum IconTypes {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  WHITE = "white",
}

export enum IconSize {
  xs = "xs",
  sm = "sm",
  xl = "xl",
  xl2 = "xl2",
}

export type Props = {
  theme: any;
  icon: any;
  type: IconTypes;
  size: IconSize;
  className?: string;
};
const Icon: React.FC<Props> = ({
  theme,
  icon,
  type = IconTypes.PRIMARY,
  size = IconSize.xs,
  className = "",
}) => {
  const classes = [className];
  classes.push(theme.text[type]);
  classes.push(theme.size[size]);
  return <FontAwesomeIcon icon={icon} className={`${classes.join(" ")}`} />;
};

export default withTheme(Icon);
