import { useParams } from "react-router-dom";
import {
  AUTH_HEADER,
  AUTH_TOKEN,
  HOSPITAL_DOCTOR_SERVICE_API_URL,
} from "../base";
import { RemoteAddHospitalDoctor } from "../data/usecases/remote-add-hospital-doctor";
import { RemoteAddHospitalDoctorToGoVaxx } from "../data/usecases/remote-add-hospital-doctor-to-govaxx";
import { RemoteHospitalDoctorVaccinator } from "../data/usecases/remote-hospital-doctor-vaccinator";
import { RemoteFetchHospitalMasters } from "../data/usecases/remote-fetch-hospital-masters";
import { RemoteFindHospital } from "../data/usecases/remote-find-hospital";
import { RemoteRemoveHospitalDoctorFromGoVaxx } from "../data/usecases/remote-remove-hospital-doctor-from-govaxx";
import { RemoteUpdateHospitalCommercialDetails } from "../data/usecases/remote-update-hospital-commercial-details";
import { RemoteUpdateHosptialCompetitionDetails } from "../data/usecases/remote-update-hospital-competition-details";
import { RemoteUpdateHospitalDoctor } from "../data/usecases/remote-update-hospital-doctor";
import { RemoteUpdateHospitalPhysicalDetails } from "../data/usecases/remote-update-hospital-physical-details";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import HospitalProfilePage from "../presentation/pages/hospital-profile-page";

type Props = {};

export const HospitalProfilePageFactory = (props: Props) => {
  const { id } = useParams();
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: `Bearer ${atob(token)}` });

  const remoteUpdatePhysicalDetails = new RemoteUpdateHospitalPhysicalDetails(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals/:id/update-physical-details`,
    axiosHttpClient
  );

  const remoteUpdateCommercialDetails =
    new RemoteUpdateHospitalCommercialDetails(
      `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals/:id/update-commercial-details`,
      axiosHttpClient
    );

  const remoteUpdateCompetitionDetails =
    new RemoteUpdateHosptialCompetitionDetails(
      `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals/:id/update-competition-details`,
      axiosHttpClient
    );

  const remoteAddHospitalDoctor = new RemoteAddHospitalDoctor(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals/:id/doctors`,
    axiosHttpClient
  );

  const remoteUpdateHospitalDoctor = new RemoteUpdateHospitalDoctor(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals/:id/doctors/:doctor_id`,
    axiosHttpClient
  );

  const remoteFindHospital = new RemoteFindHospital(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals/:id`,
    axiosHttpClient
  );

  const remoteFetchHospitalMaster = new RemoteFetchHospitalMasters(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/masters/hospitals`,
    axiosHttpClient
  );

  const remoteAddHospitalDoctorToGoVaxx = new RemoteAddHospitalDoctorToGoVaxx(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals/:id/doctors/:doctor_id/add-to-govaxx`,
    axiosHttpClient
  );

  const remoteHospitalDoctorVaccinator = new RemoteHospitalDoctorVaccinator(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals/:id/doctors/:doctor_id/vaccinator`,
    axiosHttpClient
  );

  const remoteRemoveHospitalDoctorFromGoVaxx =
    new RemoteRemoveHospitalDoctorFromGoVaxx(
      `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals/:id/doctors/:doctor_id/remove-from-govaxx`,
      axiosHttpClient
    );

  if (!id) return <div>Please try after sometime</div>;
  return (
    <HospitalProfilePage
      id={id}
      updatePhysicalDetails={remoteUpdatePhysicalDetails}
      updateCommercialDetails={remoteUpdateCommercialDetails}
      updateCompetitionDetails={remoteUpdateCompetitionDetails}
      addDoctor={remoteAddHospitalDoctor}
      addHospitalDoctorToGoVaxx={remoteAddHospitalDoctorToGoVaxx}
      HospitalDoctorVaccinator={remoteHospitalDoctorVaccinator}
      updateDoctor={remoteUpdateHospitalDoctor}
      findHospital={remoteFindHospital}
      removeHospitalDoctorFromGoVaxx={remoteRemoveHospitalDoctorFromGoVaxx}
      fetchMasters={remoteFetchHospitalMaster}
      {...props}
    />
  );
};
