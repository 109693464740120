import React from "react";
import IconButton from "../common/buttons/icon-button";

export enum ProfileStatus {
  COMPLETE,
  INCOMPLETE,
}

type Props = {
  text: any;
  icon: any;
  status: ProfileStatus;
};
const ProfileSectionButton: React.FC<Props> = ({ text, icon, status }) => {
  if (status === ProfileStatus.COMPLETE)
    return (
      <IconButton
        text={text}
        icon={icon}
        primary={true}
        size={"sm"}
        onClick={() => {}}
      />
    );
  else
    return (
      <IconButton
        text={text}
        icon={icon}
        primary={false}
        size={"lg"}
        onClick={() => {}}
      />
    );
};

export default ProfileSectionButton;
