import { LocalJsonStorage } from "../infra/http/local-json-storage";
import HomePage from "../presentation/pages/home-page";

type Props = {
  onSelect: Function;
  selected?: number;
};

export const HomeFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  return <HomePage jsonStorage={storage} {...props} />;
};
