import React, { useEffect, useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { Clinic } from "../../../domain/models/clinic";
import { Image } from "../../../domain/models/image";
import { Button } from "../../ga-components/buttons";
import ButtonColor from "../../ga-components/buttons/button-color";
import { NumberField, TextField, Option } from "../inputs/index";
import SelectField from "../../ga-components/input/select";
import { Speciality } from "../../../domain/models/speciality";
import { UpdateDoctorClinic } from "../../../domain/usages/update-doctor-clinic";
import Swal from "sweetalert2";
import { Result } from "../../../domain/models/result";
import ClinicChemistVerificationModal from "../../pages/sections/doctor/modal/ClinicChemistVerificationModal";
import { UpdateClinicVerification } from "../../../domain/usages/update-clinic-verification";
import { Constants } from "../../../common/Constants";
import Utils from "../../../common/Utils";
import SweetAlertMessageType from "../../../common/constants-types/sweet-alert-message";

type ClinicDetails = {
  _id: string;
  full_name: string;
  mobile: string;
  no_of_staff: number;
  billboard: Image;
  image: string;
  speciality: { value: string; label: string };
};

const convertClinicToDetails = (clinic: Clinic) => {
  const clinicDetils: ClinicDetails = {} as ClinicDetails;
  clinicDetils._id = clinic._id;
  clinicDetils.full_name = clinic.full_name;
  clinicDetils.mobile = clinic.mobile;
  clinicDetils.no_of_staff = clinic.no_of_staff;
  if (clinic.billboard && clinic.billboard.length > 0)
    clinicDetils.image = clinic.billboard[0].image_path;
  return clinicDetils;
};

type Props = {
  clinic: Clinic;
  specialities: Speciality[];
  updateClinic: UpdateDoctorClinic;
  id: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  updateClinicVerification: UpdateClinicVerification;
};

const DoctorClinicInformationForm: React.FC<Props> = ({
  id,
  clinic,
  updateClinic,
  specialities,
  setLoading,
  updateClinicVerification,
}) => {
  const { handleSubmit, control, setValue } = useForm<ClinicDetails>({
    mode: "onChange",
    defaultValues: convertClinicToDetails(clinic),
  });

  const [modalOn, setModalOn] = useState(false);
  const [isClinicRejected, setIsClinicRejected] = useState(false);

  useEffect(() => {
    setValue("full_name", clinic.full_name);
    setValue("mobile", clinic.mobile);
    setValue("no_of_staff", clinic.no_of_staff);
    setValue("speciality", {
      value: clinic.speciality,
      label: clinic.speciality,
    });
  }, [clinic]);

  const update = useCallback(
    async (data: any) => {
      setLoading(true);
      let result: Result = await updateClinic.update(id, clinic._id, {
        full_name: data.full_name,
        mobile: data.mobile,
        no_of_staff: data.no_of_staff,
        billboard: data.billboard,
        speciality: data.speciality.value,
      });
      if (!result.errors) {
        setLoading(false);
        Swal.fire(
          Constants.CLINIC_UPDATE_SUCCESS,
          "",
          SweetAlertMessageType.SUCCESS
        );
      } else {
        Swal.fire(
          Constants.CLINIC_UPDATE_FAILED,
          "",
          SweetAlertMessageType.ERROR
        );
      }
    },
    [updateClinic, clinic]
  );

  const updateStatus = useCallback(
    async (status, rejectionReason) => {
      setLoading(true);
      let result: Result = await updateClinicVerification.update(clinic._id, {
        verification_status: status,
        rejection_reason: rejectionReason.value,
      });
      if (!result.errors) {
        setLoading(false);
        Swal.fire(
          Constants.CLINIC_VERFICATION_SUCCESS,
          "",
          SweetAlertMessageType.SUCCESS
        );
      } else {
        Swal.fire(
          Constants.CLINIC_VERFICATION_FAILED,
          "",
          SweetAlertMessageType.ERROR
        );
      }
    },
    [updateClinicVerification, clinic]
  );

  const onAppove = () => {
    if (!Utils.isClinicVerified(clinic.verification_status)) {
      setModalOn(true);
      setIsClinicRejected(false);
    }
  };

  const onReject = () => {
    if (!Utils.isClinicRejected(clinic.verification_status)) {
      setModalOn(true);
      setIsClinicRejected(true);
    }
  };

  return (
    <>
      <div className="p-4">
        {!Utils.isClinicVerified(clinic.verification_status) &&
        !Utils.isClinicRejected(clinic.verification_status) ? (
          <div className="gap-x-5">
            <Button
              onClick={onAppove}
              color={ButtonColor.SUCCESS}
              text="Approve"
              className="hover:bg-secondary hover:-translate-y-1 transform transition  focus:bg-secondary_low focus:text-primary mr-2"
            />
            <Button
              onClick={onReject}
              color={ButtonColor.ERROR}
              text="Reject"
              className="hover:bg-secondary hover:-translate-y-1 transform transition  focus:bg-secondary_low focus:text-primary"
            />
          </div>
        ) : null}

        {clinic.addresses && clinic.addresses.length > 0 && (
          <>
            <span className="text-green-600 text-lg">
              <strong>Geo Address Verified</strong>
            </span>
            <br></br>
          </>
        )}

        {clinic.addresses && clinic.addresses.length > 0 && (
          <>
            <span className="text-gray-900 text-base">
              <strong>Address: </strong>
            </span>
            <span className="text-gray-600 text-xs">
              {clinic.addresses[0].line}
            </span>
            <br></br>
          </>
        )}

        {Utils.isClinicVerified(clinic.verification_status) && (
          <>
            <span className="text-green-600 text-lg">
              <strong>Clinic Approved</strong>
            </span>
          </>
        )}

        {Utils.isClinicRejected(clinic.verification_status) && (
          <>
            <span className="text-red-600 text-lg mr-2">
              <strong>Clinic Rejected</strong>
            </span>
            <br />
            <span>
              <strong className="mr-2">Reason:</strong>
              {clinic.rejection_reason}
            </span>
          </>
        )}

        {modalOn && (
          <ClinicChemistVerificationModal
            setModalOn={setModalOn}
            successCallback={updateStatus}
            data={clinic.full_name}
            showRejectMessage={isClinicRejected}
            reasonsForRejection={[
              Constants.IMAGE_NOT_CLEAR,
              Constants.NOT_SHOP_IMAGE,
            ]}
          />
        )}

        <div className="mt-2">
          <span>
            <strong>Clinic Details:</strong>
          </span>
        </div>
        <Controller
          name="speciality"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectField
              label="Select Speciality"
              valueData={value}
              name="Select Speciality"
              placeholder="Select Speciality"
              options={specialities.map(
                (s) => ({ key: s.name, value: s.name } as Option)
              )}
              onChange={(value: any) => onChange(value)}
              error={error && error.message}
            />
          )}
          rules={{
            required: {
              value: true,
              message: Constants.FIELD_VALIDATION_ERROR,
            },
          }}
        />

        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={error && error.message}
              placeholder="Clinic Name"
              label="Clinic Name"
            />
          )}
          rules={{
            required: {
              value: true,
              message: Constants.FIELD_VALIDATION_ERROR,
            },
          }}
        />

        <Controller
          name="mobile"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              error={error && error.message}
              onChange={onChange}
              placeholder="Mobile No."
              label="Mobile No."
            />
          )}
          rules={{
            pattern: {
              value: /^[6-9]{1}[0-9]{9}$/,
              message: "Invalid mobile",
            },
            required: {
              value: true,
              message: Constants.FIELD_VALIDATION_ERROR,
            },
          }}
        />

        <Controller
          name="no_of_staff"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              error={error && error.message}
              onChange={onChange}
              placeholder="No of Staff"
              label="No of Staff"
            />
          )}
          rules={{
            required: {
              value: true,
              message: Constants.FIELD_VALIDATION_ERROR,
            },
          }}
        />
      </div>
      {Utils.isClinicPending(clinic.verification_status) && (
        <Button
          onClick={handleSubmit(update)}
          color={ButtonColor.PRIMARY}
          text="SAVE CLINIC DETAILS"
        />
      )}
    </>
  );
};

export default DoctorClinicInformationForm;
