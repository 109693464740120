import React from "react";
import AsyncSelect from "react-select/async";
import { InputVariant } from ".";
import withTheme from "../../ga-components/theme/withTheme";
import InputGroup from "./input-group";
import { Option } from "./option";

// TODO:: This field is not testing need to test with one of the field.
export type AutoCompleteOption = {
  label: string;
  value: string;
};

type Props = {
  theme: any;
  value: Option;
  options: Option[];
  loadOptions: (inputValue: number) => Promise<AutoCompleteOption[]>;
  onChange: Function;
  variant?: InputVariant;
  label?: string;
  placeholder?: string;
  error?: string;
  className?: string;
};
const AutoCompleteField: React.FC<Props> = ({
  theme,
  value,
  options,
  onChange,
  loadOptions,
  variant = InputVariant.FILLED,
  label = "",
  error = "",
  className = "",
}) => {
  const classes = [className];
  if (error) {
    classes.push(theme.input[variant].errorField);
  } else {
    classes.push(theme.input[variant].select);
  }

  const onOptionChange = (value: any) => {
    const selectedOption = options.find((o) => o.value == value.value);
    if (selectedOption) onChange(selectedOption);
  };

  const autocomplete = (
    inputValue: string,
    callback: (options: AutoCompleteOption[]) => void
  ) => {
    loadOptions(parseInt(inputValue)).then((result: AutoCompleteOption[]) =>
      callback(result)
    );
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
    }),
  };
  return (
    <InputGroup label={label} error={error}>
      <AsyncSelect
        loadOptions={autocomplete}
        defaultValue={value && { value: value.key, label: value.value }}
        styles={customStyles}
        className={`${classes.join(" ")}`}
        onInputChange={(data) => onOptionChange(data)}
      />
    </InputGroup>
  );
};

export default withTheme(AutoCompleteField);
