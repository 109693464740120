import { useParams } from "react-router-dom";
import {
  AUTH_HEADER,
  AUTH_TOKEN,
  HOSPITAL_DOCTOR_SERVICE_API_URL,
} from "../base";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import { RemoteFetchDoctorList } from "../data/usecases/remote-fetch-doctor-list";
import { RemoteFindDoctor } from "../data/usecases/remote-find-doctor";
import { RemoteGetReadLink } from "../data/usecases/remote-get-read-link";
import { RemoteFetchDoctorMasters } from "../data/usecases/remote-fetch-doctor-masters";
import { RemoteUpdateDoctorClinic } from "../data/usecases/remote-update-doctor-clinic";
import { RemoteUpdateDoctorChemist } from "../data/usecases/remote-update-doctor-chemist";
import { RemoteAddDoctorListToClinic } from "../data/usecases/remote-add-doctor-to-clinic";
import { RemoteUpdateDoctorVerification } from "../data/usecases/remote-doctor-verification";
import { RemoteUpdateClinicVerification } from "../data/usecases/remote-clinic-verification";
import { RemoteUpdateChemistVerification } from "../data/usecases/remote-chemist-verification";
import DoctorInfoPage from "../presentation/pages/DoctorsInfo/DoctorInfoPage";
import { RemoteUpdateDoctorSamplingVerification } from "../data/usecases/remote-doctor-sampling-verification";

type Props = {};

export const DoctorInformationFactory = (props: Props) => {
  const { id } = useParams();
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: `Bearer ${atob(token)}` });

  const remoteFetchDoctorList = new RemoteFetchDoctorList(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors`,
    axiosHttpClient
  );

  const remoteFindDoctor = new RemoteFindDoctor(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id`,
    axiosHttpClient
  );

  const remoteGetReadLink = new RemoteGetReadLink(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/uploads/read-url/:id`,
    axiosHttpClient
  );

  const remoteFetchDoctorMasters = new RemoteFetchDoctorMasters(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/masters/doctors`,
    axiosHttpClient
  );

  const remoteUpdateClinic = new RemoteUpdateDoctorClinic(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/clinics/:clinic_id`,
    axiosHttpClient
  );
  const remoteUpdateDoctorVerification = new RemoteUpdateDoctorVerification(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/doctor-status`,
    axiosHttpClient
  );
  const remoteUpdateDoctorSamplingVerification =
    new RemoteUpdateDoctorSamplingVerification(
      `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/verification`,
      axiosHttpClient
    );
  const remoteUpdateClinicVerification = new RemoteUpdateClinicVerification(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:clinic_id/clinic-status`,
    axiosHttpClient
  );
  const remoteUpdateChemistVerification = new RemoteUpdateChemistVerification(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:chemist_id/chemist-status`,
    axiosHttpClient
  );

  const remoteAddDoctorToClinic = new RemoteAddDoctorListToClinic(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/update-clinic-doctors`,
    axiosHttpClient
  );
  const remoteUpdateChemist = new RemoteUpdateDoctorChemist(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/chemists/:chemist_id`,
    axiosHttpClient
  );

  return (
    <DoctorInfoPage
      fetchDoctorList={remoteFetchDoctorList}
      getReadLink={remoteGetReadLink}
      findDoctor={remoteFindDoctor}
      fetchMasters={remoteFetchDoctorMasters}
      updateClinic={remoteUpdateClinic}
      updateChemist={remoteUpdateChemist}
      addDoctorToClinic={remoteAddDoctorToClinic}
      updateDoctorVerification={remoteUpdateDoctorVerification}
      updateClinicVerification={remoteUpdateClinicVerification}
      updateChemistVerification={remoteUpdateChemistVerification}
      updateDoctorSamplingVerification={remoteUpdateDoctorSamplingVerification}
      {...props}
    />
  );
};
