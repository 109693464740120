import { Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DoctorSummaryCard from "../../components/data-entry/doctorProfilerVerifcation/DoctorSummaryCard";
import PersonIcon from "@mui/icons-material/Person";
import { FetchVerificationCounts } from "../../../domain/usages/data-entry/doctor-verification/fetch-verification-counts";
import { VerificationCounts } from "../../../domain/models/data-entry/verificationCounts";
import TodayIcon from "@mui/icons-material/Today";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ImageIcon from "@mui/icons-material/Image";
import FetchState from "../../../domain/enums/fetch-state-type";
import DoctorSummaryCardLoading from "../../components/data-entry/doctorProfilerVerifcation/DoctorSummaryCardLoading";

type Props = {
  doctorVerficationCounts: FetchVerificationCounts;
};

const DoctorCountSummary: React.FC<Props> = ({ doctorVerficationCounts }) => {
  const [countSummary, setCountSummary] = useState<VerificationCounts>(
    {} as VerificationCounts
  );
  const [fetchState, setFetchState] = useState<FetchState>(FetchState.DEFAULT);

  const fetchVerificationCounts = async () => {
    setFetchState(FetchState.LOADING);
    let result = await doctorVerficationCounts.fetch();
    if (result.success) {
      setCountSummary(result.data);
      setFetchState(FetchState.SUCCESS);
    }
  };

  useEffect(() => {
    fetchVerificationCounts();
  }, []);

  return (
    <>
      <Stack
        marginTop={10}
        spacing={5}
        direction={"row"}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {fetchState === FetchState.LOADING ? (
          <>
            <DoctorSummaryCardLoading /> <DoctorSummaryCardLoading />
          </>
        ) : (
          <>
            <DoctorSummaryCard
              title="Verified Doctors"
              count={countSummary.verifiedDoctorsCount}
              type="Doctors"
              avatar={<PersonIcon fontSize="large" />}
            />
            <DoctorSummaryCard
              title="Today's Verified Doctors"
              count={countSummary.todaysVerifiedCount}
              type="Doctors"
              avatar={<TodayIcon fontSize="large" />}
            />
          </>
        )}
      </Stack>
      <Stack
        marginTop={5}
        spacing={5}
        direction={"row"}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {fetchState === FetchState.LOADING ? (
          <>
            <DoctorSummaryCardLoading /> <DoctorSummaryCardLoading />
          </>
        ) : (
          <>
            <DoctorSummaryCard
              title="Pending Doctors"
              count={countSummary.pendingDoctors}
              type="Doctors"
              avatar={<HourglassTopIcon fontSize="large" />}
            />
            <DoctorSummaryCard
              title="Pending Doctors With Images"
              count={countSummary.pendingDoctorsWithImages}
              type="Doctors"
              avatar={<ImageIcon fontSize="large" />}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default DoctorCountSummary;
