import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import withTheme from "../theme/withTheme";
import NavModel from "./nav-model";

type NavProps = {
  theme: any;
  item: NavModel;
  className?: string;
};
const NavItem: React.FC<NavProps> = ({ theme, item, className = "" }) => {
  const navigate = useNavigate();
  const cssClass = [
    className,
    theme.nav.item.base,
    item.active ? theme.nav.item.active : theme.nav.item.inActive,
  ].join(" ");

  const onClick = () => {
    navigate(item.link);
  };

  return (
    <li>
      <a className={cssClass} onClick={onClick}>
        {item.icon && item.icon}
        {item.text && item.text}
      </a>
    </li>
  );
};

export default withTheme(NavItem);
