import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import indexRoutes, { pageRoutes } from "./routes";

const App: React.FC = () => {
  return (
    <div className="justify-center h-screen">
      <Routes>
        <Route
          path="/"
          element={<Navigate to={pageRoutes.dataEntryVerficationLogin} />}
        />
        {indexRoutes.map((prop, key) => {
          return (
            <Route
              key={key}
              path={prop.path}
              element={React.createElement(prop.component)}
            >
              {prop.children &&
                prop.children.map((prop, key) => {
                  return (
                    <Route
                      key={key}
                      path={prop.path}
                      element={React.createElement(prop.component)}
                    ></Route>
                  );
                })}
            </Route>
          );
        })}
      </Routes>
    </div>
  );
};

export default App;
