import React from "react";
import { LocalLoggedInUser } from "./data/usecases/local-logged-in-user";
import { LoggedInUser } from "./domain/usages/logged-in-user";
import { LocalJsonStorage } from "./infra/http/local-json-storage";

export interface InjectedLoggedInUserProps {
  loggedInUser: LoggedInUser;
}

const withLoggedInUser = <P extends InjectedLoggedInUserProps>(
  Component: React.ComponentType<P>
) => {
  const storage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(storage);
  const WithLoggedInUser: React.FC<Omit<P, keyof InjectedLoggedInUserProps>> = (
    props
  ) => <Component {...(props as P)} loggedInUser={loggedInUser} />;

  WithLoggedInUser.displayName = `WithTheme(${Component.displayName})`;
  WithLoggedInUser.propTypes = Component.propTypes;
  return WithLoggedInUser;
};

export default withLoggedInUser;
