import React from "react";
import withTheme from "../../../ga-components/theme/withTheme";
import Icon, { IconSize, IconTypes } from "../typography/icon";
import Label, { LabelSize, LabelTypes } from "../typography/label";

export type NavMenuItem = {
  id: number;
  icon: any;
  title: string;
};

export enum NavItemType {
  ACTIVE = "active",
  IN_ACTIVE = "",
}

type Props = {
  theme: any;
  item: NavMenuItem;
  onClick: Function;
  type: NavItemType;
  className?: string;
};

const NavItem: React.FC<Props> = ({
  theme,
  item,
  onClick,
  type,
  className = "",
}) => {
  const classes = [className];
  classes.push(type);

  const selectItem = () => {
    onClick(item.id);
  };

  return (
    <li
      className={`flex-grow w-90 h-90 z-10 ${classes.join(" ")}`}
      onClick={selectItem}
    >
      <a className="relative w-full h-full flex flex-col justify-center items-center">
        <Icon
          icon={item.icon}
          size={IconSize.xl2}
          className="icon"
          type={IconTypes.WHITE}
        />
        {type === NavItemType.ACTIVE && (
          <Label
            text={item.title}
            size={LabelSize.sm}
            className="absolute tracking-wider font-bold mt-4"
            type={LabelTypes.WHITE}
          />
        )}
      </a>
    </li>
  );
};
export default withTheme(NavItem);
