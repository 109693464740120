import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React, { useCallback, useEffect, useState } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import FlipIcon from "@mui/icons-material/Flip";
import CircularProgress from "@mui/material/CircularProgress";
import { Tooltip, Typography } from "@mui/material";
import FetchState from "../../../../domain/enums/fetch-state-type";
import { GetReadLink } from "../../../../domain/usages/get-read-link";
import ExpandIcon from "@mui/icons-material/Expand";

type Props = {
  uuid?: string;
  getReadLink: GetReadLink;
  imageWidth?: string;
  imageHeight?: string;
  id?: string;
  iconSize?: "small" | "medium" | "large";
  handleImageDraggable?: Function;
};

const ImageDisplay: React.FC<Props> = ({
  uuid = "",
  id,
  getReadLink,
  imageWidth,
  imageHeight,
  iconSize = "small",
  handleImageDraggable = () => {},
}) => {
  const [flip, setFlip] = useState(false);
  const [imageLink, setImageLink] = useState("");
  const [rotateImage, setRotateImage] = useState(0);
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);

  const rotate = () => {
    if (rotateImage < 360) {
      setRotateImage(rotateImage + 90);
    } else {
      setRotateImage(90);
    }
  };

  const getImage = useCallback(
    async (uuid: string) => {
      try {
        setFetchState(FetchState.LOADING);
        if (uuid) {
          let result = await getReadLink.get(uuid, {});
          setFetchState(FetchState.SUCCESS);
          setImageLink(result.url);
        }
      } catch (err) {
        setFetchState(FetchState.ERROR);
      }
    },
    [getReadLink]
  );

  useEffect(() => {
    if (uuid) {
      getImage(uuid);
    }
  }, [id]);

  return (
    <TransformWrapper initialScale={1}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <div>
          {fetchState === FetchState.LOADING && (
            <div
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
                height: imageHeight ? `${imageHeight}` : `200px`,
                width: imageWidth ? `${imageWidth}` : `400px`,
              }}
            >
              <CircularProgress color="warning" />
            </div>
          )}
          {fetchState === FetchState.ERROR && (
            <>
              <Typography color="error">something went wrong</Typography>
            </>
          )}
          <TransformComponent>
            {fetchState === FetchState.SUCCESS && (
              <div
                style={
                  flip
                    ? {
                        transform: "scaleX(-1)",
                        width: `${imageWidth}`,
                        height: `${imageHeight}`,
                      }
                    : {
                        transform: "scaleX(1)",
                        width: `${imageWidth}`,
                        height: `${imageHeight}`,
                      }
                }
              >
                <img
                  style={{
                    transform: `rotate(${rotateImage}deg)`,
                    width: `${imageWidth}`,
                    height: `${imageHeight}`,
                  }}
                  src={imageLink}
                  alt=""
                />
              </div>
            )}
          </TransformComponent>
          <div>
            <div
              style={{
                display: "flex",
                margin: 10,
                backgroundColor: "white",
                justifyContent: "center",
              }}
            >
              <Tooltip title="Flip">
                <div style={{ padding: 6, cursor: "pointer" }}>
                  <FlipIcon
                    fontSize={iconSize}
                    color={"info"}
                    onClick={() => {
                      setFlip(!flip);
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Zoom In">
                <div style={{ padding: 6, cursor: "pointer" }}>
                  <ZoomInIcon
                    fontSize={iconSize}
                    color={"info"}
                    onClick={() => zoomIn()}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Zoom Out">
                <div style={{ padding: 6, cursor: "pointer" }}>
                  <ZoomOutIcon
                    fontSize={iconSize}
                    color={"info"}
                    onClick={() => zoomOut()}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Rotate">
                <div style={{ padding: 6, cursor: "pointer" }}>
                  <RotateRightIcon
                    fontSize={iconSize}
                    color={"info"}
                    onClick={rotate}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Reset">
                <div style={{ padding: 6, cursor: "pointer" }}>
                  <RestartAltIcon
                    fontSize={iconSize}
                    color={"info"}
                    onClick={() => resetTransform()}
                  />
                </div>
              </Tooltip>
              <div style={{ padding: 6, cursor: "pointer" }}>
                <Tooltip title="Draggable Image Box">
                  <ExpandIcon onClick={() => handleImageDraggable(imageLink)} />
                </Tooltip>
              </div>
              <div style={{ padding: 6, cursor: "pointer" }}>
                <a href={imageLink} target="_blank" download={false}>
                  <Tooltip title="Expand">
                    <OpenWithIcon color={"info"} fontSize={iconSize} />
                  </Tooltip>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </TransformWrapper>
  );
};

export default ImageDisplay;
