import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import "./DoctorProfiler.css";
import React, { useEffect, useState } from "react";
import { FetchDoctors } from "../../../domain/usages/data-entry/doctor-verification/fetch-doctors";
import { GetDoctorByID } from "../../../domain/usages/data-entry/doctor-verification/get-doctor-data-by-id";
import { GetImageRejectionReasons } from "../../../domain/usages/data-entry/doctor-verification/get-image-rejection-reasons";
import { RejectImage } from "../../../domain/usages/data-entry/doctor-verification/reject-image";
import { SaveDoctorBasicDetails } from "../../../domain/usages/data-entry/doctor-verification/save-doctor-basic-details";
import { VerifyDoctorContacts } from "../../../domain/usages/data-entry/doctor-verification/verify-doctor-contact";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import DoctorCard from "../../components/data-entry/doctorProfilerVerifcation/DoctorCard";
import DoctorDetails from "../../components/data-entry/doctorProfilerVerifcation/DoctorDetails";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { FetchState } from "../../../domain/models/fetch-state-type";
import InfiniteScroll from "react-infinite-scroll-component";
import ListSkeleton from "../../components/data-entry/doctorProfilerVerifcation/loading/ListSkeleton";
import { VerifyDoctorAddress } from "../../../domain/usages/data-entry/doctor-verification/verify-doctor-address";
import { FetchDoctorMasters } from "../../../domain/usages/fetch-doctor-masters";
import { VerifyDoctorName } from "../../../domain/usages/data-entry/doctor-verification/verify-doctor-name";
import { VerifyDoctorQualification } from "../../../domain/usages/data-entry/doctor-verification/verify-doctor-qualification";
import { VerifyDoctorSpeciality } from "../../../domain/usages/data-entry/doctor-verification/verify-doctor-speciality";
import { Speciality } from "../../../domain/models/speciality";
import { Qualification } from "../../../domain/models/qualification";
import { FetchBatchSizes } from "../../../domain/usages/data-entry/fetch-batch-sizes";
import SelectBatchSizeModal from "../../components/data-entry/doctorProfilerVerifcation/SelectBatchSizeModal";
import { CreateBatch } from "../../../domain/usages/data-entry/create-batch";
import BatchProgress from "../../components/data-entry/doctorProfilerVerifcation/BatchProgress";
import { LoadingButton } from "@mui/lab";
import { EndBatch } from "../../../domain/usages/data-entry/end-batch";
import Swal from "sweetalert2";
import { GetBatchData } from "../../../domain/usages/data-entry/get-batch-data";
import { GetAddressByPincode } from "../../../domain/usages/get-address-by-pincode";
import DataSkeleton from "../../components/data-entry/doctorProfilerVerifcation/loading/DataSkeleton";
import { useHomePageMainStore } from "../../../store/main/home";

type Props = {
  fetchDoctors: FetchDoctors;
  getDoctorByID: GetDoctorByID;
  getImageRejectionReasons: GetImageRejectionReasons;
  rejectImage: RejectImage;
  saveDoctorDetails: SaveDoctorBasicDetails;
  verifyDoctorDetails: VerifyDoctorContacts;
  getReadLink: GetReadLink;
  verifyAddress: VerifyDoctorAddress;
  verifyName: VerifyDoctorName;
  verifyQualification: VerifyDoctorQualification;
  verifySpeciality: VerifyDoctorSpeciality;
  fetchMasters: FetchDoctorMasters;
  getAddress: GetAddressByPincode;
  fetchBatchSizes: FetchBatchSizes;
  createBatch: CreateBatch;
  endBatch: EndBatch;
  getBatchData: GetBatchData;
};

const DoctorProfilerVerification: React.FC<Props> = ({
  fetchDoctors,
  getDoctorByID,
  getImageRejectionReasons,
  rejectImage,
  saveDoctorDetails,
  verifyDoctorDetails,
  getReadLink,
  verifyAddress,
  fetchMasters,
  verifyName,
  verifyQualification,
  verifySpeciality,
  getAddress,
  fetchBatchSizes,
  createBatch,
  endBatch,
  getBatchData,
}) => {
  const [doctorList, setDoctorList] = useState<DoctorData[]>([]);
  const [totalDoctors, setTotalDoctors] = useState<number>(0);
  const [specialities, setSpecialities] = useState<Speciality[]>([]);
  const [qualifications, setQualifications] = useState<Qualification[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [selectedDoctor, setSelectedDoctor] = useState<DoctorData>(
    {} as DoctorData
  );
  const [endBatchLoading, setEndBatchLoading] = useState(false);
  const [batchSize, setBatchSize] = useState(0);
  const [pendingItems, setPendingItems] = useState(0);
  const [createNewBatch, setCreateNewBatch] = useState(false);

  const { expandSideBar } = useHomePageMainStore();

  const fetchDoctorsList = async (page: number, scrolled: boolean) => {
    const filter = {
      page: page,
      limit: 10,
    };
    try {
      if (!scrolled) {
        setFetchState(FetchState.LOADING);
      }
      let result = await fetchDoctors.fetch(filter);
      if (result.success) {
        setCurrentPage(result.data.page);
        setTotalDoctors(result.data.total);
        setLastPage(result.data.totalPages);
        setTotalDoctors(result.data.total);
        if (scrolled) {
          setDoctorList((oldData) => [...oldData, ...result.data.result]);
        } else {
          setDoctorList(result.data.result);
        }
        setFetchState(FetchState.SUCCESS);
        if (page == 1) {
          setSelectedDoctor({ ...result.data.result[0] } as DoctorData);
        }
      }
    } catch (err) {
      setFetchState(FetchState.ERROR);
    }
  };

  const fetchMasterData = async () => {
    let result = await fetchMasters.fetch({});
    if (result.success) {
      setSpecialities(result.specialities);
      setQualifications(result.qualifications);
    }
  };

  const getBatchInformation = async () => {
    let payload = { type: "data_entry_basic" };
    let result = await getBatchData.get(payload);
    if (result.success) {
      if (result.data == null) {
        setFetchState(FetchState.LOADING);
        setCreateNewBatch(true);
      } else {
        const countPending = result.data.doctors.filter(
          (record: any) => record.status === "pending"
        ).length;
        setPendingItems(countPending);
        setBatchSize(result.data.batchSize);
        fetchDoctorsList(1, false);
      }
    }
  };

  const onEndBatch = async () => {
    setEndBatchLoading(true);
    let payload = {
      type: "data_entry_basic",
    };
    let result = await endBatch.end(payload);
    if (result.success) {
      setEndBatchLoading(false);
      Swal.fire({
        title: "Batch Ended Sucessfully 😃",
        icon: "success",
        allowOutsideClick: false,
        timer: 2000,
      }).then((response) => {
        window.location.reload();
      });
    } else {
      setEndBatchLoading(false);
      if (result.errors) {
        if (
          typeof result.errors.message === "object" &&
          result.errors.message !== null
        ) {
          Swal.fire(result.errors.message.message, "", "error");
        } else if (Array.isArray(result.errors)) {
          let errorText = "";
          for (let x in result.errors) {
            errorText += result.errors[x] + "\n";
          }
          Swal.fire(errorText, "", "error");
        } else {
          Swal.fire(result.errors.message, "", "error");
        }
      } else {
        Swal.fire(result.message, "", "error");
      }
    }
  };

  const reloadList = () => {
    getBatchInformation();
  };

  useEffect(() => {
    setFetchState(FetchState.LOADING);
    getBatchInformation();
    fetchMasterData();
  }, []);

  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };
  const onSelectedDoctor = (index: number) => {
    setSelectedDoctor({ ...doctorList[index] } as DoctorData);
  };

  return (
    <div style={{ marginTop: 70 }}>
      <Grid container spacing={2} columns={14}>
        <Grid
          item
          xs={expandSideBar ? 4.2 : 3.7}
          sx={{
            borderRight: "2px solid #E7E7E7",
          }}
        >
          <div
            style={{
              marginTop: 20,
              position: "fixed",
              width: "20%",
              marginLeft: 10,
            }}
          >
            {batchSize > 0 ? (
              <BatchProgress
                batchSize={batchSize}
                pendingItems={pendingItems}
              />
            ) : (
              <Skeleton
                variant="rounded"
                animation="wave"
                height={20}
                sx={{ marginBottom: 1 }}
              />
            )}
          </div>
          <div style={{ marginTop: 80, marginLeft: 10, position: "fixed" }}>
            <Typography variant={"subtitle2"}>
              Doctors Count ({totalDoctors})
            </Typography>
          </div>
          <div
            className="scrollBarStyles"
            id="div"
            style={{
              overflowY: "auto",
              height: "80vh",
              width: "310px",
              position: "fixed",
              marginTop: 100,
              flex: "grid",
              alignItems: "center",
            }}
          >
            {fetchState === FetchState.LOADING && (
              <>
                <div style={{ margin: 20 }}>
                  <ListSkeleton />
                </div>
                <div style={{ margin: 20 }}>
                  <ListSkeleton />
                </div>
                <div style={{ margin: 20 }}>
                  <ListSkeleton />
                </div>
              </>
            )}
            {fetchState === FetchState.ERROR && (
              <>
                <Typography color="error">something went wrong</Typography>
              </>
            )}
            {fetchState === FetchState.SUCCESS && (
              <InfiniteScroll
                scrollableTarget="div"
                dataLength={doctorList?.length}
                next={() => {
                  fetchDoctorsList(currentPage + 1, true);
                }}
                hasMore={hasMoreData()}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <LoadingButton
                    disabled={doctorList.length != 0}
                    color="warning"
                    loading={endBatchLoading}
                    loadingPosition="start"
                    onClick={onEndBatch}
                  >
                    <Typography color={"white"} textTransform={"none"}>
                      Click to End Batch
                    </Typography>
                  </LoadingButton>
                }
              >
                {doctorList.map((doctor, index) => {
                  return (
                    <div>
                      <DoctorCard
                        doctorDetails={doctor}
                        active={selectedDoctor._id == doctor._id}
                        onClick={() => onSelectedDoctor(index)}
                      />
                    </div>
                  );
                })}
                {doctorList.length == 0 && (
                  <Stack
                    spacing={2}
                    direction={"column"}
                    alignItems={"center"}
                    marginTop={5}
                  >
                    <Typography variant={"subtitle2"}>
                      No records found
                    </Typography>
                    <LoadingButton
                      variant="contained"
                      color="warning"
                      loading={endBatchLoading}
                      loadingPosition="start"
                      onClick={onEndBatch}
                    >
                      <Typography color={"white"} textTransform={"none"}>
                        Click to End Batch and Start New
                      </Typography>
                    </LoadingButton>
                  </Stack>
                )}
              </InfiniteScroll>
            )}
          </div>
        </Grid>

        <Grid item xs={9} sx={{ marginLeft: 5 }}>
          {fetchState === FetchState.LOADING && (
            <>
              <div style={{ margin: 20 }}>
                <DataSkeleton />
              </div>
            </>
          )}
          {fetchState === FetchState.ERROR && (
            <>
              <Typography color="error">something went wrong</Typography>
            </>
          )}
          {fetchState === FetchState.SUCCESS && selectedDoctor._id && (
            <DoctorDetails
              getDoctorByID={getDoctorByID}
              getImageRejectionReasons={getImageRejectionReasons}
              rejectImage={rejectImage}
              saveDoctorDetails={saveDoctorDetails}
              verifyDoctorDetails={verifyDoctorDetails}
              getReadLink={getReadLink}
              selectedDoctor={selectedDoctor}
              reloadList={reloadList}
              verifyAddress={verifyAddress}
              specialities={specialities}
              qualifications={qualifications}
              verifyName={verifyName}
              verifyQualification={verifyQualification}
              verifySpeciality={verifySpeciality}
              getAddress={getAddress}
            />
          )}
        </Grid>
        {/* )} */}
      </Grid>
      <SelectBatchSizeModal
        open={createNewBatch}
        handleClose={() => {}}
        fetchBatchSizes={fetchBatchSizes}
        createBatch={createBatch}
        reloadList={reloadList}
      />
    </div>
  );
};

export default DoctorProfilerVerification;
