import React from "react";
import { Chemist } from "../../../domain/models/chemist";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import DoctorChemistCard from "../../components/doctor/doctor-chemist-card";

type Props = {
  doctor_id: string;
  chemists: Chemist[];
  onItemClick: Function;
  getReadLink: GetReadLink;
  successCallback: Function;
};

const DoctorChemistList: React.FC<Props> = ({
  doctor_id,
  chemists,
  onItemClick,
  getReadLink,
  successCallback,
}) => {
  return (
    <div className="p-2">
      {chemists &&
        chemists.map((c, index) => (
          <DoctorChemistCard
            key={c._id}
            chemist={c}
            getReadLink={getReadLink}
            onClick={() => onItemClick(c)}
          />
        ))}
    </div>
  );
};

export default DoctorChemistList;
