import { faHospital } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import withTheme from "../../../ga-components/theme/withTheme";
import Icon, { IconSize, IconTypes } from "../typography/icon";

export type ListItemModel = {
  id: string;
  title: string;
  subTitle: string;
  description: string;
  data: any;
};

export enum ListItemType {
  PRIMARY = "primaryMedium",
  SECONDARY = "secondaryMedium",
}

type Props = {
  theme: any;
  key: any;
  item: ListItemModel;
  onClick: Function;
  type: ListItemType;
  className?: string;
};

const ListItem: React.FC<Props> = ({
  theme,
  key,
  item,
  onClick,
  type,
  className = "",
}) => {
  const classes = [className];
  classes.push(type);

  const selectItem = () => {
    onClick(item);
  };
  return (
    <li
      key={key}
      className={`w-full p-4 bg-primary_medium text-black rounded-2xl mb-2 ${classes.join(
        " "
      )}`}
      onClick={selectItem}
    >
      <a className=" w-full flex text-center align-middle">
        <div className="w-10 h-10 mr-4 align-middle flex-shrink-0 leading-extra-loose">
          <Icon
            icon={faHospital}
            type={IconTypes.PRIMARY}
            size={IconSize.xl}
            className=""
          />
        </div>
        <div className="">
          <div className="leading-7 text-base text-left font-thin">
            {item.title}
          </div>
          <div className="leading-5 text-xs text-left font-light text-gray-600">
            {item.subTitle}
          </div>
        </div>
      </a>
    </li>
  );
};

export default withTheme(ListItem);
