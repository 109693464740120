import React from "react";
import { Doctor } from "../../../domain/models/doctor";

type Props = {
  title: string;
  doctorDetails: Doctor;
};
const DoctorTitle: React.FC<Props> = ({ title, doctorDetails }) => {
  return (
    <div>
      <span className="mb-2 text-base">{title}</span>
      <br />
      <span className="text-sm mb-2 text-gray-700 tracking-wide">
        {`Doctor: ${doctorDetails.full_name} (${doctorDetails.qualifications} - ${doctorDetails.speciality})`}
      </span>
      <br />
      {doctorDetails.addresses.length > 0 && (
        <span className="text-sm mb-2 text-gray-700 tracking-wide">{`Address: ${doctorDetails.addresses[0].line}, ${doctorDetails.addresses[0].landmark}, ${doctorDetails.addresses[0].district} District, ${doctorDetails.addresses[0].state} State,
        ${doctorDetails.addresses[0].pincode}`}</span>
      )}
    </div>
  );
};

export default DoctorTitle;