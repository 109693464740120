import { faChartPie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardCount } from "../../domain/models/dashboard-count";
import { Result } from "../../domain/models/result";
import { FetchDashboardCount } from "../../domain/usages/fetch-dashboard-count";
import { pageRoutes } from "../../routes";
import FilledCard, {
  FilledColors,
} from "../components/common/cards/filled-card";
import AppBar from "../components/common/sections/app-bar";
import Clickable from "../ga-components/clickable";
import { Account } from "../ga-components/nav";

type Props = {
  fetchDashboardCount: FetchDashboardCount;
};

const DashboardPage: React.FC<Props> = ({ fetchDashboardCount }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [counts, setCounts] = useState<DashboardCount>();
  const navigate = useNavigate();

  const fetchCounts = useCallback(async () => {
    setLoading(true);
    let result: Result = await fetchDashboardCount.fetch({});
    setCounts(result.counts);
    setLoading(false);
  }, [fetchDashboardCount]);

  useEffect(() => {
    fetchCounts();
  }, []);

  return (
    <section className="h-full primary_low">
      <div className="md:container md:mx-auto md:px-5 h-full sm:w-screen">
        <div className="flex place-content-end">
          <Account />
        </div>

        <AppBar
          icon={<FontAwesomeIcon icon={faChartPie} className="text-primary" />}
          title={
            <span className="text-primary font-bold drop-shadow-2xl">
              DASHBAORD
            </span>
          }
        />
        {counts && (
          <>
            <div className="flex flex-row">
              <div className="flex-1 p-2">
                <Clickable
                  onClick={() => navigate("/home/" + pageRoutes.doctors)}
                >
                  <FilledCard
                    headline={counts?.total_doctors}
                    subhead="Total doctors"
                    supporting_text={""}
                    filledColor={FilledColors.PRIMARY}
                  />
                </Clickable>
              </div>
              <div className="flex-1 p-2">
                <Clickable
                  onClick={() => navigate("/home/" + pageRoutes.hospitals)}
                >
                  <FilledCard
                    headline={counts?.total_hospitals}
                    subhead="Total hospitals"
                    supporting_text={""}
                    filledColor={FilledColors.PRIMARY}
                  />
                </Clickable>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex-1 p-2">
                <FilledCard
                  headline={counts?.total_sampling_doctors}
                  subhead="Total sampling doctors"
                  supporting_text={""}
                  filledColor={FilledColors.PRIMARY}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default DashboardPage;
