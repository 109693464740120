import { faPlus, faUserNurse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Hospital } from "../../domain/models/hospital";
import { Result } from "../../domain/models/result";
import { FetchHospitalList } from "../../domain/usages/fetch-hospital-list";
import { pageRoutes } from "../../routes";
import FabButton from "../components/common/buttons/fab-button";
import PageLoading from "../components/common/page-loading";
import AppBar from "../components/common/sections/app-bar";
import HospitalList from "./list/hospital-list";

type Props = {
  fetchHospitalList: FetchHospitalList;
};

const HospitalPage: React.FC<Props> = ({ fetchHospitalList }) => {
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchHospitals = useCallback(async () => {
    setLoading(true);
    let result: Result = await fetchHospitalList.fetch({});
    setHospitals(result.hospitals);
    setTotalRecords(result.totalRecords);
    setLoading(false);
  }, [fetchHospitalList]);

  useEffect(() => {
    fetchHospitals();
  }, []);

  const goToHospitalProfile = (id: string) => {
    navigate("/home/" + pageRoutes.hospitalProfile.replace(":id", id));
  };

  const goToCreateHospital = () => {
    navigate("/home/" + pageRoutes.createHospital);
  };

  if (hospitals.length == 0 && loading) {
    return <PageLoading />;
  }

  return (
    <div className="md:container md:mx-auto md:px-5 h-full sm:w-screen">
      <AppBar
        icon={<FontAwesomeIcon icon={faUserNurse} className="text-primary" />}
        title={
          <span className="text-primary font-bold drop-shadow-2xl">
            HOSPITALS
          </span>
        }
      />
      {hospitals.length == 0 && (
        <div className="p-4 text-primary">
          No Hospitals, Click on plus button to start adding now.
        </div>
      )}
      <HospitalList hospitals={hospitals} onItemClick={goToHospitalProfile} />
      <FabButton text="Create" icon={faPlus} onClick={goToCreateHospital} />
    </div>
  );
};

export default HospitalPage;
