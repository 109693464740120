const Endpoints = {
  POSTAL_ADDRESS: "/pincode/:pincode",
  IMAGE_READ_URL: "/v1/uploads/read-url/:id",
  FETCH_DOCTOR_MASTERS: "/v1/masters/doctors",
  FETCH_DOCTOR_FOR_DATA_ENTRY: "/v1/profiler/data-entry/doctors/batches/basic",

  GET_DOCTOR_DATA_BY_ID: "/v1/profiler/data-entry/doctors/basic/id/:id",
  SAVE_BASIC_DOCTOR_DETAILS: "/v1/profiler/data-entry/doctors/basic/id/:id",
  VERIFY_DOCTOR_CONTACT:
    "/v1/profiler/data-entry/doctors/id/:id/contacts/:contactId/verification/verify",
  VERIFY_ADDRESS:
    "/v1/profiler/data-entry/doctors/id/:id/addresses/:addressId/verification/verify",
  VERIFY_NAME:
    "/v1/profiler/data-entry/doctors/id/:id/fullNames/:fullNameId/verification/verify",
  VERIFY_QUALIFICATION:
    "/v1/profiler/data-entry/doctors/id/:id/qualifications/:qualificationId/verification/verify",
  VERIFY_SPECIALITY:
    "/v1/profiler/data-entry/doctors/id/:id/specialities/:specialityId/verification/verify",
  MARK_IMAGE_AS_REJECT:
    "/v1/profiler/data-entry/doctors/id/:id/images/:imageId/verification/reject",
  GET_IMAGE_REJECTION_REASONS:
    "/v1/profiler/data-entry/doctors/images/rejection-reasons",
  FETCH_BATCH_SIZES: "/v1/profiler/data-entry/doctors/batches/batch-sizes",
  CREATE_BATCH: "/v1/profiler/data-entry/doctors/batches",
  END_BATCH: "/v1/profiler/data-entry/doctors/batches/complete",
  GET_ADDRESS_BY_PINCODE: "/v1/locations/pincodes/:pincode",
  DOCTOR_VERIFICATION_COUNT_SUMMARY:
    "/v1/reports/doctors/data-entry/basic/summary",

  //auth
  DATA_ENTRY_VERIFICATION_LOGIN: "/v1/users/auth/login",
};

export default Endpoints;
