import React from "react";
import withTheme from "../../theme/withTheme";

type Props = {
  theme: any;
  children?: React.ReactNode;
  className?: string;
};
const CardHeadline: React.FC<Props> = ({ theme, children, className = "" }) => {
  const cssClass = [theme.card.headline, className];
  return <div className={cssClass.join(" ")}>{children}</div>;
};

export default withTheme(CardHeadline);
