import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { orange } from "@mui/material/colors";

type Props = {};

const DoctorSummaryCardLoading: React.FC<Props> = ({}) => {
  return (
    <Card variant="outlined" raised>
      <CardContent>
        <Stack direction={"row"} spacing={2}>
          <Skeleton variant="rectangular" width={100} height={100}></Skeleton>
          <Stack spacing={1}>
            <Skeleton variant="rectangular" width={200} height={40}></Skeleton>
            <Stack direction={"row"} spacing={2}>
              <Skeleton variant="rectangular" width={90} height={40}></Skeleton>
              <Skeleton variant="rectangular" width={90} height={40}></Skeleton>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DoctorSummaryCardLoading;
