export {};

let shortMonthNames: string[] = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
declare global {
  interface Date {
    toStandardDate(): string;
    toDateMonthStr(): string;
  }
}

Date.prototype.toStandardDate = function (): string {
  var mm = this.getMonth() + 1;
  var dd = this.getDate();

  return [
    this.getFullYear(),
    "-",
    (mm > 9 ? "" : "0") + mm,
    "-",
    (dd > 9 ? "" : "0") + dd,
  ].join("");
};

Date.prototype.toDateMonthStr = function (): string {
  var dd = this.getDate();
  return [
    (dd > 9 ? "" : "0") + dd,
    ", ",
    shortMonthNames[this.getMonth()],
  ].join("");
};
