import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { Name } from "../../../../../domain/models/doctor/name";

type Props = {
  existingNameDetails: Name[];
  isVerified: boolean[];
  handlePendingContacts: Function;
  removeNameField: Function;
  nameDetails: NameType[];
  handleNameChange: Function;
  addNameField: Function;
  formErrors: string[];
};

type NameType={
  name: string
}

const NameField: React.FC<Props> = ({
  existingNameDetails,
  isVerified,
  handlePendingContacts,
  removeNameField,
  nameDetails,
  handleNameChange,
  formErrors,
  addNameField,
}) => {
  return (
    <div>
      <Typography variant="subtitle2">Doctor Name:</Typography>
      {existingNameDetails?.length > 0 &&
        existingNameDetails?.map((data, index) => {
          return (
            <div style={{ marginBottom: 10, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <TextField
                  value={data.name}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {isVerified[index] ? (
                          <Tooltip title="Verified">
                            <CheckCircleOutlineIcon color="success" />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Pending, click to verify">
                            <ReportGmailerrorredIcon
                              color="warning"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handlePendingContacts(index, "name")
                              }
                            />
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          );
        })}
      {nameDetails.map((data, index) => {
        return (
          <div style={{ marginBottom: 10, width: "100%" }}>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
              }}
            >
              <TextField
                name="socialMedia"
                placeholder="Enter Name"
                value={data.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleNameChange(index, event.target.value)
                }
                error={formErrors[index] ? true : false}
                helperText={formErrors[index]}
                fullWidth
              />
              <IconButton onClick={() => removeNameField(index)}>
                <Tooltip title="Cancel">
                  <RemoveCircleOutlineIcon color="error" />
                </Tooltip>
              </IconButton>
            </div>
          </div>
        );
      })}
      <Button variant="text" color="warning" onClick={() => addNameField()}>
        <AddIcon /> ADD DOCTOR NAME
      </Button>
    </div>
  );
};

export default NameField;
