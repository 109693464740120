import { Stack, Typography } from "@mui/material";
import React from "react";

type Props = {
  title: string;
};
const Header: React.FC<Props> = ({ title = "" }) => {
  return (
    <>
      <Stack
        direction={"row"}
        width={"100%"}
        border="1px solid  #d2d2d2"
        alignItems={"center"}
        paddingY={1}
        paddingX={4}
      >
        <Typography color={"grey"} variant="h6">
          {title}
        </Typography>
      </Stack>
    </>
  );
};

export default Header;
