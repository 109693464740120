type Props = {
  text: string;
  className?: string;
  value: string;
  onChange: () => void;
};

const CheckBox: React.FC<Props> = ({ text, className, value, onChange }) => {
  const classes = [className];
  return (
    <div>
      <div className="flex items-center h-5">
        <input
          type="checkbox"
          className={"h-4 w-4 border-2 rounded-sm"}
          value={value}
          onChange={onChange}
        />

        <div className="ml-1 text-sm">
          <label
            className={`font-semibold text-gray-500 text-sm p-2 ${classes
              .join(" ")
              .trim()}`}
          >
            {text}
          </label>
        </div>
      </div>
    </div>
  );
};

export default CheckBox;
