import withTheme from "../../../ga-components/theme/withTheme";

export enum HeaderType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  WHITE = "white",
}

export enum HeaderSize {
  xs = "xs",
  sm = "sm",
  base = "base",
  lg = "lg",
  xl = "xl",
  xl2 = "xl2",
}

type Props = {
  theme: any;
  text: string;
  type: HeaderType;
  size?: HeaderSize;
  className?: string;
};

const Header: React.FC<Props> = ({
  theme,
  text,
  type,
  size = HeaderSize.xs,
  className,
}) => {
  const classes = [className];
  classes.push(theme.text[type]);
  classes.push(theme.size[size]);
  return <h1 className={`px-4 ${classes.join(" ")}`}>{text}</h1>;
};

export default withTheme(Header);
