enum ButtonColor {
  PRIMARY = "primary",
  PRIMARY_LOW = "primaryMedium",
  SECONDARY = "secondary",
  SECONDARY_LOW = "secondaryMedium",
  WHITE = "white",
  ERROR = "error",
  SUCCESS = "success",
}

export default ButtonColor;
