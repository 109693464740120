import { create } from "zustand";
import { useHomeStore } from "../slice/home";

interface HomePageMainStoreInterface extends ReturnType<typeof useHomeStore> {}

export const useHomePageMainStore = create<HomePageMainStoreInterface>(
  (...data) => ({
    ...useHomeStore(...data),
  })
);
