import { GooglePlaceSearchResult } from "google-places-web";
import { AgeGroup } from "./age-group";
import { CommercialType } from "./commercial-type";
import { CompetitorVaccine } from "./competitor-vaccine";
import { DashboardCount } from "./dashboard-count";
import { Doctor } from "./doctor";
import { Gender } from "./gender";
import { Hospital } from "./hospital";
import { OrganizationCode } from "./organizationCode";
import { PatientFocus } from "./patient-focus";
import { PostOffice } from "./post-office";
import { Qualification } from "./qualification";
import { Speciality } from "./speciality";

type Errors = {
  message?: string;
};

export type Result = {
  doctor: Doctor;
  doctors: Doctor[];
  hospitals: Hospital[];
  hospital: Hospital;
  specialities: Speciality[];
  qualifications: Qualification[];
  deptCodes: string[];
  states: string[];
  genders: Gender[];
  patientFocuses: PatientFocus[];
  commercialTypes: CommercialType[];
  competitorVaccines: CompetitorVaccine[];
  geo_locations: GooglePlaceSearchResult[];
  PostOffice: PostOffice[];
  totalRecords: number;
  ageGroups: AgeGroup[];
  uuid: string;
  url: string;
  counts: DashboardCount;
  errors: Errors;
  success: boolean;
  message: string;
  total: number;
  orgCodes: OrganizationCode[];
};

export const EmptyResponse: Result = {
  doctor: {} as Doctor,
  doctors: [],
  hospitals: [],
  hospital: {} as Hospital,
  specialities: [],
  qualifications: [],
  deptCodes: [],
  states: [],
  genders: [],
  patientFocuses: [],
  commercialTypes: [],
  competitorVaccines: [],
  geo_locations: [],
  PostOffice: [],
  totalRecords: 10,
  ageGroups: [],
  counts: {} as DashboardCount,
  uuid: "",
  url: "",
  errors: {} as Errors,
  success: false,
  message: "",
  total: 0,
  orgCodes: [],
};
