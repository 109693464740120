import { Skeleton } from "@mui/material";
import React from "react";

type Props = {};

const DataSkeleton = (props: Props) => {
  return (
    <div>
      <Skeleton
        variant="rounded"
        animation="wave"
        height={50}
        sx={{ marginBottom: 1 }}
      />
      <Skeleton
        variant="rounded"
        animation="wave"
        height={300}
        width="50%"
        sx={{ marginBottom: 3, marginLeft: 30, marginTop: 10 }}
      />
      <Skeleton
        variant="rounded"
        animation="wave"
        height={50}
        sx={{ marginBottom: 3 }}
      />
      <Skeleton
        variant="rounded"
        animation="wave"
        height={50}
        sx={{ marginBottom: 1 }}
      />
    </div>
  );
};

export default DataSkeleton;
