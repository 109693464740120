import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Chemist } from "../../../domain/models/chemist";
import { Doctor } from "../../../domain/models/doctor";
import { Result } from "../../../domain/models/result";
import { AddDoctorChemist } from "../../../domain/usages/add-doctor-chemist";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { GenerateUploadUrl } from "../../../domain/usages/generate-upload-url";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import { UpdateDoctorChemist } from "../../../domain/usages/update-doctor-chemist";
import { UploadFile } from "../../../domain/usages/upload-file";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import TonalButton from "../../components/common/buttons/tonal-button";
import TonalFixedButton from "../../components/common/buttons/tonal-fixed-button";
import DoctorChemistForm from "../../components/doctor/doctor-chemist-form";
import DoctorChemistList from "../list/doctor-chemist-list";

type Props = {
  id: string;
  doctor: Doctor;
  addChemist: AddDoctorChemist;
  updateChemist: UpdateDoctorChemist;
  confirmFileUpload: ConfirmFileUpload;
  generateUploadUrl: GenerateUploadUrl;
  uploadFile: UploadFile;
  getReadLink: GetReadLink;
  successCallback: Function;
};
const DoctorChemistDetailsForm: React.FC<Props> = ({
  id,
  doctor,
  addChemist,
  updateChemist,
  confirmFileUpload,
  generateUploadUrl,
  uploadFile,
  getReadLink,
  successCallback,
}) => {
  const [creating, setCreating] = useState(false);
  const [chemist, setChemist] = useState<Chemist>({} as Chemist);
  const [loading, setLoading] = useState(false);

  const storage = LocalJsonStorage.getInstance();
  const location = storage.get("lat-long");

  useEffect(() => {
    if (!doctor.chemists || doctor.chemists.length == 0) {
      setCreating(true);
    }
  }, [doctor]);

  useEffect(() => {
    if (creating == false) setChemist({} as Chemist);
  }, [creating]);

  const create = useCallback(
    async (data: any) => {
      setLoading(true);
      let result: Result = await addChemist.create(id, {
        full_name: data.full_name,
        mobile: data.mobile,
        no_of_staff: data.no_of_staff,
        billboard: data.billboard,
        lat_long: location,
      });
      if (!result.errors) {
        Swal.fire("Chemist added to doctor successfully.", "", "success");
        successCallback();
        setCreating(false);
      } else {
        setLoading(false);
      }
    },
    [addChemist]
  );

  const update = useCallback(
    async (data: any) => {
      setLoading(true);
      let result: Result = await updateChemist.update(id, data._id, {
        full_name: data.full_name,
        mobile: data.mobile,
        no_of_staff: data.no_of_staff,
        billboard: data.billboard,
        lat_long: location,
      });
      if (!result.errors) {
        Swal.fire("Chemist updated successfully.", "", "success");
        setCreating(false);
        successCallback();
      } else {
        setLoading(false);
      }
    },
    [updateChemist]
  );

  return (
    <div>
      {creating && doctor && (
        <>
          {doctor.clinics && doctor.clinics.length > 0 && (
            <div className="text-right">
              <TonalButton
                icon={faTimes}
                primary={false}
                text={"Close"}
                onClick={() => setCreating(false)}
              />
            </div>
          )}

          <DoctorChemistForm
            chemist={chemist}
            generateUploadUrl={generateUploadUrl}
            uploadFile={uploadFile}
            confirmFileUpload={confirmFileUpload}
            successCallback={(data: Chemist) =>
              data._id ? update(data) : create(data)
            }
          />
        </>
      )}
      {!creating && (
        <DoctorChemistList
          doctor_id={doctor._id}
          chemists={doctor.chemists}
          onItemClick={(chemist: Chemist) => {
            setChemist(chemist);
            setCreating(true);
          }}
          getReadLink={getReadLink}
          successCallback={successCallback}
        />
      )}

      {!creating && doctor.chemists && doctor.chemists.length > 0 && (
        <TonalFixedButton
          icon={faPlus}
          primary={true}
          text={"ADD NEW CHEMIST"}
          onClick={() => setCreating(true)}
        />
      )}
    </div>
  );
};

export default DoctorChemistDetailsForm;
