import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useState } from "react";
import { Doctor } from "../../../domain/models/doctor";
import { Hospital } from "../../../domain/models/hospital";
import { HospitalDoctor } from "../../../domain/models/hospital-doctor";
import { Result } from "../../../domain/models/result";
import { FetchHospitalList } from "../../../domain/usages/fetch-hospital-list";
import { FetchHospitalsNearMe } from "../../../domain/usages/fetch-hospitals-near-me";
import TonalButton from "../../components/common/buttons/tonal-button";
import TonalFixedButton from "../../components/common/buttons/tonal-fixed-button";
import DoctorHospitalForm from "../../components/doctor/doctor-hospital-form";
import NearByHospital from "../../components/doctor/near-by-hospital";
import DoctorHospitalList from "../list/doctor-hospital-list";
import HospitalList from "../list/hospital-list";
import { FindHospital } from "../../../domain/usages/find-hospital";
import { AddDoctorHospital } from "../../../domain/usages/add-doctor-hospital";
import Swal from "sweetalert2";
import { Constants } from "../../../common/Constants";
import SweetAlertMessageType from "../../../common/constants-types/sweet-alert-message";

type Props = {
  id: string;
  doctor: Doctor;
  addHospital: AddDoctorHospital;
  successCallback: Function;
  fetchHospitalsNearMe: FetchHospitalsNearMe;
  fetchHospitalList: FetchHospitalList;
  findHospital: FindHospital;
};
const DoctorHospitalDetailsForm: React.FC<Props> = ({
  id,
  doctor,
  addHospital,
  fetchHospitalsNearMe,
  successCallback,
  fetchHospitalList,
  findHospital,
}) => {
  const [creating, setCreating] = useState(false);
  const [hospital, setHospital] = useState<Hospital>({} as Hospital);
  const [existingHospitals, setExistingHospitals] = useState<Hospital[]>([]);
  const [loading, setLoading] = useState(false);
  const [showNearByHospital, setShowNearByHospital] = useState(false);
  const [showHospitalList, setShowHospitalList] = useState(true);

  useEffect(() => {
    if (!doctor.hospitals || doctor.hospitals.length == 0) {
      setCreating(true);
    }
  }, [doctor]);

  const fetchHospitals = useCallback(async () => {
    setLoading(true);
    let result: Result = await fetchHospitalList.fetch({});
    setExistingHospitals(result.hospitals);
    setLoading(false);
  }, [fetchHospitalList]);

  const getHospital = useCallback(
    async (id) => {
      let result: Result = await findHospital.find(id);
      setHospital(result.hospital);
      setShowNearByHospital(false);
      setCreating(true);
      setShowHospitalList(false);
      setLoading(false);
    },
    [findHospital]
  );

  useEffect(() => {
    fetchHospitals();
  }, []);

  const create = useCallback(
    async (data: any) => {
      setLoading(true);
      let result: Result = await addHospital.create(id, {
        full_name: data.full_name,
        addresses: data.addresses,
        uuid: data._id,
      });
      if (!result.errors) {
        Swal.fire(
          Constants.HOSPITAL_ADDED_TO_DOCTOR,
          "",
          SweetAlertMessageType.SUCCESS
        );
        successCallback();
        setCreating(false);
      } else {
        setLoading(false);
      }
    },
    [addHospital]
  );

  const handleAddHospital = () => {
    setCreating(true);
    setShowNearByHospital(true);
    setShowHospitalList(false);
  };

  const handleItemSelection = (id: string) => {
    getHospital(id);
  };
  const handleAddNewHospital = () => {
    setCreating(true);
    setShowNearByHospital(false);
    setShowHospitalList(true);
  };

  const handleCloseButton = () => {
    setCreating(true);
    setShowHospitalList(true);
  };

  return (
    <div>
      {creating && !showHospitalList && (
        <>
          {!showNearByHospital && (
            <div className="text-right">
              <TonalButton
                icon={faTimes}
                primary={false}
                text={"Close"}
                onClick={handleCloseButton}
              />
            </div>
          )}
          {!showNearByHospital && (
            <DoctorHospitalForm
              hospital={hospital}
              successCallback={(data: HospitalDoctor) => create(data)}
            />
          )}
          {showNearByHospital && (
            <NearByHospital
              fetchHospitalsNearMe={fetchHospitalsNearMe}
              onHospitalSelection={setHospital}
              setShowNearByHospital={setShowNearByHospital}
            />
          )}
        </>
      )}
      {!creating && (
        <DoctorHospitalList
          hospitals={doctor.hospitals}
          onItemClick={() => {}}
        />
      )}

      {!creating && doctor.hospitals && doctor.hospitals.length > 0 && (
        <TonalFixedButton
          loading={loading}
          icon={faPlus}
          primary={true}
          text={"ADD NEW HOSPTIAL"}
          onClick={handleAddNewHospital}
        />
      )}

      {showHospitalList && creating && (
        <>
          <span>Already Existing Hospitals:</span>
          <HospitalList
            hospitals={existingHospitals}
            showIconFields={false}
            onItemClick={handleItemSelection}
          />

          <TonalFixedButton
            loading={loading}
            icon={faPlus}
            primary={true}
            text={"Click here if Hospital not in the list"}
            onClick={handleAddHospital}
          />
        </>
      )}
    </div>
  );
};

export default DoctorHospitalDetailsForm;
