import {
  AUTH_HEADER,
  AUTH_TOKEN,
  HOSPITAL_DOCTOR_SERVICE_API_URL,
  POST_OFFICE_API,
} from "../base";
import { RemoteConfirmFileUpload } from "../data/usecases/remote-confirm-file-upoad";
import { RemoteCreateDoctor } from "../data/usecases/remote-create-doctor";
import { RemoteFetchDoctorMasters } from "../data/usecases/remote-fetch-doctor-masters";
import { RemoteFetchHospitalsNearMe } from "../data/usecases/remote-fetch-hospitals-near-me";
import { RemoteFetchPostalAddressForPincode } from "../data/usecases/remote-fetch-postal-address-for-pincode";
import { RemoteGenerateUploadUrl } from "../data/usecases/remote-generate-upload-url";
import { RemoteUploadFile } from "../data/usecases/remote-upload-file";
import { RemoteValidateDoctor } from "../data/usecases/remote-validate-doctor";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import CreateDoctorPage from "../presentation/pages/create-doctor-page";

type Props = {
  onSelect: Function;
  selected?: number;
};

export const CreateDoctorFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: `Bearer ${atob(token)}` });

  const remoteCreateDoctor = new RemoteCreateDoctor(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors`,
    axiosHttpClient
  );

  const remoteValidateDoctor = new RemoteValidateDoctor(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/validate`,
    axiosHttpClient
  );

  const remoteFetchMasters = new RemoteFetchDoctorMasters(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/masters/doctors`,
    axiosHttpClient
  );

  const remoteFetchPostalAddressForPincode =
    new RemoteFetchPostalAddressForPincode(
      `${POST_OFFICE_API}/pincode/:pincode`,
      axiosHttpClient
    );

  const remoteGenerateUploadUrl = new RemoteGenerateUploadUrl(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/uploads/generate-upload-url`,
    axiosHttpClient
  );
  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);
  const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/uploads/confirm-upload`,
    axiosHttpClient
  );

  const remoteFetchHospitalsNearMe = new RemoteFetchHospitalsNearMe(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/geo-locations/hospitals/near-me`,
    axiosHttpClient
  );

  return (
    <CreateDoctorPage
      createDoctor={remoteCreateDoctor}
      validateDoctor={remoteValidateDoctor}
      fetchMasters={remoteFetchMasters}
      fetchPostalAddressForPincode={remoteFetchPostalAddressForPincode}
      generateUploadUrl={remoteGenerateUploadUrl}
      confirmFileUpload={remoteConfirmFileUpload}
      uploadFile={remoteUploadFile}
      fetchClinicsNearMe={remoteFetchHospitalsNearMe}
      {...props}
    />
  );
};
