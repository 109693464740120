import { GetImageRejectionReasons } from "../../../../domain/usages/data-entry/doctor-verification/get-image-rejection-reasons";
import { HttpConstants } from "../../../protocols/http/http-constants";
import { HttpGetClient } from "../../../protocols/http/http-get-client";

export class RemoteGetImageRejectionReasons
  implements GetImageRejectionReasons
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}
  async get(params: GetImageRejectionReasons.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
