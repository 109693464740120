import { useParams } from "react-router-dom";
import {
  AUTH_HEADER,
  AUTH_TOKEN,
  HOSPITAL_DOCTOR_SERVICE_API_URL,
} from "../base";
import { RemoteFetchDoctorList } from "../data/usecases/remote-fetch-doctor-list";
import { RemoteFetchDoctorMasters } from "../data/usecases/remote-fetch-doctor-masters";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import DataEntryAdminDashboardPage from "../presentation/pages/data-entry/DataEntryAdminDashboardPage";

type Props = {};

export const DataEntryAdminDashboardPageFactory = (props: Props) => {
  const { id } = useParams();
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: `Bearer ${atob(token)}` });

  const remoteFetchDoctorMasters = new RemoteFetchDoctorMasters(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/masters/doctors`,
    axiosHttpClient
  );

  const remoteFetchDoctorList = new RemoteFetchDoctorList(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors`,
    axiosHttpClient
  );

  return (
    <DataEntryAdminDashboardPage
      fetchMasters={remoteFetchDoctorMasters}
      fetchDoctorList={remoteFetchDoctorList}
      {...props}
    />
  );
};
