import { EmptyResponse, Result } from "../../domain/models/result";
import { RemoveHospitalDoctorFromGoVaxx } from "../../domain/usages/remove-hospital-doctor-from-govaxx";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteRemoveHospitalDoctorFromGoVaxx
  implements RemoveHospitalDoctorFromGoVaxx
{
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async remove(
    id: string,
    doctor_id: string,
    params: RemoveHospitalDoctorFromGoVaxx.Params
  ): Promise<Result> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id).replace(":doctor_id", doctor_id),
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: "Server error. Please try after sometime." };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
