import React, { useState } from "react";
import { Icon, IconColor } from "../icon";
import withTheme from "../theme/withTheme";
import ButtonColor from "./button-color";
import ButtonType from "./button-type";

type Props = {
  theme: any;
  text: string;
  onClick: Function;
  color: ButtonColor;
  loading?: boolean;
  type?: ButtonType;
  className?: string;
};
const Button: React.FC<Props> = ({
  theme,
  text,
  onClick,
  color = ButtonColor.PRIMARY,
  loading = false,
  type = ButtonType.FILLED,
  className = "",
}) => {
  const [cssClass] = useState<string>(
    [
      theme.button.colors[color],
      theme.button.base,
      theme.button[type],
      className,
    ].join(" ")
  );

  return (
    <button className={cssClass} onClick={() => onClick()} disabled={loading}>
      {loading && (
        <span className={theme.button.icon}>
          <Icon icon="loading" spin={true} color={IconColor.WHITE} />
        </span>
      )}
      <span className={theme.button.name}>{text}</span>
    </button>
  );
};

export default withTheme(Button);
