import {
  faArrowDown,
  faArrowUp,
  faChartPie,
  faCloudUploadAlt,
  faDownload,
  faExclamationCircle,
  faFilter,
  faInfoCircle,
  faSignOutAlt,
  faSpinner,
  faTable,
  faTimesCircle,
  faPhone,
  faPlus,
  faSearchPlus,
  faSearchMinus,
  faUndo,
  faSync,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IconColor, IconSize } from ".";
import withTheme from "../theme/withTheme";

const icons = {
  logout: faSignOutAlt,
  logo: faChartPie,
  table: faTable,
  filter: faFilter,
  loading: faSpinner,
  download: faDownload,
  upload: faCloudUploadAlt,
  cancel: faTimesCircle,
  warning: faExclamationCircle,
  info: faInfoCircle,
  error: faExclamationCircle,
  growth: faArrowUp,
  loss: faArrowDown,
  phone: faPhone,
  plus: faPhone,
  zoomIn: faSearchPlus,
  zoomOut: faSearchMinus,
  reset: faUndo,
  rotate: faSync,
  expand: faExpand,
} as any;

export type Props = {
  theme: any;
  icon: string;
  color?: IconColor;
  size?: IconSize;
  spin?: boolean;
  className?: string;
};
const Icon: React.FC<Props> = ({
  theme,
  icon,
  color,
  size = IconSize.xs,
  spin = false,
  className = "",
}) => {
  const classes = [className];
  if (color) classes.push(theme.text[color]);
  classes.push(theme.size[size]);
  return (
    <FontAwesomeIcon
      icon={icons[icon]}
      className={`${classes.join(" ")}`}
      spin={spin}
    />
  );
};

export default withTheme(Icon);
