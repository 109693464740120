import ProfileSectionButton, {
  ProfileStatus,
} from "../doctor/profile-section-button";
import Swal from "sweetalert2";
import FilledCard, { FilledColors } from "../common/cards/filled-card";
import React, { useEffect } from "react";
import {
  faUser,
  faClinicMedical,
  faHospital,
  faUserGraduate,
  faCapsules,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import { Doctor } from "../../../domain/models/doctor";
import Clickable from "../../ga-components/clickable";
import TonalButton from "../common/buttons/tonal-button";
import DoctorStatus from "../../../domain/enums/doctor-status";
import { Constants } from "../../../common/Constants";

type HeaderProps = {
  title: string;
  gender: string;
  status: string;
};
const HospitalHeader: React.FC<HeaderProps> = ({ title, gender, status }) => {
  const statusColor: Record<string, string> = {
    [DoctorStatus.PENDING]: Constants.PENDING_TEXT,
    [DoctorStatus.VERIFIED]: Constants.APPROVED_TEXT,
    [DoctorStatus.REJECTED]: Constants.REJECTED_TEXT,
  };
  const doctorStatus: Record<string, string> = {
    [DoctorStatus.PENDING]: Constants.PENDING,
    [DoctorStatus.VERIFIED]: Constants.VERIFIED,
    [DoctorStatus.REJECTED]: Constants.REJECT,
  };

  return (
    <div>
      {gender && (
        <div className="text-primary text-xs float-right">{gender}</div>
      )}
      {title}
      {status && (
        <span className={`${statusColor[status]} ml-5 text-base border p-2`}>
          {doctorStatus[status]}
        </span>
      )}
    </div>
  );
};

type Props = {
  doctor: Doctor;
  onClick: Function;
  addForSampling: (id: string) => {};
  removeFromSampling: (id: string) => {};
};
const DoctorCard: React.FC<Props> = ({
  doctor,
  onClick,
  addForSampling,
  removeFromSampling,
}) => {
  const onAddForSamplingClick = () => {
    Swal.fire({
      confirmButtonText: "Save",
      showCancelButton: true,
      title: `Do you want to mark ${doctor.full_name} for sampling?`,
    }).then((result) => {
      if (result.isConfirmed) {
        addForSampling(doctor._id);
      } else if (result.isDenied) {
      }
    });
  };

  const onRemoveFromSamplingClick = () => {
    Swal.fire({
      confirmButtonText: "Save",
      showCancelButton: true,
      title: `Do you want to remove ${doctor.full_name} from sampling?`,
    }).then((result) => {
      if (result.isConfirmed) {
        removeFromSampling(doctor._id);
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <Clickable onClick={onClick}>
      <FilledCard
        filledColor={doctor ? FilledColors.PRIMARY : FilledColors.SECONDARY}
        headline={
          <HospitalHeader
            title={doctor.full_name}
            gender={doctor.gender}
            status={doctor.verification_status}
          />
        }
        subhead={doctor.speciality}
        supporting_text={
          doctor.addresses &&
          doctor.addresses.length > 0 &&
          doctor.addresses[0].line
        }
        actions={
          <div>
            <div>
              {!doctor.sampling && (
                <TonalButton
                  text={"Add for sampling"}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onAddForSamplingClick();
                  }}
                  icon={undefined}
                />
              )}
              {doctor.sampling && (
                <TonalButton
                  text={"Remove from sampling"}
                  primary={false}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onRemoveFromSamplingClick();
                  }}
                  icon={undefined}
                />
              )}
            </div>
            <br />

            <ProfileSectionButton
              icon={faUser}
              text={"Personal"}
              status={ProfileStatus.COMPLETE}
            />
            <span className="p-2"></span>
            <ProfileSectionButton
              icon={faUserNurse}
              text={"Practice"}
              status={ProfileStatus.INCOMPLETE}
            />
            <span className="p-2"></span>
            <ProfileSectionButton
              icon={faClinicMedical}
              text={"Clinic"}
              status={ProfileStatus.INCOMPLETE}
            />
            <span className="p-2"></span>
            <ProfileSectionButton
              icon={faCapsules}
              text={"Chemist"}
              status={ProfileStatus.INCOMPLETE}
            />
            <span className="p-2"></span>
            <ProfileSectionButton
              icon={faHospital}
              text={"Hospital"}
              status={ProfileStatus.INCOMPLETE}
            />
          </div>
        }
      />
    </Clickable>
  );
};

export default DoctorCard;
