import React from "react";
import { Doctor } from "../../../../domain/models/doctor";
import DoctorItem from "./doctor-item";

type Props = {
  doctors: Doctor[] | undefined;
  onItemClick: (doctor: Doctor) => void;
  selectedDoctor?: Doctor;
};

const DoctorList: React.FC<Props> = ({
  doctors,
  selectedDoctor,
  onItemClick,
}) => {
  return (
    <>
      {doctors &&
        doctors.map((d) => (
          <DoctorItem
            doctor={d}
            key={d._id}
            onClick={() => onItemClick(d)}
            active={selectedDoctor && selectedDoctor._id == d._id}
          />
        ))}
    </>
  );
};

export default DoctorList;
