import { useParams } from "react-router-dom";
import { useAuthToken } from "../presentation/hooks";
import { LocalLoggedInUser } from "../data/usecases/local-logged-in-user";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import AuthenticatePage from "../presentation/pages/authenticate-page";
import Constants from "../Constants";
import { useEffect } from "react";

export const AuthenticateFactory = () => {
  const { authToken } = useParams();
  const payload = useAuthToken(authToken);
  const jsonStorage = LocalJsonStorage.getInstance();
  const localLoggedInUser = new LocalLoggedInUser(jsonStorage);

  useEffect(() => {
    if (
      payload &&
      authToken &&
      Constants.DOCTOR_CONTROL_CHEMIST_DIVISIONS.includes(payload.dept_code)
    ) {
      window.location.href = Constants.DOCTOR_CHEMIST_PROFILER_URL.replace(
        ":token",
        authToken
      );
    }
  }, [payload, authToken]);

  if (!authToken) return <div>Invalid access</div>;

  if (authToken && !payload) {
    return <div>Preparing...</div>;
  }

  return (
    <AuthenticatePage loggedInUser={localLoggedInUser} authToken={authToken} />
  );
};
