import { Result } from "../../domain/models/result";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { FindHospital } from "../../domain/usages/find-hospital";
import { HttpConstants } from "../protocols/http/http-constants";

export class RemoteFindHospital implements FindHospital {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}
  async find(id: string, params: FindHospital.Params): Promise<Result> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":id", id),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
