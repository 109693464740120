import { FetchDoctors } from "../../../../domain/usages/data-entry/doctor-verification/fetch-doctors";
import { HttpConstants } from "../../../protocols/http/http-constants";
import { HttpGetClient } from "../../../protocols/http/http-get-client";

export class RemoteFetchDoctors implements FetchDoctors {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}
  async fetch(params: FetchDoctors.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
