import { EmptyResponse, Result } from "../../domain/models/result";
import { HttpPostClient } from "../protocols/http/http-post-client";
import { CreateDoctor } from "../../domain/usages/create-doctor";
import { HttpConstants } from "../protocols/http/http-constants";
import { ValidateDoctor } from "../../domain/usages/validate-doctor";

export class RemoteValidateDoctor implements ValidateDoctor {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async validate(params: ValidateDoctor.Params): Promise<Result> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: "Server error. Please try after sometime." };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
