import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
} from "../../base";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { RemoteFetchVerificationCounts } from "../../data/usecases/data-entry/doctor-verification/remote-fetch-verification-counts";
import DoctorCountSummary from "../../presentation/pages/data-entry/DoctorCountSummary";
import React from "react";
import Endpoints from "../../domain/endpoints";

type Props = {};

export const DoctorVerificationCountSummaryFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
  });

  const remoteFetchVerificationCounts = new RemoteFetchVerificationCounts(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.DOCTOR_VERIFICATION_COUNT_SUMMARY}`,
    axiosHttpClient
  );

  return (
    <DoctorCountSummary
      doctorVerficationCounts={remoteFetchVerificationCounts}
    />
  );
};
