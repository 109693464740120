import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";

function LinearProgressWithLabel(
  props: LinearProgressProps & {
    value: number;
    batchSize: number;
    pendingItems: number;
  }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Stack marginRight={2}>
        <Typography variant="body2" color="text.secondary">{`${
          props.batchSize - props.pendingItems
        }/${props.batchSize}`}</Typography>
      </Stack>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

type Props = {
  batchSize: number;
  pendingItems: number;
};

const BatchProgress: React.FC<Props> = ({ batchSize, pendingItems }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="caption">Batch Progress</Typography>
      <LinearProgressWithLabel
        batchSize={batchSize}
        pendingItems={pendingItems}
        color="warning"
        value={((batchSize - pendingItems) / batchSize) * 100}
        sx={{ height: 10 }}
      />
    </Box>
  );
};

export default BatchProgress;
