import React from "react";
import { Chemist } from "../../../../domain/models/chemist";
import ChemistItem from "./chemist-item";

type Props = {
  clinics: Chemist[] | undefined;
  onItemClick: (clinic: Chemist) => void;
  selectedChemist?: Chemist;
};

const ChemistList: React.FC<Props> = ({
  clinics,
  selectedChemist,
  onItemClick,
}) => {
  return (
    <>
      {clinics &&
        clinics.map((c) => (
          <ChemistItem
            chemist={c}
            key={c._id}
            onClick={() => onItemClick(c)}
            active={selectedChemist && selectedChemist._id == c._id}
          />
        ))}
    </>
  );
};

export default ChemistList;
