export class Constants {
  //server error message
  static SERVER_ERROR = "Server error. Please try after sometime.";
  static TOKEN_ERROR = "Token missing";

  //google map source
  static SOURCE_NAME = "GOOGLE_PLACES_API";

  //success messages
  static DOCTOR_VERFICATION_SUCCESS = "Doctor Status updated successfully.";
  static CLINIC_VERFICATION_SUCCESS = "Clinic Status updated successfully.";
  static CLINIC_UPDATE_SUCCESS = "Clinic updated successfully.";
  static DOCTOR_ADDED_TO_CLINIC_SUCCESS =
    "Doctor added to clinic successfully.";
  static CHEMIST_VERFICATION_SUCCESS = "Chemist Status updated successfully.";
  static CHEMIST_UPDATE_SUCCESS = "Chemist updated successfully.";
  static DOCTOR_ADDED_TO_GOVAXX = "Doctor added to Govaxx list successfully.";
  static DOCTOR_REMOVED_FROM_GOVAXX = "Doctor removed from GoVaxx.";
  static DOCTOR_ADDED_TO_VACCINATOR =
    "Doctor added to Vaccinator list successfully.";
  static HOSPITAL_ADDED_TO_DOCTOR = "Hospital added to doctor successfully.";

  //failed messages
  static DOCTOR_VERFICATION_FAILED = "Doctor Status updated failed.";
  static CLINIC_VERFICATION_FAILED = "Clinic Status updated failed.";
  static CLINIC_UPDATE_FAILED = "Clinic updated failed.";
  static DOCTOR_ADDED_TO_CLINIC_FAILED = "Doctor added to clinic failed.";
  static CHEMIST_VERFICATION_FAILED = "Chemist Status updated failed.";
  static CHEMIST_UPDATE_FAILED = "Chemist updated failed.";
  static DOCTOR_FAILED_TO_ADD_VACCINATOR =
    "Doctor failed to add to Vaccinator list.";

  //validation error messages
  static MOBILE_NUMBER_VALIDATION_ERROR = "Invalid Mobile Number";
  static FIELD_VALIDATION_ERROR = "Field Required";
  static EMAIL_ID_VALIDATION_ERROR = "Invalid Email ID";
  static SOCIAL_MEDIA_TYPE_VALIDATION = " Please select social media type";

  //pagination
  static INTIAL_PAGE_NUMBER = 1;

  //image rejection message
  static IMAGE_NOT_CLEAR = "Image is not clear";
  static NOT_SHOP_IMAGE = "Not a Shop Image";

  //pagination debounce time
  static PAGINATION_DEBOUNCE_TIME = 1000;

  //Doctor status messages
  static APPROVED = "Doctor Approved";
  static REJECTED = "Doctor Rejected";

  //Sampling status messages
  static SAMPLING_ACCEPT = "Sampling Accepted";
  static SAMPLING_REJECT = "Sampling Rejected";

  //Doctor rejection reasons
  static ADDRESS_MISMATCH_WITH_CLINIC_IMAGE =
    "Address not matching with Image uploaded in Clinic";

  //Sampling rejection reasons
  static CLINIC_IMAGE_IS_MEDICAL_SHOP = "Clinic image is of a medical shop";
  static DOCTOR_NOT_MBBS = "Doctor is not MBBS or above";

  //status messages
  static PENDING = "Pending";
  static VERIFIED = "Approved";
  static REJECT = "Rejected";

  //status text color
  static PENDING_TEXT = "text-yellow-500";
  static APPROVED_TEXT = "text-green-500";
  static REJECTED_TEXT = "text-red-500";

  //HTTP Error message
  static HTTP_ERROR_MESSAGE = "Server error. Please try after sometime.";

  static IS_USES_PREVNAR_TRUE = "Yes";
  static IS_STOCKS_FLU_VACCINE_TRUE = "Yes";
  static IS_USES_PREVNAR_FALSE = "No";
  static IS_STOCKS_FLU_VACCINE_FALSE = "No";

  static MASTER_MANAGEMENT_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Im9uZWdvYXBwdGl2X21tIn0.eyJ1c2VybmFtZSI6Ik9ORUdPQVBQVElWX0NNIiwiaXNBZG1pbiI6dHJ1ZSwiaWF0IjoxNjY0ODc2Njk2fQ.T8vale6a4qgtRC-5t4d_dK84YDAQRWGy0R4iDMZp46k";
}
