import React from "react";
import Select from "react-select";
import { InputVariant } from ".";
import withTheme from "../../ga-components/theme/withTheme";
import InputGroup from "./input-group";
import { Option } from "./option";

type Props = {
  theme: any;
  value: Option;
  options: Option[];
  onChange: Function;
  variant?: InputVariant;
  label?: string;
  name?:string
  placeholder?: string;
  error?: string;
  className?: string;
};
const SelectField: React.FC<Props> = ({
  theme,
  value,
  options,
  onChange,
  variant = InputVariant.FILLED,
  label = "",
  error = "",
  className = "",
  name=''
}) => {
  const classes = [className];
  if (error) classes.push(theme.input[variant].errorField);
  else classes.push(theme.input[variant].select);

  const onOptionChange = (value: any) => {
    const selectedOption = options.find((o) => o.value == value.value);
    if (selectedOption) onChange(selectedOption);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
    }),
  };
  return (
    <InputGroup label={label} error={error}>
      <Select
      name={name}
        defaultValue={value && { value: value.key, label: value.value }}
        styles={customStyles}
        className={`${classes.join(" ")}`}
        options={options.map((o) => ({ value: o.value, label: o.key }))}
        onChange={(data) => onOptionChange(data)}
      />
    </InputGroup>
  );
};

export default withTheme(SelectField);
