import { Result } from "../../domain/models/result";
import { FetchPostalAddressForPincode } from "../../domain/usages/fetch-postal-address-for-pincode";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteFetchPostalAddressForPincode
  implements FetchPostalAddressForPincode
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(pincode: number): Promise<Result[]> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":pincode", pincode.toString()),
      query: {},
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
    });

    return httpResponse.data;
  }
}
