import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Contact } from "../../../domain/models/contact";
import { HospitalDoctor } from "../../../domain/models/hospital-doctor";
import Clickable from "../../ga-components/clickable";
import TonalButton from "../common/buttons/tonal-button";
import FilledCard, { FilledColors } from "../common/cards/filled-card";

type HeaderProps = {
  title: string;
};
const HospitalDoctorHeader: React.FC<HeaderProps> = ({ title }) => {
  return <div>{title}</div>;
};

type Props = {
  doctor: HospitalDoctor;
  onClick: Function;
  addToGovaxx: (doctorId: string) => {};
  removeFromGoVaxx: (doctorId: string) => {};
  hospitalVaccinator: (doctorId: string, vaccinator: boolean) => {};
};
const HospitalDoctorCard: React.FC<Props> = ({
  doctor,
  onClick,
  addToGovaxx,
  removeFromGoVaxx,
  hospitalVaccinator,
}) => {
  const [mobile, setMobile] = useState<Contact | undefined>();
  const [email, setEmail] = useState<Contact | undefined>();

  useEffect(() => {
    if (doctor.contacts && doctor.contacts.length > 0) {
      const mobile: Contact | undefined = doctor.contacts.find(
        (c) => c.type == "mobile"
      );
      const email: Contact | undefined = doctor.contacts.find(
        (c) => c.type == "email"
      );
      setMobile(mobile);
      setEmail(email);
    }
  }, [doctor]);

  const onGovaxxClick = () => {
    Swal.fire({
      confirmButtonText: "Save",
      showCancelButton: true,
      title: `Do you want to mark ${doctor.full_name} as GoVaxx doctor?`,
    }).then((result) => {
      if (result.isConfirmed) {
        addToGovaxx(doctor._id);
      } else if (result.isDenied) {
      }
    });
  };

  const onRemoveGovaxxClick = () => {
    Swal.fire({
      confirmButtonText: "Save",
      showCancelButton: true,
      title: `Do you want to remove ${doctor.full_name} from GoVaxx?`,
    }).then((result) => {
      if (result.isConfirmed) {
        removeFromGoVaxx(doctor._id);
      } else if (result.isDenied) {
      }
    });
  };

  const onAddVaccinatorClick = () => {
    Swal.fire({
      confirmButtonText: "Save",
      showCancelButton: true,
      title: `Do you want to add ${doctor.full_name} to Vaccinator?`,
    }).then((result) => {
      if (result.isConfirmed) {
        hospitalVaccinator(doctor._id, true);
      } else if (result.isDenied) {
      }
    });
  };

  const onRemoveVaccinatorClick = () => {
    Swal.fire({
      confirmButtonText: "Save",
      showCancelButton: true,
      title: `Do you want to remove ${doctor.full_name} from Vaccinator?`,
    }).then((result) => {
      if (result.isConfirmed) {
        hospitalVaccinator(doctor._id, false);
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <Clickable onClick={onClick}>
      <FilledCard
        filledColor={doctor ? FilledColors.PRIMARY : FilledColors.SECONDARY}
        headline={<HospitalDoctorHeader title={doctor.full_name} />}
        subhead={doctor.speciality}
        supporting_text={
          <>
            <span>MOBILE: </span>
            <span>{mobile && mobile.value}</span>
            <br />
            <span>EMAIL: </span>
            <span>{email && email.value}</span>
          </>
        }
        actions={
          <div className="flex gap-4">
            <div>
              {!doctor.go_vaxx && (
                <TonalButton
                  text={"Add to govaxx"}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onGovaxxClick();
                  }}
                />
              )}
              {doctor.go_vaxx && (
                <TonalButton
                  text={"Remove from govaxx"}
                  primary={false}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onRemoveGovaxxClick();
                  }}
                />
              )}
            </div>
            <div>
              {!doctor.vaccinator && (
                <TonalButton
                  text={"Add to Vaccinator"}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onAddVaccinatorClick();
                  }}
                />
              )}
              {doctor.vaccinator && (
                <TonalButton
                  text={"Remove from Vaccinator"}
                  primary={false}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onRemoveVaccinatorClick();
                  }}
                />
              )}
            </div>
          </div>
        }
      />
    </Clickable>
  );
};

export default HospitalDoctorCard;
