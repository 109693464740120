import React, { useState } from "react";
import Swal from "sweetalert2";
import { AUTH_TOKEN } from "../../../base";
import { Constants } from "../../../common/Constants";
import SweetAlertMessageType from "../../../common/constants-types/sweet-alert-message";
import { Doctor } from "../../../domain/models/doctor";
import { Result } from "../../../domain/models/result";
import { UpdateDoctorSamplingVerification } from "../../../domain/usages/update-doctor-sampling-verification";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { Button, ButtonColor } from "../../ga-components/buttons";
import OrgCodeSelectionModal from "../sections/doctor/modal/OrgCodeSelectionModal";
import jwt_decode from "jwt-decode";
import { OrganizationCode } from "../../../domain/models/organizationCode";
import Utils from "../../../common/Utils";

type Props = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDoctorDetails: Doctor;
  updateDoctorSamplingVerification: UpdateDoctorSamplingVerification;
  orgCodes: OrganizationCode[];
};
const DoctorSampling: React.FC<Props> = ({
  setLoading,
  selectedDoctorDetails,
  updateDoctorSamplingVerification,
  orgCodes,
}) => {
  const [modalOn, setModalOn] = useState(false);
  const [showRejectMessage, setRejectionMessage] = useState(false);
  const storage = LocalJsonStorage.getInstance();
  const token: string = storage.get(AUTH_TOKEN);

  let jwtToken: any;
  let decodeData = "";

  if (token) {
    decodeData = atob(token);
    jwtToken = jwt_decode(decodeData);
  }

  const onClickAcceptSampling = () => {
    setModalOn(true);
    setRejectionMessage(false);
  };

  const onClickRejectSampling = () => {
    setModalOn(true);
    setRejectionMessage(true);
  };

  const updateStatus = async (
    status: boolean,
    SamplingOrgCode: string,
    rejectionReason: any
  ) => {
    const payload = {
      org_code: SamplingOrgCode,
      sampling_data: {
        sample_verification_flag: status,
        reason: rejectionReason,
        verified_by: { name: "", id: jwtToken.user_id },
      },
    };
    setLoading(true);
    let result: Result = await updateDoctorSamplingVerification.update(
      selectedDoctorDetails._id,
      payload
    );
    if (!result.errors) {
      setLoading(false);
      const data = Utils.findSamplingVerificationObjectByOrgCode(
        payload.org_code,
        selectedDoctorDetails.verification_flags
      );
      if (data) {
        {
          selectedDoctorDetails.verification_flags.map((flag) => {
            if (flag.org_code == payload.org_code) {
              flag.sampling_data.sample_verification_flag =
                payload.sampling_data.sample_verification_flag;
            }
          });
        }
      } else {
        selectedDoctorDetails.verification_flags.push(payload);
      }

      Swal.fire(result.message, "", SweetAlertMessageType.SUCCESS);
    } else {
      Swal.fire(result.errors.message, "", SweetAlertMessageType.ERROR);
    }
  };

  return (
    <div>
      <div className="flex gap-x-2 ml-5 items-center">
        <span className="font-bold">Sampling:</span>

        <Button
          onClick={onClickAcceptSampling}
          color={ButtonColor.SUCCESS}
          text="Accept"
          className="hover:bg-secondary hover:-translate-y-1 transform transition  focus:bg-secondary_low focus:text-primary mr-2"
        />
        <Button
          onClick={onClickRejectSampling}
          color={ButtonColor.ERROR}
          text="Reject"
          className="hover:bg-secondary hover:-translate-y-1 transform transition  focus:bg-secondary_low focus:text-primary"
        />
      </div>
      {modalOn && (
        <OrgCodeSelectionModal
          setModalOn={setModalOn}
          successCallback={updateStatus}
          doctorName={selectedDoctorDetails.full_name}
          showRejectMessage={showRejectMessage}
          organizationCodes={orgCodes}
          reasonsForRejection={[
            Constants.CLINIC_IMAGE_IS_MEDICAL_SHOP,
            Constants.DOCTOR_NOT_MBBS,
          ]}
          columns={["Orginzation code", "Sampling status"]}
          rows={selectedDoctorDetails.verification_flags}
        />
      )}
    </div>
  );
};

export default DoctorSampling;
