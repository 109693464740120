import { Controller, useForm } from "react-hook-form";
import { DoctorSearch } from "../../../../domain/models/doctor-search";
import { Option, TextField } from "../../../components/inputs";
import { Button } from "../../../ga-components/buttons";
import ButtonColor from "../../../ga-components/buttons/button-color";
import SelectField from "../../../ga-components/input/select";
import CheckBox from "../../../ga-components/input/CheckBox";

type SearchProps = {
  onSubmit: (fields: DoctorSearchDetails) => void;
  statusOptions: string[];
};

export type DoctorSearchDetails = {
  q: string;
  status: { value: string; label: string };
  page: number;
  sampling: string;
};

const DoctorSearchFilter = ({ onSubmit, statusOptions }: SearchProps) => {
  const { handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      q: "",
      status: { value: "", label: "" },
      sampling: "",
    } as DoctorSearchDetails,
  });

  return (
    <div>
      <span>
        <strong>Search:</strong>
      </span>
      <Controller
        name="q"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            onChange={onChange}
            placeholder="Search by name"
            label="Doctor Name"
          />
        )}
      />

      <Controller
        name="status"
        control={control}
        render={({ field: { onChange, value } }) => (
          <SelectField
            label="Status"
            valueData={value}
            name=" Status"
            placeholder="Select Status"
            options={statusOptions.map((s) => ({ key: s, value: s } as Option))}
            onChange={(value: any) => onChange(value)}
          />
        )}
      />
      <div className="mb-5">
        <Controller
          name="sampling"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CheckBox text="Sampling" value={value} onChange={onChange} />
          )}
        />
      </div>

      <Button
        onClick={handleSubmit(onSubmit)}
        color={ButtonColor.PRIMARY}
        text="Search"
        className="hover:bg-secondary hover:-translate-y-1 transform transition focus:bg-secondary-low focus:text-black"
      />
    </div>
  );
};
export default DoctorSearchFilter;
