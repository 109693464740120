import React from "react";
import "../../../../date.extension";
import { Doctor } from "../../../../domain/models/doctor";
import {
  Card,
  CardBody,
  CardColor,
  CardRightContent,
  CardSubHeadline,
  CardSupportingText,
  CardTags,
} from "../../../ga-components/cards";
import { Chip, ChipColor, ChipType } from "../../../ga-components/chip";
import Clickable from "../../../ga-components/clickable";

const calculateSize = (value: number) => {
  const i = ~~(Math.log2(value) / 10);
  return (
    (value / Math.pow(1024, i)).toFixed(2) + ("KMGTPEZY"[i - 1] || "") + "B"
  );
};

type Props = {
  doctor: Doctor;
  onClick: () => void;
  active?: boolean;
};

const DoctorItem: React.FC<Props> = ({ doctor, onClick, active = false }) => {
  return (
    <Clickable onClick={onClick}>
      <Card color={!active ? CardColor.PRIMARY_LOW : CardColor.SECONDARY_LOW}>
        <CardBody className="pb-0">
          <CardRightContent>
            <CardSupportingText className="text-right">
              <span className="">Age: {doctor.age} </span>
              <div>{doctor.gender}</div>
            </CardSupportingText>
          </CardRightContent>
          <CardSupportingText>#{doctor._id}</CardSupportingText>
          <CardSubHeadline>{doctor.full_name}</CardSubHeadline>
          <CardSupportingText className="mt-2">
            <span className="mr-2">CREATED AT:</span>
            {new Date(doctor.created_at).toStandardDate()}
          </CardSupportingText>
        </CardBody>
        <CardTags className="flex">
          <Chip
            type={ChipType.SUGGESTION}
            color={ChipColor.PRIMARY_MEDIUM}
            text={`Chemists: ${doctor.chemists && doctor.chemists.length}`}
            className="uppercase"
          />
          <span className="p-2"></span>
          <Chip
            type={ChipType.SUGGESTION}
            color={ChipColor.WHITE}
            text={`Clinics: ${doctor.chemists && doctor.chemists.length}`}
            className="uppercase"
          />
          <span className="p-2"></span>
        </CardTags>
      </Card>
    </Clickable>
  );
};

export default DoctorItem;
