import React from "react";
import { Clinic } from "../../../domain/models/clinic";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import DoctorClinicCard from "../../components/doctor/doctor-clinic-card";

type Props = {
  doctor_id: string;
  clinics: Clinic[];
  onItemClick: Function;
  getReadLink: GetReadLink;
  successCallback: Function;
};

const DoctorClinicList: React.FC<Props> = ({
  doctor_id,
  clinics,
  onItemClick,
  getReadLink,
  successCallback,
}) => {
  return (
    <div className="p-2">
      {clinics &&
        clinics.map((c, index) => (
          <DoctorClinicCard
            key={c._id}
            getReadLink={getReadLink}
            clinic={c}
            onClick={() => onItemClick(c)}
          />
        ))}
    </div>
  );
};

export default DoctorClinicList;
