import ChemistStatus from "../domain/enums/chemist-status";
import ClinicStatus from "../domain/enums/clinic-status";
import DoctorStatus from "../domain/enums/doctor-status";
import { SamplingVerification } from "../domain/models/sampling_verification";

export default class Utils {
  static isDoctorVerified = (status: string) => {
    if (status == DoctorStatus.VERIFIED) {
      return true;
    }
    return false;
  };
  static isDoctorPending = (status: string) => {
    if (status == DoctorStatus.PENDING || status == undefined) {
      return true;
    }
    return false;
  };
  static isDoctorRejected = (status: string) => {
    if (status == DoctorStatus.REJECTED) {
      return true;
    }
    return false;
  };
  static isClinicVerified = (status: string) => {
    if (status == ClinicStatus.VERIFIED) {
      return true;
    }
    return false;
  };

  static isClinicPending = (status: string) => {
    if (status == ClinicStatus.PENDING || status == undefined) {
      return true;
    }
    return false;
  };

  static isClinicRejected = (status: string) => {
    if (status == ClinicStatus.REJECTED) {
      return true;
    }
    return false;
  };
  static isChemistVerified = (status: string) => {
    if (status == ChemistStatus.VERIFIED) {
      return true;
    }
    return false;
  };
  static isChemistRejected = (status: string) => {
    if (status == ChemistStatus.REJECTED) {
      return true;
    }
    return false;
  };
  static isChemistPending = (status: string) => {
    if (status == ChemistStatus.PENDING || status == undefined) {
      return true;
    }
    return false;
  };
  static findSamplingVerificationObjectByOrgCode = (orgCode: string, array: SamplingVerification[]) => {
    const data = array.find((element) => element.org_code == orgCode);
    return data;
  };
}
