import React, { useCallback, useEffect, useState } from "react";
import { PostOffice } from "../../../domain/models/post-office";
import { Result } from "../../../domain/models/result";
import { FetchPostalAddressForPincode } from "../../../domain/usages/fetch-postal-address-for-pincode";
import { InputVariant, TextField } from "../../components/inputs";

type Props = {
  value: number;
  fetchPostalAddressForPincode: FetchPostalAddressForPincode;
  onChange: (postOffice: PostOffice) => void;
  error?: string;
};
const PincodeToPostalField: React.FC<Props> = ({
  value,
  onChange,
  fetchPostalAddressForPincode,
  error = "",
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pincode, setPincode] = useState<string>(value ? value.toString() : "");
  const [postOffice, setPostOffice] = useState<PostOffice>();
  const [helpText, setHelpText] = useState<string>();
  const [fieldError, setFieldError] = useState<string>(error);

  const onOptionChange = (value: any) => {
    console.log(value.target.value);
    setPincode(value.target.value);
  };

  useEffect(() => {
    console.log(pincode);
    if (pincode && pincode.length == 6) {
      fetchPostOffices(parseInt(pincode));
    } else {
      setFieldError("Invalid pincode");
      setPostOffice(undefined);
    }
  }, [pincode]);

  useEffect(() => {
    if (postOffice) {
      const helpText = `${postOffice?.District},${postOffice?.State},${postOffice?.Country}`;
      setHelpText(helpText);
    } else {
      setHelpText(undefined);
    }
  }, [postOffice]);

  const fetchPostOffices = useCallback(
    async (pincode: number) => {
      setLoading(true);
      let results: Result[] = await fetchPostalAddressForPincode.fetch(pincode);
      if (
        results &&
        results[0].PostOffice &&
        results[0].PostOffice.length > 0
      ) {
        const postOffice = results[0].PostOffice[0];
        postOffice.pincode = pincode;
        onChange(postOffice);
        setPostOffice(postOffice);
        setFieldError("");
      } else {
        setPostOffice(undefined);
        setFieldError("Invalid pincode");
      }

      setLoading(false);
    },
    [fetchPostalAddressForPincode, pincode]
  );

  return (
    <TextField
      value={pincode}
      onChange={onOptionChange}
      variant={InputVariant.FILLED}
      error={fieldError && fieldError}
      placeholder="Pincode"
      label="Pincode"
      helpText={helpText}
    />
  );
};

export default PincodeToPostalField;
