import {
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { Contacts } from "../../../../../domain/models/doctor/contacts";

type Props = {
  existingSocialMediaLinkDetails: Contacts[];
  isVerified: boolean[];
  handlePendingSocialMediaLink: Function;
  removeSocialMediaLinkField: Function;
  socialMediaLinkDetails: {
    type: string;
    value: string;
    status: string;
  }[];
  handleSocialMediaLinkChange: Function;
  handleChangeSocialMediaType: Function;
  addSocialMediaLinkField: Function;
  formErrors: string[];
  socialMediaType: string[]
};

const SocialMediaLink: React.FC<Props> = ({
  existingSocialMediaLinkDetails,
  isVerified,
  handlePendingSocialMediaLink,
  removeSocialMediaLinkField,
  socialMediaLinkDetails,
  handleSocialMediaLinkChange,
  formErrors,
  addSocialMediaLinkField,
  handleChangeSocialMediaType,
  socialMediaType
}) => {
  const socialMediaList = [
    { id: 1, name: "twitter" },
    { id: 2, name: "facebook" },
    { id: 3, name: "linkedin" },
  ];

  return (
    <div>
      <Typography variant="subtitle2">Social Media Link(s):</Typography>
      {existingSocialMediaLinkDetails?.length > 0 &&
        existingSocialMediaLinkDetails?.map((data, index) => {
          return (
            <div style={{ marginBottom: 10, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <TextField
                  value={data.value}
                  fullWidth
                />
              </div>
            </div>
          );
        })}
      {socialMediaLinkDetails.map((data, index) => {
        return (
          <div style={{ marginBottom: 10, width: "100%" }}>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
              }}
            >
              <TextField
                name="socialMedia"
                placeholder="Enter Social Media Links"
                value={data.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleSocialMediaLinkChange(index, event.target.value)
                }
                error={formErrors[index] ? true : false}
                helperText={formErrors[index]}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ gap: 1 }}>
                      <TextField
                        select
                        size="small"
                        value={socialMediaType.length>0?socialMediaType[index]:""}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChangeSocialMediaType(index, event.target.value)
                        }
                      >
                        {socialMediaList.map((option) => (
                          <MenuItem key={option.id} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton onClick={() => removeSocialMediaLinkField(index)}>
                <Tooltip title="Cancel">
                  <RemoveCircleOutlineIcon color="error" />
                </Tooltip>
              </IconButton>
            </div>
          </div>
        );
      })}
      <Button
        variant="text"
        color="warning"
        onClick={() => addSocialMediaLinkField()}
      >
        <AddIcon /> ADD SOCIAL MEDIA LINKS
      </Button>
    </div>
  );
};

export default SocialMediaLink;
