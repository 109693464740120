import React, { useCallback, useEffect, useState } from "react";
import { Constants } from "../../../common/Constants";
import ClinicStatus from "../../../domain/enums/clinic-status";
import { Clinic } from "../../../domain/models/clinic";
import { Result } from "../../../domain/models/result";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import Clickable from "../../ga-components/clickable";
import FilledCard, { FilledColors } from "../common/cards/filled-card";

type HeaderProps = {
  title: string;
  status: string;
};
const DoctorClinicCardHeader: React.FC<HeaderProps> = ({ title, status }) => {
  const statusColor: Record<string, string> = {
    [ClinicStatus.PENDING]: Constants.PENDING_TEXT,
    [ClinicStatus.VERIFIED]: Constants.APPROVED_TEXT,
    [ClinicStatus.REJECTED]: Constants.REJECTED_TEXT,
  };
  const clinicStatus: Record<string, string> = {
    [ClinicStatus.PENDING]: Constants.PENDING,
    [ClinicStatus.VERIFIED]: Constants.VERIFIED,
    [ClinicStatus.REJECTED]: Constants.REJECT,
  };
  return (
    <div>
      {title}
      {status && (
        <span className={`${statusColor[status]} ml-5 text-base border p-2`}>
          {clinicStatus[status]}
        </span>
      )}
    </div>
  );
};

type Props = {
  clinic: Clinic;
  getReadLink: GetReadLink;
  onClick: Function;
};
const DoctorClinicCard: React.FC<Props> = ({
  clinic,
  getReadLink,
  onClick,
}) => {
  const [link, setLink] = useState<string>();
  const findReadLink = useCallback(async () => {
    let result: Result = await getReadLink.get(clinic.billboard[0].uuid, {});
    setLink(result.url);
  }, [getReadLink, clinic]);

  useEffect(() => {
    findReadLink();
  }, []);

  return (
    <Clickable onClick={onClick}>
      <FilledCard
        image={link && link}
        filledColor={clinic ? FilledColors.PRIMARY : FilledColors.SECONDARY}
        headline={
          <DoctorClinicCardHeader
            title={clinic.full_name}
            status={clinic.verification_status}
          />
        }
        subhead={clinic.mobile}
        supporting_text={
          <>
            <span>No. of Staff: </span>
            <span>{clinic.no_of_staff}</span>
          </>
        }
      />
    </Clickable>
  );
};

export default DoctorClinicCard;
