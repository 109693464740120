import React, { useEffect, useState } from "react";

const withCurrentLocation = (Component: any) => {
  const WithCurrentLocation: React.FC<any> = (props) => {
    const { geolocation } = navigator;
    const [isLocationAllowed, setIsLocationAllowed] = useState(true);
    const [location, setLocation] = useState<GeolocationCoordinates>();

    useEffect(() => {
      geolocation.getCurrentPosition(
        (position) => {
          setIsLocationAllowed(true);
          setLocation(position.coords);
        },
        (error) => {
          setIsLocationAllowed(false);
        },
        {}
      );
    }, []);

    return (
      <Component
        {...props}
        location={location}
        isLocationAllowed={isLocationAllowed}
      />
    );
  };

  WithCurrentLocation.displayName = `WithCurrentLocation(${Component.displayName})`;
  return WithCurrentLocation;
};

export default withCurrentLocation;
