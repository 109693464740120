import React from "react";
import { ChipColor } from ".";
import { ChipType } from ".";
import withTheme from "../theme/withTheme";

type Props = {
  theme: any;
  text: React.ReactNode;
  type?: ChipType;
  color?: ChipColor;
  className?: string;
};

const Chip: React.FC<Props> = ({
  theme,
  text,
  type = ChipType.FILTER,
  color = ChipColor.PRIMARY,
  className = "",
}) => {
  const cssClass = [
    theme.chip.base,
    theme.chip[type],
    theme.chip.colors[color],
    className,
  ];

  return (
    <span className={cssClass.join(" ")}>
      <span className={theme.chip.text}>{text}</span>
    </span>
  );
};

export default withTheme(Chip);
