enum CardColor {
  PRIMARY = "primary",
  PRIMARY_MEDIUM = "primaryMedium",
  PRIMARY_LOW = "primaryLow",
  SECONDARY = "secondary",
  SECONDARY_MEDIUM = "secondaryMedium",
  SECONDARY_LOW = "secondaryLow",
  WHITE = "white",
}

export default CardColor;
