import React from "react";
import { Hospital } from "../../../domain/models/hospital";
import DoctorHospitalCard from "../../components/doctor/doctor-hospital-card";

type Props = {
  hospitals: Hospital[];
  onItemClick: Function;
};

const DoctorHospitalList: React.FC<Props> = ({
  hospitals,
  onItemClick,
}) => {
  return (
    <div className="p-2">
      {hospitals &&
        hospitals.map((hospital) => (
          <DoctorHospitalCard
            key={hospital._id}
            hospital={hospital}
            onClick={() => onItemClick(hospital)}
          />
        ))}
    </div>
  );
};

export default DoctorHospitalList;
