import {
  AUTH_TOKEN,
  AUTH_USER,
  LOGGEDIN_USER,
} from "../../base";
import { LoggedInUserDetails } from "../../domain/models/loggedInUser";
import { User } from "../../domain/models/user";
import { LoggedInUser } from "../../domain/usages/logged-in-user";
import { JsonStorage } from "../protocols/storage/json-storage";

// TODO:: User encryption to store user data to local storage.
export class LocalLoggedInUser implements LoggedInUser {
  userKey: string = AUTH_USER;
  tokenKey: string = AUTH_TOKEN;
  loggedInUser: string = LOGGEDIN_USER;

  constructor(private readonly jsonStorage: JsonStorage) {}
  setLoggedIn: any;

  setUser(user: User): void {
    this.jsonStorage.add(this.userKey, this.encrypt(JSON.stringify(user)));
  }

  setLoggedInUser(user: LoggedInUserDetails): void {
    this.jsonStorage.add(this.loggedInUser, JSON.stringify(user));
  }

  getUser(): User | null {
    let user = this.jsonStorage.get(this.userKey);
    if (user && user !== "") return JSON.parse(this.decrypt(user));
    return null;
  }

  getLoggedInUser(): LoggedInUserDetails | null {
    let user = this.jsonStorage.get(this.loggedInUser);
    if (user) return JSON.parse(user);
    return null;
  }
  getToken(): string | null {
    let token = this.jsonStorage.get(this.tokenKey);
    if (token && token !== "") return this.decrypt(token);
    return null;
  }

  setToken(token: string): void {
    this.jsonStorage.add(this.tokenKey, this.encrypt(token));
  }

  logout(): void {
    this.jsonStorage.remove(this.userKey);
    this.jsonStorage.remove(this.tokenKey);
    this.jsonStorage.remove(this.loggedInUser);
  }

  resetUser(user: User): void {
    this.jsonStorage.add(this.userKey, this.encrypt(JSON.stringify(user)));
  }

  encrypt(value: string) {
    return btoa(value);
  }

  decrypt(value: string) {
    return atob(value);
  }
}
