import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Label, { LabelTypes } from "../typography/label";
import Icon, { IconSize, IconTypes } from "../typography/icon";
import withTheme from "../../../ga-components/theme/withTheme";

export enum TabType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  WHITE = "primaryLow",
}

export type TabItem = {
  id: number;
  title: string;
  icon: IconDefinition;
};

type Props = {
  theme: any;
  item: TabItem;
  type?: TabType;
  onClick: Function;
  className?: string;
};

const Tab: React.FC<Props> = ({
  theme,
  item,
  type = TabType.PRIMARY,
  onClick,
  className = "",
}) => {
  const classes = [className, theme.bg[type]];
  return (
    <li
      className={`flex-grow w-70 h-70 ${classes.join(" ")}`}
      onClick={() => {
        onClick(item.id);
      }}
    >
      <a className="relative w-full h-full flex flex-col justify-center items-center text-center">
        <Icon
          icon={item.icon}
          size={IconSize.xl}
          className="block leading-extra-loose"
          type={type === TabType.PRIMARY ? IconTypes.WHITE : IconTypes.PRIMARY}
        />
        {type === TabType.PRIMARY && (
          <Label
            text={item.title}
            className="font-bold w-full mt-2"
            type={LabelTypes.WHITE}
          />
        )}
      </a>
    </li>
  );
};
export default withTheme(Tab);
