import React from "react";
import Select from "react-select";
import { InputVariant } from ".";
import withTheme from "../../ga-components/theme/withTheme";
import InputGroup from "./input-group";
import { Option } from "./option";

type Props = {
  theme: any;
  value: Option[];
  options: Option[];
  onChange: Function;
  variant?: InputVariant;
  label?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  name?:string;
};
const MultiSelectField: React.FC<Props> = ({
  theme,
  value,
  options,
  onChange,
  variant = InputVariant.FILLED,
  label = "",
  error = "",
  className = "",
  name=""
}) => {
  const classes = [className];
  if (error) classes.push(theme.input[variant].errorField);
  else classes.push(theme.input[variant].select);

  const onOptionChange = (value: any) => {
    const selectedOptions = options.filter((o) => {
      return value.find((v: any) => v.value == o.value);
    });
    if (selectedOptions) onChange(selectedOptions);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
    }),
  };
  return (
    <InputGroup label={label} error={error}>
      <Select
        isMulti
        defaultValue={
          value && value.map((v: any) => ({ value: v.key, label: v.value }))
        }
        styles={customStyles}
        className={`${classes.join(" ")}`}
        options={options.map((o) => ({ value: o.value, label: o.key }))}
        onChange={(data) => onOptionChange(data)}
        name={name}
      />
    </InputGroup>
  );
};

export default withTheme(MultiSelectField);
