import { EmptyResponse, Result } from "../../domain/models/result";
import { AddDoctorHospital } from "../../domain/usages/add-doctor-hospital";
import { Constants } from "../../common/Constants";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteAddDoctorHospital implements AddDoctorHospital {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPutClient
  ) {}

  async create(id: string, params: AddDoctorHospital.Params): Promise<Result> {
    const httpResponse = await this.httpPostClient.put({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: Constants.HTTP_ERROR_MESSAGE };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
