import { GetDoctorByID } from "../../../../domain/usages/data-entry/doctor-verification/get-doctor-data-by-id";
import { HttpConstants } from "../../../protocols/http/http-constants";
import { HttpGetClient } from "../../../protocols/http/http-get-client";

export class RemoteGetDoctorByID implements GetDoctorByID {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}
  async get(id: string, params: GetDoctorByID.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":id", id),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
