import React from "react";
import { useNavigate } from "react-router-dom";
import withTheme from "../theme/withTheme";
import TabModel from "./tab-model";

type Props = {
  theme: any;
  item: TabModel;
  onClick: () => void;
  className?: string;
  active?: boolean;
};
const Tab: React.FC<Props> = ({
  theme,
  item,
  onClick,
  className = "",
  active,
}) => {
  const cssClass = [
    className,
    theme.tab.item.base,
    active ? theme.tab.item.active : theme.tab.item.inActive,
  ].join(" ");

  return (
    <li>
      <a className={cssClass} onClick={onClick}>
        {item.icon && item.icon}
        {item.text && item.text}
      </a>
    </li>
  );
};

export default withTheme(Tab);
