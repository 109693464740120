import React from "react";
import withTheme from "../theme/withTheme";
import NavItem from "./nav-item";
import NavModel from "./nav-model";

type Props = {
  theme: any;
  items: NavModel[];
  className?: string;
};
const Nav: React.FC<Props> = ({ theme, items, className = "" }) => {
  const cssClass = [className, theme.nav.base].join(" ");
  return (
    <ul className={cssClass}>
      {items.map((i, index) => (
        <NavItem item={i} key={index} />
      ))}
    </ul>
  );
};

export default withTheme(Nav);
