import React from "react";
import withTheme from "../theme/withTheme";

type Props = {
  theme: any;
  title: any;
};
const SectionTitle: React.FC<Props> = ({ theme, title }) => {
  return <h2 className={theme.section.title}>{title}</h2>;
};

export default withTheme(SectionTitle);
