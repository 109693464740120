import { EmptyResponse, Result } from "../../domain/models/result";
import { UpdateHospitalDoctor } from "../../domain/usages/update-hospital-doctor";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteUpdateHospitalDoctor implements UpdateHospitalDoctor {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async update(
    id: string,
    doctorId: string,
    params: UpdateHospitalDoctor.Params
  ): Promise<Result> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id).replace(":doctor_id", doctorId),
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: "Server error. Please try after sometime." };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
