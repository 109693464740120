import React, { useContext } from "react";
import { AppUserContext } from "../../UserContext";
import { Icon, IconColor, IconSize } from "../ga-components/icon";
import { NavModel, Account } from "../ga-components/nav";
import withTheme from "../ga-components/theme/withTheme";

const NavItems = [
  { text: "My Account", active: true } as NavModel,
  { text: "Cards" } as NavModel,
  { text: "Offers" } as NavModel,
];

type Props = {
  theme: any;
};

const AppHeader: React.FC<Props> = ({ theme }) => {
  const {
    state: {},
    dispatch,
  } = useContext(AppUserContext);

  return (
    <nav className={theme.header.container}>
      <div className={theme.header.base}>
        <a href="#" className={theme.header.logo.base}>
          <div className={theme.header.logo.icon}>
            <Icon icon="logo" size={IconSize.xl} color={IconColor.WHITE} />
          </div>
          <p className={theme.header.logo.title}>
            MyDocData
            <span className={theme.header.logo.boldTitle}>Profiler</span>
          </p>
        </a>
        {/* <Nav items={NavItems} /> */}
        <Account />
      </div>
    </nav>
  );
};

export default withTheme(AppHeader);
