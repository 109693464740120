import {
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { DoctorQualification } from "../../../../../domain/models/doctor/qualification";
import { Qualification } from "../../../../../domain/models/qualification";

type Props = {
  existingQualificationDetails: DoctorQualification[];
  isVerified: boolean[];
  handlePendingQualification: Function;
  removeQualificationField: Function;
  qualificationDetails: {
    value: string;
  }[];
  handleQualificationChange: Function;
  addQualificationField: Function;
  formErrors: string[];
  qualifications: Qualification[];
};

type QualificationType = {
  value: string;
};

const QualificationField: React.FC<Props> = ({
  existingQualificationDetails,
  isVerified,
  handlePendingQualification,
  removeQualificationField,
  qualificationDetails,
  handleQualificationChange,
  formErrors,
  addQualificationField,
  qualifications,
}) => {
  return (
    <div>
      <Typography variant="subtitle2">Qualification(s):</Typography>
      {existingQualificationDetails?.length > 0 &&
        existingQualificationDetails?.map((data, index) => {
          return (
            <div style={{ marginBottom: 10, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <TextField
                  value={data.value}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isVerified[index] ? (
                          <Tooltip title="Verified">
                            <CheckCircleOutlineIcon color="success" />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Pending, click to verify">
                            <ReportGmailerrorredIcon
                              color="warning"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handlePendingQualification(
                                  index,
                                  "qualification"
                                )
                              }
                            />
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          );
        })}
      {qualificationDetails.map((data, index) => {
        return (
          <div style={{ marginBottom: 10, width: "100%" }}>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
              }}
            >
              <TextField
                name="Qualification"
                placeholder="Select Qualification"
                select
                value={data.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleQualificationChange(index, event.target.value)
                }
                error={formErrors[index] ? true : false}
                helperText={formErrors[index]}
                fullWidth
              >
                {qualifications.map((qualification, index) => (
                  <MenuItem key={index} value={qualification.name}>
                    {qualification.name}
                  </MenuItem>
                ))}
              </TextField>
              <IconButton onClick={() => removeQualificationField(index)}>
                <Tooltip title="Cancel">
                  <RemoveCircleOutlineIcon color="error" />
                </Tooltip>
              </IconButton>
            </div>
          </div>
        );
      })}
      <Button
        variant="text"
        color="warning"
        onClick={() => addQualificationField()}
      >
        <AddIcon /> ADD Qualification
      </Button>
    </div>
  );
};

export default QualificationField;
