import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
  LOCATION_SERVICE_API_URL,
  LOCATION_SERVICE_TOKEN,
} from "../../base";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { RemoteFetchDoctors } from "../../data/usecases/data-entry/doctor-verification/remote-fetch-doctors";
import { RemoteGetDoctorByID } from "../../data/usecases/data-entry/doctor-verification/remote-get-doctor-data-by-id";
import { RemoteRejectImage } from "../../data/usecases/data-entry/doctor-verification/remote-reject-image";
import { RemoteGetImageRejectionReasons } from "../../data/usecases/data-entry/doctor-verification/remote-get-image-rejection-reasons";
import { RemoteSaveDoctorBasicDetails } from "../../data/usecases/data-entry/doctor-verification/remote-save-doctor-basic-details";
import { RemoteVerifyDoctorContacts } from "../../data/usecases/data-entry/doctor-verification/remote-verify-doctor-contacts";
import { RemoteVerifyDoctorAddress } from "../../data/usecases/data-entry/doctor-verification/remote-verify-doctor-address";
import { RemoteVerifyDoctorName } from "../../data/usecases/data-entry/doctor-verification/remote-verify-doctor-name";
import { RemoteVerifyDoctorQualification } from "../../data/usecases/data-entry/doctor-verification/remote-verify-doctor-qualification";
import { RemoteVerifyDoctorSpeciality } from "../../data/usecases/data-entry/doctor-verification/remote-verify-doctor-speciality";
import { RemoteFetchDoctorMasters } from "../../data/usecases/remote-fetch-doctor-masters";
import DoctorProfilerVerification from "../../presentation/pages/data-entry/DoctorProfilerVerification";
import { RemoteGetReadLink } from "../../data/usecases/remote-get-read-link";
import Endpoints from "../../domain//endpoints";
import { RemoteFetchBatchSizes } from "../../data/usecases/data-entry/remote-fetch-batch-sizes";
import { RemoteCreateBatch } from "../../data/usecases/data-entry/remote-create-batch";
import { RemoteEndBatch } from "../../data/usecases/data-entry/remote-end-batch";
import { RemoteGetBatchData } from "../../data/usecases/data-entry/remote-get-batch-data";
import { RemoteGetAddressByPincode } from "../../data/usecases/remote-get-address-by-pincode";
import { Constants } from "../../common/Constants";
import React from "react";

type Props = {};

export const DoctorVerificationFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN);
  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
  });

  const axiosHttpClientMaster = AxiosHttpClient.getInstance();
  axiosHttpClientMaster.setAuthHeaders({
    [AUTH_HEADER_VERIFICATION]: `${LOCATION_SERVICE_TOKEN}`,
  });

  const remoteFetchDoctors = new RemoteFetchDoctors(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_DOCTOR_FOR_DATA_ENTRY}`,
    axiosHttpClient
  );
  const remoteGetDoctorByID = new RemoteGetDoctorByID(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.GET_DOCTOR_DATA_BY_ID}`,
    axiosHttpClient
  );

  const remoteGetImageRejection = new RemoteGetImageRejectionReasons(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.GET_IMAGE_REJECTION_REASONS}`,
    axiosHttpClient
  );

  const remoteRejectImage = new RemoteRejectImage(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.MARK_IMAGE_AS_REJECT}`,
    axiosHttpClient
  );

  const remoteSaveDoctorBasicDetails = new RemoteSaveDoctorBasicDetails(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.SAVE_BASIC_DOCTOR_DETAILS}`,
    axiosHttpClient
  );

  const remoteVerifyDoctorContacts = new RemoteVerifyDoctorContacts(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.VERIFY_DOCTOR_CONTACT}`,
    axiosHttpClient
  );

  const remoteVerifyDoctorName = new RemoteVerifyDoctorName(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.VERIFY_NAME}`,
    axiosHttpClient
  );

  const remoteVerifyDoctorQualification = new RemoteVerifyDoctorQualification(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.VERIFY_QUALIFICATION}`,
    axiosHttpClient
  );
  const remoteVerifyDoctorSpeciality = new RemoteVerifyDoctorSpeciality(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.VERIFY_SPECIALITY}`,
    axiosHttpClient
  );
  const remoteVerifyDoctorAddress = new RemoteVerifyDoctorAddress(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.VERIFY_ADDRESS}`,
    axiosHttpClient
  );
  const remoteGetReadLink = new RemoteGetReadLink(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.IMAGE_READ_URL}`,
    axiosHttpClient
  );

  const remoteFetchDoctorMasters = new RemoteFetchDoctorMasters(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_DOCTOR_MASTERS}`,
    axiosHttpClient
  );

  const remoteFetchBatchSizes = new RemoteFetchBatchSizes(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_BATCH_SIZES}`,
    axiosHttpClient
  );

  const remoteCreateBatch = new RemoteCreateBatch(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.CREATE_BATCH}`,
    axiosHttpClient
  );

  const remoteEndBatch = new RemoteEndBatch(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.END_BATCH}`,
    axiosHttpClient
  );

  const remoteGetBatchData = new RemoteGetBatchData(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.CREATE_BATCH}`,
    axiosHttpClient
  );

  const remoteGetAddressByPincode = new RemoteGetAddressByPincode(
    `${LOCATION_SERVICE_API_URL}${Endpoints.GET_ADDRESS_BY_PINCODE}`,
    axiosHttpClientMaster
  );

  return (
    <DoctorProfilerVerification
      fetchDoctors={remoteFetchDoctors}
      getDoctorByID={remoteGetDoctorByID}
      getImageRejectionReasons={remoteGetImageRejection}
      rejectImage={remoteRejectImage}
      saveDoctorDetails={remoteSaveDoctorBasicDetails}
      verifyDoctorDetails={remoteVerifyDoctorContacts}
      getReadLink={remoteGetReadLink}
      verifyAddress={remoteVerifyDoctorAddress}
      verifyName={remoteVerifyDoctorName}
      verifyQualification={remoteVerifyDoctorQualification}
      verifySpeciality={remoteVerifyDoctorSpeciality}
      fetchMasters={remoteFetchDoctorMasters}
      getAddress={remoteGetAddressByPincode}
      fetchBatchSizes={remoteFetchBatchSizes}
      createBatch={remoteCreateBatch}
      endBatch={remoteEndBatch}
      getBatchData={remoteGetBatchData}
    />
  );
};
