import React from "react";
import { Clinic } from "../../../domain/models/clinic";
import Clickable from "../../ga-components/clickable";
import {
  Card,
  CardBody,
  CardColor,
  CardSupportingText,
} from "../../ga-components/cards";

type HeaderProps = {
  title: string;
  id: string;
};
const DoctorClinicCardHeader: React.FC<HeaderProps> = ({ title, id }) => {
  return (
    <>
      <strong className="text-black text-base">{title}</strong>
      <br />#{id}
    </>
  );
};

type Props = {
  clinic: Clinic;
  onClick: Function;
};
const ClinicCard: React.FC<Props> = ({ clinic, onClick }) => {
  return (
    <Clickable onClick={onClick}>
      <Card color={CardColor.PRIMARY_LOW} className="m-1">
        <CardBody>
          <CardSupportingText>
            <DoctorClinicCardHeader title={clinic.full_name} id={clinic._id} />
          </CardSupportingText>
        </CardBody>
      </Card>
    </Clickable>
  );
};

export default ClinicCard;
