import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Address } from "../../../domain/models/address";
import { Chemist } from "../../../domain/models/chemist";
import { Clinic } from "../../../domain/models/clinic";
import { Contact } from "../../../domain/models/contact";
import { Doctor } from "../../../domain/models/doctor";
import { DoctorMaster } from "../../../domain/models/doctor-master";
import { PostOffice } from "../../../domain/models/post-office";
import { FetchPostalAddressForPincode } from "../../../domain/usages/fetch-postal-address-for-pincode";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import PincodeToPostalField from "../../data-driven/common/pincode-to-postal-field";
import TonalFixedButton from "../common/buttons/tonal-fixed-button";
import {
  InputVariant,
  MultiSelectField,
  Option,
  SelectField,
  TextField,
} from "../inputs/index";

type DoctorDetails = {
  full_name: string;
  gender: string;
  age: string;
  mobile: string;
  email: string;
  qualifications: string[];
  registration_number: string;
  speciality: string;
  addresses: Address[];
  clinics: Clinic[];
  chemists: Chemist[];
};

const convertDoctorToDetails = (doctor: Doctor) => {
  const details: DoctorDetails = {} as DoctorDetails;
  if (doctor.contacts && doctor.contacts.length > 0) {
    let mobile = doctor.contacts.find((c) => c.type == "mobile");
    let email = doctor.contacts.find((c) => c.type == "email");
    if (mobile) details.mobile = mobile.value;
    if (email) details.email = email.value;
  }
  details.full_name = doctor.full_name;
  details.age = doctor.age;
  details.gender = doctor.gender;
  details.registration_number = doctor.registration_number;
  details.qualifications = doctor.qualifications;
  details.speciality = doctor.speciality;
  details.addresses = doctor.addresses;
  details.clinics = doctor.clinics;
  details.chemists = doctor.chemists;
  return details;
};

type Props = {
  doctor: Doctor;
  errors: {};
  master: DoctorMaster;
  successCallback: (doctor: Doctor) => void;
  fetchPostalAddressForPincode: FetchPostalAddressForPincode;
};
const CreateDoctorForm: React.FC<Props> = ({
  doctor,
  errors = {},
  successCallback,
  master,
  fetchPostalAddressForPincode,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [postOffice, setPostOffice] = useState<PostOffice>();
  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: convertDoctorToDetails(doctor),
  });

  const storage = LocalJsonStorage.getInstance();
  const location = storage.get("lat-long");

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const contacts = [];
      const address: Address = data.addresses[0];
      if (data.mobile) {
        contacts.push({ value: data.mobile, type: "mobile" } as Contact);
      }
      if (data.email) {
        contacts.push({ value: data.email, type: "email" } as Contact);
      }
      data.contacts = contacts;
      if (postOffice) {
        address.district = postOffice.District;
        address.state = postOffice.State;
        address.country = postOffice.Country;
        address.lat_long = location;
      }
      successCallback({
        full_name: data.full_name,
        gender: data.gender,
        age: data.age,
        contacts: contacts,
        qualifications: data.qualifications,
        registration_number: data.registration_number,
        speciality: data.speciality,
        addresses: [address],
      } as Doctor);
    },
    [postOffice]
  );

  console.log(formErrors);
  return (
    <div>
      <div className="p-4">
        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              placeholder="Doctor Name"
              error={error && error.message}
              label="Doctor Name"
            />
          )}
          rules={{ required: { value: true, message: "Name is required" } }}
        />

        <Controller
          name="age"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectField
              value={{ value: value, key: value } as Option}
              onChange={(value: any) => onChange(value.value)}
              options={master.ageGroups.map(
                (ag) => ({ key: ag.name, value: ag.name } as Option)
              )}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Age"
              label="Age"
            />
          )}
          rules={{
            required: { value: true, message: "Age is required" },
          }}
        />

        <Controller
          name="gender"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectField
              value={{ value: value, key: value } as Option}
              onChange={(value: any) => onChange(value.value)}
              options={master.genders.map(
                (ag) => ({ key: ag.name, value: ag.name } as Option)
              )}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Gender"
              label="Gender"
            />
          )}
          rules={{
            required: { value: true, message: "Gender is required" },
          }}
        />

        <Controller
          name="mobile"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              placeholder="Doctor's Mobile Number"
              error={error && error.message}
              label="Doctor's Mobile Number"
            />
          )}
          rules={{
            pattern: {
              value: /^[6-9]{1}[0-9]{9}$/,
              message: "Invalid mobile",
            },
          }}
        />

        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              placeholder="Doctor's Email"
              error={error && error.message}
              label="Doctor's Email"
            />
          )}
        />

        <Controller
          name="speciality"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectField
              className="p-0"
              value={{ value: value, key: value }}
              placeholder="Select Speciality"
              label="Select Speciality"
              onChange={(value: any) => onChange(value.value)}
              error={error && error.message}
              options={master.specialities.map(
                (s) => ({ key: s.name, value: s.name } as Option)
              )}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />

        <Controller
          name="qualifications"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <MultiSelectField
              value={value
                .map((v: any) =>
                  master.qualifications.find((cv: any) => cv.name == v)
                )
                .map((v: any) => ({ key: v.name, value: v.name } as Option))}
              placeholder="Select Qualifications"
              label="Select Qualifications"
              onChange={(value: any) =>
                onChange(value.map((v: any) => v.value))
              }
              error={error && error.message}
              options={master.qualifications.map(
                (q) => ({ key: q.name, value: q.name } as Option)
              )}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />

        <Controller
          name="addresses.0.pincode"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PincodeToPostalField
              value={value}
              fetchPostalAddressForPincode={fetchPostalAddressForPincode}
              onChange={(postOffice: PostOffice) => {
                setPostOffice(postOffice);
                onChange(postOffice.pincode);
              }}
              error={error && error.message}
            />
          )}
          rules={{
            pattern: {
              value: /^[1-9]{1}[0-9]{5}$/,
              message: "Invalid pincode",
            },
            required: { value: true, message: "Pincode is required" },
          }}
        />

        <Controller
          name="addresses.0.line"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Building/Street"
              label="Building/Street"
            />
          )}
          rules={{
            required: { value: true, message: "Address line is required" },
          }}
        />

        <Controller
          name="addresses.0.landmark"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Landmark"
              label="Landmark"
            />
          )}
          rules={{ required: { value: true, message: "Landmark is required" } }}
        />
      </div>
      <TonalFixedButton
        loading={loading}
        icon={faSave}
        onClick={handleSubmit(onSubmit)}
        text="Submit"
      />
    </div>
  );
};

export default CreateDoctorForm;
