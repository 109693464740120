import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIcon from "../typography/loading-icon";
import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

type Props = {
  text: any;
  icon: any;
  onClick: Function;
  disabled?: boolean;
  loading?: boolean;
  primary?: boolean;
  className?: string;
};
const TonalFixedButton: React.FC<Props> = ({
  text,
  icon,
  onClick,
  primary = true,
  disabled = false,
  loading = false,
  className = "",
}) => {
  const classes = [
    className,
    "fixed bottom-0 w-full text-center h-10 pr-6 pl-4",
  ];
  if (primary)
    return (
      <button
        className={` bg-primary ${classes.join(" ")}`}
        disabled={loading}
        onClick={() => onClick()}
      >
        <span className="text-sm pl-2 text-white">{text}</span>
        {icon && !loading && (
          <span className="w-4 h-4 absolute right-2">
            <FontAwesomeIcon icon={icon} className="text-white" />
          </span>
        )}
        {loading && (
          <span className="w-4 h-4 absolute right-2">
            <FontAwesomeIcon icon={faSpinner} className="text-white" spin />
          </span>
        )}
      </button>
    );
  else
    return (
      <button
        className={`bg-secondary ${classes.join(" ")}`}
        onClick={() => onClick()}
        disabled={disabled}
      >
        <span className="text-sm pl-2 text-white">{text}</span>
        {icon && !loading && (
          <span className="w-4 h-4 absolute right-2">
            <FontAwesomeIcon icon={icon} className="text-white" />
          </span>
        )}
        {loading && (
          <span className="w-4 h-4 absolute right-2">
            <FontAwesomeIcon icon={faSpinner} className="text-white" spin />
          </span>
        )}
      </button>
    );
};

export default TonalFixedButton;
