import Draggable from "react-draggable";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React, { useState } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import FlipIcon from "@mui/icons-material/Flip";
import { Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

type Props = {
  imageLink: string;
  iconSize?: "small" | "medium" | "large";
  handleClose: Function;
};

const ImageDraggableDialog: React.FC<Props> = ({
  imageLink,
  iconSize = "small",
  handleClose,
}) => {
  const [flip, setFlip] = useState(false);
  const [rotateImage, setRotateImage] = useState(0);

  const rotate = () => {
    if (rotateImage < 360) {
      setRotateImage(rotateImage + 90);
    } else {
      setRotateImage(90);
    }
  };

  return (
    <Draggable
      handle=".draggable-dialog-title"
      positionOffset={{ x: "70%", y: "-150%" }}
    >
      <Paper
        sx={{
          position: "fixed",
          zIndex: 50,
          height: "40rem",
          width: "40rem",
        }}
        elevation={20}
      >
        <div
          className="draggable-dialog-title"
          style={{ backgroundColor: "#FFF7E7", cursor: "move" }}
        >
          <CloseOutlinedIcon
            color="error"
            style={{ cursor: "pointer" }}
            onClick={() => handleClose()}
          />
        </div>
        <div
          style={{
            padding: "20px",
            position: "relative",
            display: "grid",
            justifyContent: "center",
          }}
        >
          <TransformWrapper initialScale={1}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <TransformComponent>
                  <div
                    style={
                      flip
                        ? {
                            transform: "scaleX(-1)",
                          }
                        : {
                            transform: "scaleX(1)",
                          }
                    }
                  >
                    <img
                      style={{
                        transform: `rotate(${rotateImage}deg)`,
                        borderRadius: "20px",
                        width: "30rem",
                        height: "30rem",
                      }}
                      src={imageLink}
                      alt=""
                    ></img>
                  </div>
                </TransformComponent>
                <div>
                  <div
                    style={{
                      display: "flex",
                      border: "1px gray solid",
                      borderRadius: 10,
                      margin: 10,
                      backgroundColor: "white",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Flip">
                      <div style={{ padding: 6, cursor: "pointer" }}>
                        <FlipIcon
                          fontSize={iconSize}
                          color={"info"}
                          onClick={() => {
                            setFlip(!flip);
                          }}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Zoom In">
                      <div style={{ padding: 6, cursor: "pointer" }}>
                        <ZoomInIcon
                          fontSize={iconSize}
                          color={"info"}
                          onClick={() => zoomIn()}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Zoom Out">
                      <div style={{ padding: 6, cursor: "pointer" }}>
                        <ZoomOutIcon
                          fontSize={iconSize}
                          color={"info"}
                          onClick={() => zoomOut()}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Rotate">
                      <div style={{ padding: 6, cursor: "pointer" }}>
                        <RotateRightIcon
                          fontSize={iconSize}
                          color={"info"}
                          onClick={rotate}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Reset">
                      <div style={{ padding: 6, cursor: "pointer" }}>
                        <RestartAltIcon
                          fontSize={iconSize}
                          color={"info"}
                          onClick={() => resetTransform()}
                        />
                      </div>
                    </Tooltip>
                    <div style={{ padding: 6, cursor: "pointer" }}>
                      <a href={imageLink} target="_blank" download={false}>
                        <Tooltip title="Expand">
                          <OpenWithIcon color={"info"} fontSize={iconSize} />
                        </Tooltip>
                      </a>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </TransformWrapper>
        </div>
      </Paper>
    </Draggable>
  );
};

export default ImageDraggableDialog;
