import { Constants } from "../../common/Constants";
import { EmptyResponse, Result } from "../../domain/models/result";
import { HospitalDoctorVaccinator } from "../../domain/usages/add-hospital-doctor-to-vaccinator";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteHospitalDoctorVaccinator
  implements HospitalDoctorVaccinator
{
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async add(
    id: string,
    doctor_id: string,
    params: HospitalDoctorVaccinator.Params
  ): Promise<Result> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id).replace(":doctor_id", doctor_id),
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: Constants.HTTP_ERROR_MESSAGE };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
