import { useParams } from "react-router-dom";
import {
  AUTH_HEADER,
  AUTH_TOKEN,
  HOSPITAL_DOCTOR_SERVICE_API_URL,
  POST_OFFICE_API,
} from "../base";
import { RemoteAddDoctorChemist } from "../data/usecases/remote-add-doctor-chemist";
import { RemoteAddDoctorClinic } from "../data/usecases/remote-add-doctor-clinic";
import { RemoteAddDoctorHospital } from "../data/usecases/remote-add-doctor-hospital";
import { RemoteConfirmFileUpload } from "../data/usecases/remote-confirm-file-upoad";
import { RemoteFetchDoctorMasters } from "../data/usecases/remote-fetch-doctor-masters";
import { RemoteFetchHospitalList } from "../data/usecases/remote-fetch-hospital-list";
import { RemoteFetchHospitalsNearMe } from "../data/usecases/remote-fetch-hospitals-near-me";
import { RemoteFetchPostalAddressForPincode } from "../data/usecases/remote-fetch-postal-address-for-pincode";
import { RemoteFindDoctor } from "../data/usecases/remote-find-doctor";
import { RemoteFindHospital } from "../data/usecases/remote-find-hospital";
import { RemoteGenerateUploadUrl } from "../data/usecases/remote-generate-upload-url";
import { RemoteGetReadLink } from "../data/usecases/remote-get-read-link";
import { RemoteUpdateDoctorChemist } from "../data/usecases/remote-update-doctor-chemist";
import { RemoteUpdateDoctorClinic } from "../data/usecases/remote-update-doctor-clinic";
import { RemoteUpdateDoctorPersonalDetails } from "../data/usecases/remote-update-doctor-personal-details";
import { RemoteUpdateDoctorPracticeDetails } from "../data/usecases/remote-update-doctor-practice-details";
import { RemoteUploadFile } from "../data/usecases/remote-upload-file";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import DoctorProfilePage from "../presentation/pages/doctor-profile-page";

type Props = {};

export const DoctorProfilePageFactory = (props: Props) => {
  const { id } = useParams();
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: `Bearer ${atob(token)}` });

  const remoteUpdatePersonalDetails = new RemoteUpdateDoctorPersonalDetails(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/personal-details`,
    axiosHttpClient
  );

  const remoteUpdatePracticeDetails = new RemoteUpdateDoctorPracticeDetails(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/practice-details`,
    axiosHttpClient
  );

  const remoteAddClinic = new RemoteAddDoctorClinic(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/clinics`,
    axiosHttpClient
  );

  const remoteUpdateClinic = new RemoteUpdateDoctorClinic(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/clinics/:clinic_id`,
    axiosHttpClient
  );

  const remoteAddChemist = new RemoteAddDoctorChemist(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/chemists`,
    axiosHttpClient
  );

  const remoteUpdateChemist = new RemoteUpdateDoctorChemist(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/chemists/:chemist_id`,
    axiosHttpClient
  );

  const remoteFetchDoctorMasters = new RemoteFetchDoctorMasters(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/masters/doctors`,
    axiosHttpClient
  );

  const remoteFetchPostalAddressForPincode =
    new RemoteFetchPostalAddressForPincode(
      `${POST_OFFICE_API}/pincode/:pincode`,
      axiosHttpClient
    );

  const remoteFindDoctor = new RemoteFindDoctor(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id`,
    axiosHttpClient
  );

  const remoteGenerateUploadUrl = new RemoteGenerateUploadUrl(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/uploads/generate-upload-url`,
    axiosHttpClient
  );
  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);
  const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/uploads/confirm-upload`,
    axiosHttpClient
  );

  const remoteGetReadLink = new RemoteGetReadLink(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/uploads/read-url/:id`,
    axiosHttpClient
  );

  const remoteFetchHospitalsNearMe = new RemoteFetchHospitalsNearMe(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/geo-locations/hospitals/near-me`,
    axiosHttpClient
  );

  const remoteFetchHospitalList = new RemoteFetchHospitalList(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals`,
    axiosHttpClient
  );

  const remoteFindHospital = new RemoteFindHospital(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals/:id`,
    axiosHttpClient
  );

  const remoteAddHospital = new RemoteAddDoctorHospital(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/hospital-details`,
    axiosHttpClient
  );

  if (!id) return <div>Please try after sometime</div>;
  return (
    <DoctorProfilePage
      id={id}
      findDoctor={remoteFindDoctor}
      fetchMasters={remoteFetchDoctorMasters}
      fetchPostalAddressForPincode={remoteFetchPostalAddressForPincode}
      updatePersonalDetails={remoteUpdatePersonalDetails}
      updatePracticeDetails={remoteUpdatePracticeDetails}
      addClinic={remoteAddClinic}
      generateUploadUrl={remoteGenerateUploadUrl}
      updateClinic={remoteUpdateClinic}
      addChemist={remoteAddChemist}
      updateChemist={remoteUpdateChemist}
      confirmFileUpload={remoteConfirmFileUpload}
      uploadFile={remoteUploadFile}
      getReadLink={remoteGetReadLink}
      fetchClinicsNearMe={remoteFetchHospitalsNearMe}
      fetchHospitalList={remoteFetchHospitalList}
      findHospital={remoteFindHospital}
      addHospital={remoteAddHospital}
      {...props}
    />
  );
};
