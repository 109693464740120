import {
  faBusinessTime,
  faTablets,
  faUser,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Hospital } from "../../../domain/models/hospital";
import Clickable from "../../ga-components/clickable";
import FilledCard, { FilledColors } from "../common/cards/filled-card";
import ProfileSectionButton, {
  ProfileStatus,
} from "../doctor/profile-section-button";

type HeaderProps = {
  title: string;
  isGoVaxx: boolean;
};
const HospitalHeader: React.FC<HeaderProps> = ({ title, isGoVaxx }) => {
  return (
    <div>
      {isGoVaxx && (
        <div className="text-primary text-xs float-right">GoVaxx</div>
      )}
      {title}
    </div>
  );
};

type Props = {
  hospital: Hospital;
  onClick: Function;
  showIconFields?: boolean;
};
const HospitalCard: React.FC<Props> = ({
  hospital,
  onClick,
  showIconFields,
}) => {
  return (
    <Clickable onClick={onClick}>
      <FilledCard
        filledColor={hospital ? FilledColors.PRIMARY : FilledColors.SECONDARY}
        headline={
          <HospitalHeader
            title={hospital.full_name}
            isGoVaxx={hospital.govaxx_hospital == "Yes" ? true : false}
          />
        }
        subhead={hospital.hospital_type}
        supporting_text={hospital.addresses[0].line}
        actions={
          <div>
            {showIconFields && (
              <>
                <ProfileSectionButton
                  icon={faUser}
                  text={"Physical"}
                  status={ProfileStatus.COMPLETE}
                />
                <span className="p-2"></span>
                <ProfileSectionButton
                  icon={faBusinessTime}
                  text={"Commercial"}
                  status={ProfileStatus.INCOMPLETE}
                />
                <span className="p-2"></span>
                <ProfileSectionButton
                  icon={faTablets}
                  text={"Competition"}
                  status={ProfileStatus.INCOMPLETE}
                />
                <span className="p-2"></span>
                <ProfileSectionButton
                  icon={faUserNurse}
                  text={"Customer"}
                  status={ProfileStatus.INCOMPLETE}
                />
              </>
            )}
          </div>
        }
      />
    </Clickable>
  );
};

export default HospitalCard;
