import { faHospital } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowCircleLeft,
  faCapsules,
  faClinicMedical,
  faUser,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Doctor } from "../../domain/models/doctor";
import { DoctorMaster } from "../../domain/models/doctor-master";
import { Result } from "../../domain/models/result";
import { AddDoctorChemist } from "../../domain/usages/add-doctor-chemist";
import { AddDoctorClinic } from "../../domain/usages/add-doctor-clinic";
import { ConfirmFileUpload } from "../../domain/usages/confirm-file-upload";
import { FetchDoctorMasters } from "../../domain/usages/fetch-doctor-masters";
import { FetchHospitalList } from "../../domain/usages/fetch-hospital-list";
import { FetchHospitalsNearMe } from "../../domain/usages/fetch-hospitals-near-me";
import { FetchPostalAddressForPincode } from "../../domain/usages/fetch-postal-address-for-pincode";
import { FindDoctor } from "../../domain/usages/find-doctor";
import { GenerateUploadUrl } from "../../domain/usages/generate-upload-url";
import { GetReadLink } from "../../domain/usages/get-read-link";
import { UpdateDoctorChemist } from "../../domain/usages/update-doctor-chemist";
import { UpdateDoctorClinic } from "../../domain/usages/update-doctor-clinic";
import { UpdateDoctorPersonalDetails } from "../../domain/usages/update-doctor-personal-details";
import { UpdateDoctorPracticeDetails } from "../../domain/usages/update-doctor-practice-details";
import { UploadFile } from "../../domain/usages/upload-file";
import { TabItem } from "../components/common/nav/tab";
import Tabs from "../components/common/nav/tabs";
import AppBar from "../components/common/sections/app-bar";
import DoctorChemistDetailsForm from "./sections/doctor-chemist-details-form";
import DoctorClinicDetailsForm from "./sections/doctor-clinic-details-form";
import DoctorPersonalDetailsForm from "./sections/doctor-personal-details-form";
import DoctorPracticeDetailsForm from "./sections/doctor-practice-details-form";
import DoctorHospitalDetailsForm from "./sections/doctor-hospital-details-form";
import { FindHospital } from "../../domain/usages/find-hospital";
import { AddDoctorHospital } from "../../domain/usages/add-doctor-hospital";

const ProfileTabs = [
  { id: 1, title: "Personal", icon: faUser } as TabItem,
  { id: 2, title: "Practice", icon: faUserNurse } as TabItem,
  { id: 3, title: "Clinic", icon: faClinicMedical } as TabItem,
  { id: 4, title: "Chemists", icon: faCapsules } as TabItem,
  { id: 5, title: "Hospitals", icon: faHospital } as TabItem,
];

type Props = {
  id: string;
  findDoctor: FindDoctor;
  fetchMasters: FetchDoctorMasters;
  fetchPostalAddressForPincode: FetchPostalAddressForPincode;
  updatePersonalDetails: UpdateDoctorPersonalDetails;
  updatePracticeDetails: UpdateDoctorPracticeDetails;
  addClinic: AddDoctorClinic;
  updateClinic: UpdateDoctorClinic;
  addChemist: AddDoctorChemist;
  updateChemist: UpdateDoctorChemist;
  generateUploadUrl: GenerateUploadUrl;
  confirmFileUpload: ConfirmFileUpload;
  uploadFile: UploadFile;
  getReadLink: GetReadLink;
  fetchClinicsNearMe: FetchHospitalsNearMe;
  fetchHospitalList: FetchHospitalList;
  findHospital: FindHospital;
  addHospital: AddDoctorHospital;
};

const DoctorProfilePage: React.FC<Props> = ({
  id,
  findDoctor,
  fetchMasters,
  fetchPostalAddressForPincode,
  updatePersonalDetails,
  updatePracticeDetails,
  addClinic,
  updateClinic,
  addChemist,
  updateChemist,
  generateUploadUrl,
  confirmFileUpload,
  getReadLink,
  uploadFile,
  fetchClinicsNearMe,
  fetchHospitalList,
  findHospital,
  addHospital,
}) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<number>(1);
  const [doctor, setDoctor] = useState<Doctor>();
  const [master, setMaster] = useState<DoctorMaster>();
  const [loading, setLoading] = useState<boolean>();

  const getDoctor = useCallback(async () => {
    let result: Result = await findDoctor.find(id);
    setDoctor(result.doctor);
    setLoading(false);
  }, [findDoctor]);

  const fetchMasterData = useCallback(async () => {
    let result: Result = await fetchMasters.fetch({});
    setMaster({
      ageGroups: result.ageGroups,
      genders: result.genders,
      qualifications: result.qualifications,
      specialities: result.specialities,
    } as DoctorMaster);
    getDoctor();
  }, [fetchMasters]);

  useEffect(() => {
    setLoading(true);
    fetchMasterData();
  }, []);

  const onSuccessCallback = (tab: number) => {
    setTab(tab);
    getDoctor();
  };

  if (!doctor || !master) return <div>Fetching Doctor details</div>;
  return (
    <section className="h-full">
      <div className="md:container md:mx-auto md:px-5 h-full sm:w-screen">
        <AppBar
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              className="text-primary"
              onClick={() => navigate(-1)}
            />
          }
          title={
            <span className="text-primary font-bold drop-shadow-2xl">
              {doctor && doctor.full_name}
            </span>
          }
        />
        <Tabs
          selectedItemId={tab}
          setSelectedTabId={setTab}
          items={ProfileTabs}
        />
        <div className="h-5/6 overflow-scroll pb-10">
          {tab === 1 && (
            <DoctorPersonalDetailsForm
              id={doctor._id}
              doctor={doctor}
              master={master}
              fetchPostalAddressForPincode={fetchPostalAddressForPincode}
              updatePersonalDetails={updatePersonalDetails}
              successCallback={() => onSuccessCallback(2)}
            />
          )}
          {tab === 2 && (
            <DoctorPracticeDetailsForm
              id={doctor._id}
              doctor={doctor}
              specialities={master.specialities}
              qualifications={master.qualifications}
              updatePracticeDetails={updatePracticeDetails}
              successCallback={() => onSuccessCallback(3)}
            />
          )}
          {tab === 3 && (
            <DoctorClinicDetailsForm
              id={doctor._id}
              doctor={doctor}
              uploadFile={uploadFile}
              confirmFileUpload={confirmFileUpload}
              generateUploadUrl={generateUploadUrl}
              addClinic={addClinic}
              updateClinic={updateClinic}
              getReadLink={getReadLink}
              fetchClinicsNearMe={fetchClinicsNearMe}
              successCallback={() => onSuccessCallback(4)}
            />
          )}
          {tab === 4 && (
            <DoctorChemistDetailsForm
              id={doctor._id}
              doctor={doctor}
              uploadFile={uploadFile}
              confirmFileUpload={confirmFileUpload}
              generateUploadUrl={generateUploadUrl}
              addChemist={addChemist}
              updateChemist={updateChemist}
              getReadLink={getReadLink}
              successCallback={() => onSuccessCallback(4)}
            />
          )}
          {tab === 5 && (
            <DoctorHospitalDetailsForm
              id={doctor._id}
              doctor={doctor}
              addHospital={addHospital}
              fetchHospitalsNearMe={fetchClinicsNearMe}
              fetchHospitalList={fetchHospitalList}
              findHospital={findHospital}
              successCallback={() => onSuccessCallback(5)}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default DoctorProfilePage;
