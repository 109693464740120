import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import Chip from "@mui/material/Chip";
import { Skeleton } from "@mui/material";

type Props = {};

const ListSkeleton = (props: Props) => {
  return (
    <div style={{ borderRadius: "4px", border: "1px solid gray", padding: 20 }}>
      <Skeleton
        variant="rounded"
        animation="wave"
        height={20}
        width="70%"
        sx={{ marginBottom: 1 }}
      />
      <Skeleton
        variant="rounded"
        animation="wave"
        height={20}
        width="35%"
        sx={{ marginBottom: 3 }}
      />
      <Skeleton
        variant="rounded"
        animation="wave"
        height={20}
        width="50%"
        sx={{ marginBottom: 3 }}
      />
      <Skeleton
        variant="rounded"
        animation="wave"
        height={20}
        width="20%"
        sx={{ marginBottom: 3 }}
      />
    </div>
  );
};

export default ListSkeleton;
