import { StateCreator } from "zustand";

interface homeStoreInterface {
  handleExpandSideBar: Function;
  expandSideBar: boolean;
}

const initialState = {
  expandSideBar: false,
};

export const useHomeStore: StateCreator<homeStoreInterface> = (set) => {
  return {
    ...initialState,
    handleExpandSideBar: (value: boolean) => [
      set(() => ({
        expandSideBar: value,
      })),
    ],
  };
};
