import {
  faArrowCircleLeft,
  faBusinessTime,
  faTablets,
  faUser,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Hospital } from "../../domain/models/hospital";
import { HospitalMaster } from "../../domain/models/hospital-master";
import { Result } from "../../domain/models/result";
import { AddHospitalDoctor } from "../../domain/usages/add-hospital-doctor";
import { AddHospitalDoctorToGoVaxx } from "../../domain/usages/add-hospital-doctor-to-govaxx";
import { HospitalDoctorVaccinator } from "../../domain/usages/add-hospital-doctor-to-vaccinator";
import { FetchHospitalMasters } from "../../domain/usages/fetch-hospital-masters";
import { FindHospital } from "../../domain/usages/find-hospital";
import { RemoveHospitalDoctorFromGoVaxx } from "../../domain/usages/remove-hospital-doctor-from-govaxx";
import { UpdateHospitalCommercialDetails } from "../../domain/usages/update-hospital-commercial-details";
import { UpdateHospitalCompetitionDetails } from "../../domain/usages/update-hospital-competition-details";
import { UpdateHospitalDoctor } from "../../domain/usages/update-hospital-doctor";
import { UpdateHospitalPhysicalDetails } from "../../domain/usages/update-hospital-physical-details";
import { TabItem } from "../components/common/nav/tab";
import Tabs from "../components/common/nav/tabs";
import AppBar from "../components/common/sections/app-bar";
import HospitalCommercialDetailsForm from "./sections/hospital-commercial-details-form";
import HospitalCompetitionDetailsForm from "./sections/hospital-competition-details-form";
import HospitalCustomerDetailsForm from "./sections/hospital-customer-details-form";
import HospitalPhysicalDetailsForm from "./sections/hospital-physical-details-form";

const ProfileTabs = [
  { id: 1, title: "Physical", icon: faUser } as TabItem,
  { id: 2, title: "Commercial", icon: faBusinessTime } as TabItem,
  { id: 3, title: "Competitor", icon: faTablets } as TabItem,
  { id: 4, title: "Doctors", icon: faUserNurse } as TabItem,
];

type Props = {
  id: string;
  updatePhysicalDetails: UpdateHospitalPhysicalDetails;
  updateCommercialDetails: UpdateHospitalCommercialDetails;
  updateCompetitionDetails: UpdateHospitalCompetitionDetails;
  addDoctor: AddHospitalDoctor;
  updateDoctor: UpdateHospitalDoctor;
  findHospital: FindHospital;
  fetchMasters: FetchHospitalMasters;
  addHospitalDoctorToGoVaxx: AddHospitalDoctorToGoVaxx;
  removeHospitalDoctorFromGoVaxx: RemoveHospitalDoctorFromGoVaxx;
  HospitalDoctorVaccinator: HospitalDoctorVaccinator;
};

const HospitalProfilePage: React.FC<Props> = ({
  id,
  updatePhysicalDetails,
  updateCommercialDetails,
  updateCompetitionDetails,
  addDoctor,
  updateDoctor,
  findHospital,
  fetchMasters,
  addHospitalDoctorToGoVaxx,
  removeHospitalDoctorFromGoVaxx,
  HospitalDoctorVaccinator,
}) => {
  const navigate = useNavigate();
  const [hospital, setHospital] = useState<Hospital>();
  const [masters, setMasters] = useState<HospitalMaster>();
  const [tab, setTab] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const getHospital = useCallback(async () => {
    let result: Result = await findHospital.find(id);
    setHospital(result.hospital);
    setLoading(false);
  }, [findHospital]);

  const fetchMasterData = useCallback(async () => {
    let result: Result = await fetchMasters.fetch({});
    setMasters({
      patientFocuses: result.patientFocuses,
      commercialTypes: result.commercialTypes,
      competitorVaccines: result.competitorVaccines,
      specialities: result.specialities,
    } as HospitalMaster);
    getHospital();
  }, [fetchMasters]);

  useEffect(() => {
    setLoading(true);
    fetchMasterData();
  }, []);

  const onSuccessCallback = (tab: number) => {
    setTab(tab);
    getHospital();
  };

  if (!hospital || !masters) return <div>Fetching Hospital details</div>;
  return (
    <section className="h-full">
      <div className="md:container md:mx-auto md:px-5 h-full sm:w-screen">
        <AppBar
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              className="text-primary"
              onClick={() => navigate(-1)}
            />
          }
          title={
            <span className="text-primary font-bold drop-shadow-2xl">
              {hospital.full_name}
            </span>
          }
        />
        <Tabs
          selectedItemId={tab}
          setSelectedTabId={setTab}
          items={ProfileTabs}
        />
        {tab === 1 && (
          <HospitalPhysicalDetailsForm
            hospital={hospital}
            id={id}
            updatePhysicalDetails={updatePhysicalDetails}
            successCallback={() => onSuccessCallback(2)}
          />
        )}
        {tab === 2 && (
          <HospitalCommercialDetailsForm
            hospital={hospital}
            id={id}
            patientFocuses={masters.patientFocuses}
            commercialTypes={masters.commercialTypes}
            updateCommercialDetails={updateCommercialDetails}
            successCallback={() => onSuccessCallback(3)}
          />
        )}
        {tab === 3 && (
          <HospitalCompetitionDetailsForm
            hospital={hospital}
            id={id}
            competitorVaccines={masters.competitorVaccines}
            updateCompetitionDetails={updateCompetitionDetails}
            successCallback={() => onSuccessCallback(4)}
          />
        )}
        {tab === 4 && (
          <HospitalCustomerDetailsForm
            hospital={hospital}
            specialities={masters.specialities}
            id={id}
            addDoctor={addDoctor}
            addHospitalDoctorToGoVaxx={addHospitalDoctorToGoVaxx}
            removeHospitalDoctorFromGoVaxx={removeHospitalDoctorFromGoVaxx}
            HospitalDoctorVaccinator={HospitalDoctorVaccinator}
            updateDoctor={updateDoctor}
            successCallback={() => onSuccessCallback(4)}
          />
        )}
      </div>
    </section>
  );
};

export default HospitalProfilePage;
