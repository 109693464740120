import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import { NavItem } from ".";
import { LoggedInUser } from "../../../domain/usages/logged-in-user";
import { AppUserContext, LoginAction } from "../../../UserContext";
import withLoggedInUser from "../../../withLoggedInUser";
import Clickable from "../clickable";
import { Icon, IconSize } from "../icon";
import withTheme from "../theme/withTheme";

type NavProps = {
  theme: any;
  loggedInUser: LoggedInUser;
  className?: string;
};
const Account: React.FC<NavProps> = ({
  theme,
  loggedInUser,
  className = "",
}) => {
  const { state, dispatch } = useContext(AppUserContext);

  const [cssClass] = useState<string>([className, theme.nav.account].join(" "));

  const logout = () => {
    Swal.fire({
      title: "Do you want to logout from iTrack?",
      html: "You will be redirected to the login page.",
      showCancelButton: true,
      confirmButtonText: "LOGOUT",
      cancelButtonText: "NO! CANCEL",
    }).then((result) => {
      if (result.isConfirmed) {
        loggedInUser.logout();
        dispatch({
          type: LoginAction.LOGOUT,
          payload: null,
        });
      } else if (result.isDenied) {
      }
    });
  };

  if (!state || !state.token) return <div></div>;
  return (
    <ul className={cssClass}>
      <NavItem
        item={{
          text: `Welcome (${state.user?.full_name})`,
          link: "",
          active: true,
        }}
        className="font-sans text-sm font-semibold tracking-wider"
      />
      <Clickable onClick={logout}>
        <NavItem
          className="hover:bg-gray-200"
          item={{
            icon: <Icon icon="logout" size={IconSize.sm} />,
            link: "",
          }}
        />
      </Clickable>
    </ul>
  );
};

export default withLoggedInUser(withTheme(Account));
