import { EmptyResponse, Result } from "../../domain/models/result";
import { UpdateDoctorClinic } from "../../domain/usages/update-doctor-clinic";
import { HttpPostClient } from "../protocols/http/http-post-client";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteUpdateDoctorClinic implements UpdateDoctorClinic {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async update(
    id: string,
    clinic_id: string,
    params: UpdateDoctorClinic.Params
  ): Promise<Result> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id).replace(":clinic_id", clinic_id),
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: "Server error. Please try after sometime." };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
