import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Clinic } from "../../domain/models/clinic";
import { Doctor } from "../../domain/models/doctor";
import { DoctorMaster } from "../../domain/models/doctor-master";
import { Result } from "../../domain/models/result";
import { ConfirmFileUpload } from "../../domain/usages/confirm-file-upload";
import { CreateDoctor } from "../../domain/usages/create-doctor";
import { FetchDoctorMasters } from "../../domain/usages/fetch-doctor-masters";
import { FetchHospitalsNearMe } from "../../domain/usages/fetch-hospitals-near-me";
import { FetchPostalAddressForPincode } from "../../domain/usages/fetch-postal-address-for-pincode";
import { GenerateUploadUrl } from "../../domain/usages/generate-upload-url";
import { UploadFile } from "../../domain/usages/upload-file";
import { ValidateDoctor } from "../../domain/usages/validate-doctor";
import { pageRoutes } from "../../routes";
import AppBar from "../components/common/sections/app-bar";
import CreateDoctorForm from "../components/doctor/create-doctor-form";
import DoctorClinicForm from "../components/doctor/doctor-clinic-form";
import NearByClinic from "../components/doctor/near-by-clinic";

type Props = {
  createDoctor: CreateDoctor;
  validateDoctor: ValidateDoctor;
  fetchMasters: FetchDoctorMasters;
  fetchPostalAddressForPincode: FetchPostalAddressForPincode;
  generateUploadUrl: GenerateUploadUrl;
  confirmFileUpload: ConfirmFileUpload;
  uploadFile: UploadFile;
  fetchClinicsNearMe: FetchHospitalsNearMe;
};
const CreateDoctorPage: React.FC<Props> = ({
  createDoctor,
  validateDoctor,
  fetchMasters,
  fetchPostalAddressForPincode,
  generateUploadUrl,
  confirmFileUpload,
  uploadFile,
  fetchClinicsNearMe,
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState<"personal" | "clinic">("personal");
  const [loading, setLoading] = useState<boolean>(false);
  const [master, setMaster] = useState<DoctorMaster>();
  const [doctor, setDoctor] = useState<Doctor>({
    qualifications: [],
  } as Doctor);
  const [clinic, setClinic] = useState<Clinic>({} as Clinic);
  const [showNearByClinic, setShowNearByClinic] = useState(false);

  const fetchMasterData = useCallback(async () => {
    setLoading(true);
    let result: Result = await fetchMasters.fetch({});
    setMaster({
      ageGroups: result.ageGroups,
      qualifications: result.qualifications,
      specialities: result.specialities,
      genders: result.genders,
    } as DoctorMaster);
    setLoading(false);
  }, [fetchMasters]);

  useEffect(() => {
    fetchMasterData();
  }, []);

  const validateDoctorDetails = useCallback(
    async (doctorModel: Doctor) => {
      setLoading(true);
      setDoctor(doctorModel);
      const result: Result = await validateDoctor.validate(doctorModel);
      if (result.success) {
        setStep("clinic");
        setShowNearByClinic(true);
      }
      setLoading(false);
    },
    [validateDoctor, doctor]
  );

  useEffect(() => {
    if (clinic.mobile) createDoctorDetails();
  }, [clinic]);

  const createDoctorDetails = useCallback(async () => {
    setLoading(true);
    const doctorDetails = doctor;
    doctorDetails.clinics = [clinic];
    const result: Result = await createDoctor.create(doctorDetails);
    if (!result.errors) {
      Swal.fire("Doctor created successfully.");
      navigate("/home/" + pageRoutes.doctors);
    } else {
      setLoading(false);
    }
  }, [validateDoctor, doctor, clinic]);

  if (loading) {
    return <div>Preparing masters</div>;
  }
  return (
    <section className={`h-full ${!doctor ? "bg-primary_low" : "bg-white"} `}>
      <div className="md:container md:mx-auto md:px-5 h-full sm:w-screen">
        <AppBar
          icon={
            step == "personal" && (
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                className="text-primary"
                onClick={() => navigate(-1)}
              />
            )
          }
          title={
            <span className="text-primary font-bold drop-shadow-2xl">
              {step == "personal" && "Let's profile new Doctor"}
              {step == "clinic" &&
                `Let's add clinic details for Dr. ${doctor.full_name}`}
            </span>
          }
        />

        <div className="h-5/6 overflow-auto mb-3">
          {doctor && master && step == "personal" && (
            <CreateDoctorForm
              doctor={doctor}
              master={master}
              errors={{}}
              fetchPostalAddressForPincode={fetchPostalAddressForPincode}
              successCallback={(doctor) => validateDoctorDetails(doctor)}
            />
          )}

          {showNearByClinic && doctor && master && (
            <NearByClinic
              fetchClinicNearMe={fetchClinicsNearMe}
              onClinicSelection={setClinic}
              setShowNearByClinic={setShowNearByClinic}
            />
          )}

          {!showNearByClinic && doctor && master && step == "clinic" && (
            <DoctorClinicForm
              clinic={clinic}
              generateUploadUrl={generateUploadUrl}
              uploadFile={uploadFile}
              confirmFileUpload={confirmFileUpload}
              successCallback={setClinic}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default CreateDoctorPage;
