import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import React from "react";
import withTheme from "../../ga-components/theme/withTheme";
import Icon, { IconSize, IconTypes } from "../common/typography/icon";
import InputGroup from "./input-group";
import { InputVariant } from "./models";

export type Props = {
  theme: any;
  value: boolean;
  onChange: Function;
  variant?: InputVariant;
  error?: string;
  label?: string;
  placeholder?: string;
  className?: string;
};
const OptionInput: React.FC<Props> = ({
  theme,
  value,
  onChange,
  label,
  error = "",
  variant = InputVariant.FILLED,
  placeholder = "",
  className = "",
}) => {
  const classes = [className];
  if (error) classes.push(theme.input[variant].errorField);
  else classes.push(theme.input[variant].field);

  const labelClasses = [className];
  labelClasses.push(theme.input[variant].label);

  return (
    <InputGroup error={error} variant={variant}>
      <div
        className={`mb-4 flex align-middle items-center ${classes.join(" ")}`}
        onClick={(e) => onChange(!value)}
      >
        <Icon
          icon={value ? faCheckSquare : faSquare}
          type={IconTypes.PRIMARY}
          size={IconSize.xl}
          className="mr-4"
        />
        {label && <label className={labelClasses.join(" ")}>{label}</label>}
      </div>
    </InputGroup>
  );
};

export default withTheme(OptionInput);
