import React from "react";
import { Controller, useForm } from "react-hook-form";
import { DoctorFilters } from "../../../../domain/models/doctor";
import { Qualification } from "../../../../domain/models/qualification";
import { Speciality } from "../../../../domain/models/speciality";
import {
  InputVariant,
  MultiSelectField,
  Option,
  SelectField,
  TextField,
} from "../../../components/inputs";
import {
  Button,
  ButtonColor,
  ButtonType,
} from "../../../ga-components/buttons";
import {
  Card,
  CardBody,
  CardColor,
  CardSupportingText,
  CardTags,
} from "../../../ga-components/cards";

type Props = {
  onSubmit: (fields: DoctorFilters) => void;
  qualifications: Qualification[];
  specialities: Speciality[];
};

const DoctorsFilterForm: React.FC<Props> = ({
  onSubmit,
  qualifications,
  specialities,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, isDirty, errors: formErrors },
  } = useForm<DoctorFilters>({
    mode: "onChange",
    defaultValues: {
      speciality: "",
      qualifications: [],
    },
  });

  const clear = () => {
    reset({});
    onSubmit({} as DoctorFilters);
  };

  return (
    <Card color={CardColor.SECONDARY_LOW}>
      <CardBody className="pt-0">
        <div className="mb-4"></div>
        <CardSupportingText>Select any of the filter below</CardSupportingText>
        <Controller
          name="query"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              placeholder="Enter your search query here."
              error={error && error.message}
              label="Search query"
            />
          )}
        />
        <div className="mb-4"></div>
        <Controller
          name="speciality"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectField
              className="p-0"
              value={{ value: value, key: value }}
              placeholder="Select Speciality"
              label="Select Speciality"
              onChange={(value: any) => onChange(value.value)}
              error={error && error.message}
              options={specialities.map(
                (s) => ({ key: s.name, value: s.name } as Option)
              )}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
        <div className="mb-4"></div>
        <Controller
          name="qualifications"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <MultiSelectField
              value={value
                .map((v: any) => qualifications.find((cv: any) => cv.name == v))
                .map((v: any) => ({ key: v.name, value: v.name } as Option))}
              placeholder="Select Qualifications"
              label="Select Qualifications"
              onChange={(value: any) =>
                onChange(value.map((v: any) => v.value))
              }
              error={error && error.message}
              options={qualifications.map(
                (q) => ({ key: q.name, value: q.name } as Option)
              )}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
      </CardBody>
      {isValid && isDirty && (
        <CardTags>
          <div className="flex flex-row-reverse">
            <Button
              text="Search"
              color={ButtonColor.PRIMARY}
              onClick={handleSubmit(onSubmit)}
            />
            <Button
              className="mr-4"
              text="Clear"
              color={ButtonColor.WHITE}
              type={ButtonType.OUTLINED}
              onClick={handleSubmit(clear)}
            />
          </div>
        </CardTags>
      )}
    </Card>
  );
};

export default DoctorsFilterForm;
