import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import Carousel from "react-material-ui-carousel";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ImageDisplay from "./imageDisplay";
import { GetImageRejectionReasons } from "../../../../domain/usages/data-entry/doctor-verification/get-image-rejection-reasons";
import { RejectImage } from "../../../../domain/usages/data-entry/doctor-verification/reject-image";
import { GetReadLink } from "../../../../domain/usages/get-read-link";
import RejectionReasonsList from "./RejectionReasonsList";
import { DoctorData } from "../../../../domain/models/doctor/doctorData";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import Status from "../../../../domain/enums/status";

type Props = {
  getImageRejectionReasons: GetImageRejectionReasons;
  rejectImage: RejectImage;
  getReadLink: GetReadLink;
  doctorDetails: DoctorData;
  reloadDetails: Function;
  handleImageDraggable: Function;
};

const DoctorImage: React.FC<Props> = ({
  getImageRejectionReasons,
  rejectImage,
  getReadLink,
  doctorDetails,
  reloadDetails,
  handleImageDraggable,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [imageID, setImageID] = useState("");

  const handleRejectImage = (index: number) => {
    setImageID(doctorDetails.images[index]._id);
    setOpenDialog(true);
  };
  const loadingIndicator = (loading: boolean) => {
    if (loading) {
      Swal.fire({
        title: "Response Loading...",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  const handleRejectImageWithReasons = async (
    reason: string,
    otherReason: string
  ) => {
    loadingIndicator(true);
    const payload = {
      reasonCode: reason,
      rejectionReason: otherReason,
    };

    Object.keys(payload).forEach((key) => {
      if ((payload as any)[key].length === 0) {
        delete (payload as any)[key];
      }
    });
    setOpenDialog(false);
    let result = await rejectImage.reject(doctorDetails._id, imageID, payload);
    if (result.success) {
      loadingIndicator(false);
      Swal.fire(result.data.message, "", "success");
      reloadDetails();
    } else {
      loadingIndicator(false);
      Swal.fire(result.message, "", "error");
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
      }}
    >
      <div>
        <Carousel
          sx={{
            width: "500px",
          }}
          animation="slide"
          swipe
          indicators
          navButtonsAlwaysVisible
          autoPlay={false}
          NextIcon={<ArrowCircleRightIcon />}
          PrevIcon={<ArrowCircleLeftIcon />}
          height="450px"
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "orange",
            },
          }}
          fullHeightHover
        >
          {doctorDetails.images?.map((image, index) => (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",

                  marginBottom: 15,
                }}
              >
                <Typography sx={{ fontSize: 16 }}>{image.type}</Typography>
                {image.verification?.status == Status.REJECTED ? (
                  <Typography sx={{ fontSize: 14 }} color="error">
                    <strong>IMAGE REJECTED</strong>
                  </Typography>
                ) : (
                  <Button
                    sx={{ fontSize: 14, fontWeight: 500 }}
                    variant="text"
                    onClick={() => handleRejectImage(index)}
                    color="error"
                  >
                    REJECT IMAGE
                  </Button>
                )}
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                }}
              >
                <ImageDisplay
                  getReadLink={getReadLink}
                  uuid={image.uuid}
                  id={doctorDetails._id}
                  imageHeight="350px"
                  imageWidth="100%"
                  handleImageDraggable={handleImageDraggable}
                />
              </div>
            </>
          ))}
        </Carousel>
      </div>
      <RejectionReasonsList
        openDialog={openDialog}
        handleVerify={handleRejectImageWithReasons}
        id={imageID}
        getRejectionReasons={getImageRejectionReasons}
        handleClose={handleCloseDialogBox}
      />
    </div>
  );
};

export default DoctorImage;
