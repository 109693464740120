import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Address } from "../../../domain/models/address";
import { Hospital } from "../../../domain/models/hospital";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import TonalFixedButton from "../common/buttons/tonal-fixed-button";
import Header, { HeaderSize, HeaderType } from "../common/typography/header";
import { NumberField, TextField } from "../inputs/index";

type HospitalDetails = {
  full_name: string;
  addresses: Address[];
  lat_long: string;
  _id: string;
};

const convertHospitalToDetails = (hospital: Hospital) => {
  const hospitalDetils: HospitalDetails = {} as HospitalDetails;
  hospitalDetils.full_name = hospital.full_name;
  hospitalDetils.addresses = [];
  hospitalDetils.lat_long = "";
  hospitalDetils._id = "";
  return hospitalDetils;
};

type Props = {
  hospital: Hospital;
  successCallback: Function;
};
const DoctorHospitalForm: React.FC<Props> = ({ hospital, successCallback }) => {
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<HospitalDetails>({
    mode: "onChange",
    defaultValues: convertHospitalToDetails(hospital),
  });

  const storage = LocalJsonStorage.getInstance();
  const latLongLocation = storage.get("lat-long");

  useEffect(() => {
    setValue("full_name", hospital.full_name);
    if (hospital.addresses && hospital.addresses.length > 0) {
      setValue("addresses", hospital.addresses);
      if (hospital._id && hospital._id.length > 0) {
        setValue("_id", hospital._id);
      }
    }
    setValue("lat_long", latLongLocation);
  }, [hospital]);

  const onSubmit = (data: HospitalDetails) => {
    successCallback(data);
  };

  return (
    <>
      <Header
        text={"Please fill all the details below to create a Hospital."}
        type={HeaderType.PRIMARY}
        size={HeaderSize.lg}
        className="font-thin"
      />
      <div className="p-4">
        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              placeholder="Hospital Name"
              error={error && error.message}
              label="Hospital Name"
            />
          )}
          rules={{ required: { value: true, message: "Name is required" } }}
        />

        <Controller
          name="addresses.0.pincode"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              onChange={onChange}
              error={error && error.message}
              placeholder="Pincode"
              label="Pincode"
            />
          )}
          rules={{
            pattern: {
              value: /^[1-9]{1}[0-9]{5}$/,
              message: "Invalid pincode",
            },
            required: { value: true, message: "Pincode is required" },
          }}
        />

        <Controller
          name="addresses.0.line"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={error && error.message}
              placeholder="Building, Colony"
              label="Building, Colony"
            />
          )}
          rules={{
            required: { value: true, message: "Address line is required" },
          }}
        />

        <Controller
          name="addresses.0.landmark"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={error && error.message}
              placeholder="Landmark"
              label="Landmark"
            />
          )}
          rules={{ required: { value: true, message: "Landmark is required" } }}
        />
      </div>

      <TonalFixedButton
        loading={false}
        icon={faSave}
        onClick={handleSubmit(onSubmit)}
        text="Save Details"
      />
    </>
  );
};

export default DoctorHospitalForm;
