import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Address } from "../../../domain/models/address";
import { Hospital } from "../../../domain/models/hospital";
import { Result } from "../../../domain/models/result";
import { UpdateHospitalPhysicalDetails } from "../../../domain/usages/update-hospital-physical-details";
import { pageRoutes } from "../../../routes";
import TonalFixedButton from "../../components/common/buttons/tonal-fixed-button";
import {
  InputVariant,
  NumberField,
  TextField,
} from "../../components/inputs/index";

type HospitalPhysicalDetails = {
  full_name: string;
  addresses: Address[];
  hospital_email: string;
};

type Props = {
  id: string;
  hospital: Hospital;
  updatePhysicalDetails: UpdateHospitalPhysicalDetails;
  successCallback: Function;
};
const HospitalPhysicalDetailsForm: React.FC<Props> = ({
  id,
  hospital,
  updatePhysicalDetails,
  successCallback,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<HospitalPhysicalDetails>({
    defaultValues: {
      full_name: hospital.full_name,
      addresses: hospital.addresses,
      hospital_email: hospital.hospital_email,
    },
  });

  const onSubmit = useCallback(
    async (data: HospitalPhysicalDetails) => {
      setLoading(true);
      let result: Result = await updatePhysicalDetails.update(id, {
        full_name: data.full_name,
        addresses: data.addresses,
        hospital_email: data.hospital_email,
      });
      if (!result.errors) {
        Swal.fire("Hospital details updated successfully.", "", "success");
        successCallback();
      } else {
        setLoading(false);
      }
    },
    [updatePhysicalDetails]
  );
  return (
    <>
      <div className="p-4">
        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              placeholder="Hospital Name"
              error={error && error.message}
              label="Hospital Name"
            />
          )}
          rules={{ required: { value: true, message: "Name is required" } }}
        />

        <Controller
          name="hospital_email"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Email id"
              label="Email id"
            />
          )}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email",
            },
            required: { value: true, message: "Pincode is required" },
          }}
        />

        <Controller
          name="addresses.0.pincode"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Pincode"
              label="Pincode"
            />
          )}
          rules={{
            pattern: {
              value: /^[1-9]{1}[0-9]{5}$/,
              message: "Invalid pincode",
            },
            required: { value: true, message: "Pincode is required" },
          }}
        />

        <Controller
          name="addresses.0.line"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Building, Colony"
              label="Building, Colony"
            />
          )}
          rules={{
            required: { value: true, message: "Address line is required" },
          }}
        />

        <Controller
          name="addresses.0.landmark"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Landmark"
              label="Landmark"
            />
          )}
          rules={{ required: { value: true, message: "Landmark is required" } }}
        />
      </div>
      <TonalFixedButton
        icon={faSave}
        onClick={handleSubmit(onSubmit)}
        text="Click here to save details"
      />
    </>
  );
};

export default HospitalPhysicalDetailsForm;
