import React from "react";
import withTheme from "../../../ga-components/theme/withTheme";
import NavItem, { NavMenuItem, NavItemType } from "./nav-item";

type Props = {
  theme: any;
  items: NavMenuItem[];
  setSelectedTabId: Function;
  selectedItemId: number;
};

const Navigation: React.FC<Props> = ({
  theme,
  items,
  setSelectedTabId,
  selectedItemId,
}) => {
  return (
    <div className="navigation w-full h-70 bg-primary h-16 flex fixed bottom-0 left-0 right-0 justify-center">
      <ul className="relative w-360 flex">
        {items.map((item) => (
          <NavItem
            key={item.id}
            item={item}
            type={
              selectedItemId === item.id
                ? NavItemType.ACTIVE
                : NavItemType.IN_ACTIVE
            }
            onClick={setSelectedTabId}
          />
        ))}
        <div className="indicator absolute -top-1/2 left-2 w-70 h-70 top-50 rounded-full border-4 border-primary bg-secondary duration-100"></div>
      </ul>
    </div>
  );
};

export default withTheme(Navigation);
