import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import { Grid } from "@mui/material";
import { Constants } from "../../../../common/Constants";
import { SaveDoctorBasicDetails } from "../../../../domain/usages/data-entry/doctor-verification/save-doctor-basic-details";
import { VerifyDoctorContacts } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-contact";
import { DoctorData } from "../../../../domain/models/doctor/doctorData";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { VerifyDoctorAddress } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-address";
import { Qualification } from "../../../../domain/models/qualification";
import { Speciality } from "../../../../domain/models/speciality";
import MobileNumber from "./FormFields/MobileNumber";
import Email from "./FormFields/Email";
import HospitalNameField from "./FormFields/HospitalNameField";
import SpecialityField from "./FormFields/SpecialityField";
import QualificationField from "./FormFields/QualificationField";
import SocialMediaLink from "./FormFields/SocialMediaLink";
import AddressField from "./FormFields/AddressField";
import NameField from "./FormFields/NameField";
import { VerifyDoctorName } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-name";
import { VerifyDoctorQualification } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-qualification";
import { VerifyDoctorSpeciality } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-speciality";
import ConfirmApprove from "./ConfirmApprove";
import ConfirmSubmit from "./ConfirmSubmit";
import { GetAddressByPincode } from "../../../../domain/usages/get-address-by-pincode";
import { AddressBasedOnPincode } from "../../../../domain/models/address-based-on-pincode";

type Props = {
  saveDoctorDetails: SaveDoctorBasicDetails;
  verifyDoctorDetails: VerifyDoctorContacts;
  doctorDetails: DoctorData;
  reloadDetails: Function;
  reloadList: Function;
  verifyAddress: VerifyDoctorAddress;
  specialities: Speciality[];
  qualifications: Qualification[];
  verifyName: VerifyDoctorName;
  verifyQualification: VerifyDoctorQualification;
  verifySpeciality: VerifyDoctorSpeciality;
  getAddress: GetAddressByPincode;
};

type Contacts = {
  type: string;
  value: string;
  status: string;
};

type NameType = {
  name: string;
};

type HospitalType = {
  name: string;
};

type SpecialityType = {
  value: string;
};

type AddressType = {
  type: string;
  source: string;
  latLong: string;
  line: string;
  landmark: string;
  pincode: number;
  area: string;
  district: string;
  state: string[];
  country: string[];
  [prop: string]: number | string | string[];
};

const DoctorBasicDetailsForm: React.FC<Props> = ({
  saveDoctorDetails,
  verifyDoctorDetails,
  doctorDetails,
  reloadDetails,
  reloadList,
  verifyAddress,
  qualifications,
  specialities,
  verifyName,
  verifyQualification,
  verifySpeciality,
  getAddress,
}) => {
  const [existedMobileNumberDetails, setExistedMobileNumberDetails] = useState<
    Contacts[]
  >([]);
  const [existedEmailDetails, setExistedEmailDetails] = useState<Contacts[]>(
    []
  );
  const [mobileNumberDetails, setMobileNumberDetails] = useState<Contacts[]>(
    []
  );
  const [specialityDetails, setSpecialityDetails] = useState<SpecialityType[]>(
    []
  );
  const [socialMediaType, setSocialMediaType] = useState<string[]>([]);
  const [emailDetails, setEmailDetails] = useState<Contacts[]>([]);
  const [socialMediaDetails, setSocialMediaDetails] = useState<Contacts[]>([]);
  const [nameDetails, setNameDetails] = useState<NameType[]>([]);
  const [hospitalNameDetails, setHospitalNameDetails] = useState<
    HospitalType[]
  >([]);
  const [qualificationDetails, setQualificationDetails] = useState<
    SpecialityType[]
  >([]);
  const [addressDetails, setAddressDetails] = useState<AddressType[]>([]);
  const [postalAddress, setPostalAddress] = useState<AddressBasedOnPincode[]>(
    []
  );
  const [hospitalName, setHospitalName] = useState<string>("");
  const [formErrors, setFormErrors] = useState({
    name: [""],
    mobile: [""],
    email: [""],
    hospital: [""],
    socialMediaLink: [""],
    qualification: [""],
    speciality: [""],
    address: {
      line: [""],
      landmark: [""],
      pincode: [""],
      area: [""],
      district: [""],
      state: [""],
      country: [""],
    },
  });
  const [isVerified, setIsVerified] = useState({
    name: [false],
    mobile: [false],
    email: [false],
    speciality: [false],
    qualification: [false],
    addresses: [false],
    socials: [false],
    hospitalName: [false],
  });
  const [details, setDetails] = useState({
    name: [""],
    mobileContactID: [""],
    emailContactID: [""],
    addressID: [""],
    speciality: [""],
    qualification: [""],
    socialMediaLinks: [""],
    socials: [""],
    hospitalName: [""],
  });
  const [arrayIndex, setArrayIndex] = useState({
    id: 0,
    type: "",
  });
  const [showContactDialog, setShowContactDialog] = useState<boolean>(false);
  const [showAddressDialog, setShowAddressDialog] = useState<boolean>(false);
  const [showNameDialog, setShowNameDialog] = useState<boolean>(false);
  const [showSubmitDialog, setShowSubmitDialog] = useState<boolean>(false);
  const [showQualificationDialog, setShowQualificationDialog] =
    useState<boolean>(false);
  const [showSpecialityDialog, setShowSpecialityDialog] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const emailRegex = /\S+@\S+\.\S+/;
  const regexMobileNumber = /^[6-9]{1}[0-9]{9}$/i;
  let regexPincode = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

  //ADDING FORM FIELD

  const addAddressField = () => {
    addressDetails.push({
      type: "secondary",
      source: "data_entry_profiler",
      latLong: "",
      line: "",
      landmark: "",
      pincode: 0,
      area: "",
      district: "",
      state: [""],
      country: [""],
    });
    setAddressDetails([...addressDetails]);
  };

  const addMobileField = () => {
    isVerified.mobile.push(true);
    mobileNumberDetails.push({
      type: "mobile",
      value: "",
      status: "active",
    });
    setMobileNumberDetails([...mobileNumberDetails]);
  };

  const addSocialMediaField = () => {
    isVerified.socials.push(true);
    socialMediaDetails.push({
      type: "",
      value: "",
      status: "active",
    });
    socialMediaType.push("");
    setSocialMediaDetails([...socialMediaDetails]);
  };

  const addEmailField = () => {
    isVerified.email.push(true);
    emailDetails.push({
      type: "email",
      value: "",
      status: "active",
    });
    setEmailDetails([...emailDetails]);
  };
  const addSpecialityField = () => {
    isVerified.speciality.push(true);
    specialityDetails.push({
      value: "",
    });
    setSpecialityDetails([...specialityDetails]);
  };

  const addNameField = () => {
    isVerified.name.push(true);
    nameDetails.push({
      name: "",
    });
    setNameDetails([...nameDetails]);
  };

  const addHospitalNameField = () => {
    isVerified.hospitalName.push(true);
    hospitalNameDetails.push({
      name: "",
    });
    setHospitalNameDetails([...hospitalNameDetails]);
  };

  const addQualificationField = () => {
    isVerified.qualification.push(true);
    qualificationDetails.push({
      value: "",
    });
    setQualificationDetails([...qualificationDetails]);
  };

  //REMOVE FORM FIELD

  const removeAddressField = (index: number) => {
    postalAddress.splice(index, 1);
    addressDetails.splice(index, 1);
    formErrors.address.line.splice(index, 1);
    formErrors.address.landmark.splice(index, 1);
    formErrors.address.area.splice(index, 1);
    formErrors.address.state.splice(index, 1);
    formErrors.address.country.splice(index, 1);
    formErrors.address.pincode.splice(index, 1);
    setAddressDetails([...addressDetails]);
  };

  const removeMobileField = (index: number) => {
    mobileNumberDetails.splice(index, 1);
    formErrors.mobile.splice(index, 1);
    setMobileNumberDetails([...mobileNumberDetails]);
  };

  const removeSocialMediaField = (index: number) => {
    socialMediaDetails.splice(index, 1);
    socialMediaType.splice(index, 1);
    formErrors.socialMediaLink.splice(index, 1);
    setSocialMediaType([...socialMediaType]);
    setSocialMediaDetails([...socialMediaDetails]);
  };

  const removeQualificationField = (index: number) => {
    qualificationDetails.splice(index, 1);
    formErrors.qualification.splice(index, 1);
    setQualificationDetails([...qualificationDetails]);
  };

  const removeEmailField = (index: number) => {
    emailDetails.splice(index, 1);
    formErrors.email.splice(index, 1);
    setEmailDetails([...emailDetails]);
  };
  const removeSpecialityField = (index: number) => {
    specialityDetails.splice(index, 1);
    formErrors.speciality.splice(index, 1);
    setSpecialityDetails([...specialityDetails]);
  };
  const removeNameField = (index: number) => {
    nameDetails.splice(index, 1);
    formErrors.name.splice(index, 1);
    setNameDetails([...nameDetails]);
  };

  const removeHospitalNameField = (index: number) => {
    hospitalNameDetails.splice(index, 1);
    formErrors.hospital.splice(index, 1);
    setHospitalNameDetails([...hospitalNameDetails]);
  };

  //Handle form fields change

  const handleChangeSocialMediaType = (index: number, value: string) => {
    const values = [...socialMediaType];
    values[index] = value;
    setSocialMediaType(values);
  };

  const handleAddressChange = (index: number, name: string, value: string) => {
    const values = [...addressDetails];
    if (name == "state" || name == "country") {
      values[index][name] = [value];
    } else if (name == "pincode") {
      postalAddress.splice(index, 1);
      if (isNaN(parseInt(value))) {
        values[index][name] = 0;
      } else {
        values[index][name] = parseInt(value);
      }
    } else {
      values[index][name] = value;
    }

    if (regexPincode.test(value)) {
      getAddressBasedOnPincode(parseInt(value), index);
    }

    setAddressDetails(values);
  };

  const handleMobileNumberChange = (index: number, value: string) => {
    const values = [...mobileNumberDetails];
    if (!regexMobileNumber.test(value)) {
      formErrors.mobile[index] = Constants.MOBILE_NUMBER_VALIDATION_ERROR;
      values[index].value = value;
      setMobileNumberDetails(values);
    } else {
      formErrors.mobile[index] = "";
      values[index].value = value;
      setMobileNumberDetails(values);
    }
    setFormErrors({ ...formErrors });
  };

  const handleQualificationChange = (index: number, value: string) => {
    const values = [...qualificationDetails];

    values[index].value = value;
    setQualificationDetails(values);
  };

  const handleSocialMediaChange = (index: number, value: string) => {
    const values = [...socialMediaDetails];

    values[index].value = value;
    setSocialMediaDetails(values);
  };
  const handleEmailChange = (index: number, value: string) => {
    const values = [...emailDetails];
    if (!emailRegex.test(value)) {
      formErrors.email[index] = Constants.EMAIL_ID_VALIDATION_ERROR;
      values[index].value = value;
      setEmailDetails(values);
    } else {
      formErrors.email[index] = "";
      values[index].value = value;
      setEmailDetails(values);
    }
    setFormErrors({ ...formErrors });
  };

  const handleSpecialityChange = (index: number, value: string) => {
    const values = [...specialityDetails];

    values[index].value = value;
    setSpecialityDetails(values);
  };

  const handleNameChange = (index: number, value: string) => {
    const values = [...nameDetails];

    values[index].name = value;
    setNameDetails(values);
  };

  const handleHospitalNameChange = (index: number, value: string) => {
    const values = [...hospitalNameDetails];

    values[index].name = value;
    setHospitalNameDetails(values);
  };

  const loadingIndicator = (loading: boolean) => {
    if (loading) {
      Swal.fire({
        title: "Response Loading...",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  const getAddressBasedOnPincode = async (
    pincodeValue: number,
    index: number
  ) => {
    if (pincodeValue) {
      try {
        let result = await getAddress.get({ pincode: pincodeValue.toString() });

        if (result.success) {
          postalAddress.push(result.data[0]);
          addressDetails[index].district = result.data[0].district;
          addressDetails[index].country = [result.data[0].country];
          addressDetails[index].state = [result.data[0].state];
          setPostalAddress([...postalAddress]);
        } else {
          Swal.fire("Invalid Pincode", "", "error");
        }
      } catch (err) {
        Swal.fire("Invalid Pincode", "", "error");
      }
    }
  };

  useEffect(() => {
    if (doctorDetails._id) {
      for (let i = 0; i <= existedMobileNumberDetails.length; i++) {
        mobileNumberDetails.pop();
        existedMobileNumberDetails.pop();
        isVerified.mobile.pop();
      }
      for (let i = 0; i <= existedEmailDetails.length; i++) {
        emailDetails.pop();
        existedEmailDetails.pop();
        isVerified.email.pop();
      }
      for (let i = 0; i < doctorDetails.socials.length; i++) {
        socialMediaDetails.pop();
        isVerified.socials.pop();
      }
      for (let i = 0; i < doctorDetails.qualification.length; i++) {
        qualificationDetails.pop();
        isVerified.qualification.pop();
      }
      for (let i = 0; i < doctorDetails.specialities.length; i++) {
        specialityDetails.pop();
        isVerified.speciality.pop();
      }
      for (let i = 0; i < doctorDetails.fullNames.length; i++) {
        nameDetails.pop();
        isVerified.name.pop();
      }
      for (let i = 0; i < doctorDetails.addresses.length; i++) {
        addressDetails.pop();
        isVerified.addresses.pop();
      }
      for (let i = 0; i <= details.mobileContactID.length; i++) {
        details.mobileContactID.pop();
      }
      for (let i = 0; i <= details.emailContactID.length; i++) {
        details.emailContactID.pop();
      }
      for (let i = 0; i < details.addressID.length; i++) {
        details.addressID.pop();
      }
      for (let i = 0; i <= details.socialMediaLinks.length; i++) {
        details.socialMediaLinks.pop();
      }
      for (let i = 0; i < details.qualification.length; i++) {
        details.qualification.pop();
      }
      for (let i = 0; i < details.name.length; i++) {
        details.name.pop();
      }
      for (let i = 0; i < details.speciality.length; i++) {
        details.speciality.pop();
      }
      if (
        mobileNumberDetails.length == 0 &&
        emailDetails.length == 0 &&
        socialMediaDetails.length == 0 &&
        specialityDetails.length == 0 &&
        qualificationDetails.length == 0 &&
        nameDetails.length == 0 &&
        doctorDetails._id
      ) {
        for (let i = 0; i < doctorDetails.qualification?.length; i++) {
          if (
            doctorDetails.qualification[i]?.verification?.status == "verified"
          ) {
            isVerified.qualification.push(true);
          } else {
            isVerified.qualification.push(false);
          }
          details.qualification.push(doctorDetails.qualification[i]?._id);
        }
        for (let i = 0; i < doctorDetails.fullNames?.length; i++) {
          if (doctorDetails.fullNames[i]?.verification?.status == "verified") {
            isVerified.name.push(true);
          } else {
            isVerified.name.push(false);
          }

          details.name.push(doctorDetails.fullNames[i]?._id);
        }

        for (let i = 0; i < doctorDetails.socials?.length; i++) {
          if (doctorDetails.socials[i]?.verification?.status == "verified") {
            isVerified.socials.push(true);
          } else {
            isVerified.socials.push(false);
          }
          details.socials.push(doctorDetails.socials[i]?._id);
        }

        for (let i = 0; i < doctorDetails.specialities?.length; i++) {
          if (
            doctorDetails.specialities[i]?.verification?.status == "verified"
          ) {
            isVerified.speciality.push(true);
          } else {
            isVerified.speciality.push(false);
          }
          details.speciality.push(doctorDetails.specialities[i]?._id);
        }

        for (let i = 0; i <= doctorDetails.contacts?.length; i++) {
          if (doctorDetails.contacts[i]?.type == "mobile") {
            if (doctorDetails.contacts[i]?.verification?.status == "verified") {
              isVerified.mobile.push(true);
            } else {
              isVerified.mobile.push(false);
            }

            existedMobileNumberDetails.push({
              type: doctorDetails.contacts[i]?.type,
              value: doctorDetails.contacts[i]?.value,
              status: doctorDetails.contacts[i]?.status,
            });
            setExistedMobileNumberDetails([...existedMobileNumberDetails]);
            details.mobileContactID.push(doctorDetails.contacts[i]?._id);
          } else if (doctorDetails.contacts[i]?.type == "email") {
            if (doctorDetails.contacts[i]?.verification?.status == "verified") {
              isVerified.email.push(true);
            } else {
              isVerified.email.push(false);
            }
            existedEmailDetails.push({
              type: doctorDetails.contacts[i]?.type,
              value: doctorDetails.contacts[i]?.value,
              status: doctorDetails.contacts[i]?.status,
            });
            setExistedEmailDetails([...existedEmailDetails]);

            details.emailContactID.push(doctorDetails.contacts[i]?._id);
          }
        }

        for (let i = 0; i < doctorDetails.addresses?.length; i++) {
          if (doctorDetails.addresses[i]?.verification?.status == "verified") {
            isVerified.addresses.push(true);
          } else {
            isVerified.addresses.push(false);
          }
          details.addressID.push(doctorDetails.addresses[i]?._id);
        }
      }
    }
  }, [doctorDetails._id]);

  const landmarkRegrex = /^(?=(?:.*[a-zA-Z0-9]){15,}).*$/;
  const lineRegrex = /^(?=(?:.*[a-zA-Z0-9]){15,}).*$/;

  const handleSaveDetails = async () => {
    for (let i = 0; i < addressDetails.length; i++) {
      if (addressDetails[i].line.length == 0) {
        formErrors.address.line[i] = Constants.FIELD_VALIDATION_ERROR;
      } else if (!lineRegrex.test(addressDetails[i].line)) {
        formErrors.address.line[i] = "Enter minimum 15 alphanumeric characters";
      } else {
        formErrors.address.line[i] = "";
      }
      if (addressDetails[i].landmark.length == 0) {
        formErrors.address.landmark[i] = Constants.FIELD_VALIDATION_ERROR;
      } else if (!landmarkRegrex.test(addressDetails[i].landmark)) {
        formErrors.address.landmark[i] =
          "Enter minimum 15 alphanumeric characters";
      } else {
        formErrors.address.landmark[i] = "";
      }
      if (addressDetails[i].area.length == 0) {
        formErrors.address.area[i] = Constants.FIELD_VALIDATION_ERROR;
      } else {
        formErrors.address.area[i] = "";
      }
      if (addressDetails[i].district.length == 0) {
        formErrors.address.district[i] = Constants.FIELD_VALIDATION_ERROR;
      } else {
        formErrors.address.district[i] = "";
      }
      if (addressDetails[i].state.length == 0) {
        formErrors.address.state[i] = Constants.FIELD_VALIDATION_ERROR;
      } else {
        formErrors.address.state[i] = "";
      }
      if (addressDetails[i].country.length == 0) {
        formErrors.address.country[i] = Constants.FIELD_VALIDATION_ERROR;
      } else {
        formErrors.address.country[i] = "";
      }
      if (addressDetails[i].pincode == 0) {
        formErrors.address.pincode[i] = Constants.FIELD_VALIDATION_ERROR;
      } else {
        formErrors.address.pincode[i] = "";
      }
      setFormErrors({ ...formErrors });
    }

    for (let i = 0; i < nameDetails.length; i++) {
      if (nameDetails[i].name.length == 0) {
        formErrors.name[i] = Constants.FIELD_VALIDATION_ERROR;
      } else {
        formErrors.name[i] = "";
      }
      setFormErrors({ ...formErrors });
    }

    for (let i = 0; i < hospitalNameDetails.length; i++) {
      if (hospitalNameDetails[i].name.length == 0) {
        formErrors.hospital[i] = Constants.FIELD_VALIDATION_ERROR;
      } else {
        formErrors.hospital[i] = "";
      }
      setFormErrors({ ...formErrors });
    }

    for (let i = 0; i < qualificationDetails.length; i++) {
      if (qualificationDetails[i].value.length == 0) {
        formErrors.qualification[i] = Constants.FIELD_VALIDATION_ERROR;
      } else {
        formErrors.qualification[i] = "";
      }
      setFormErrors({ ...formErrors });
    }
    for (let i = 0; i < specialityDetails.length; i++) {
      if (specialityDetails[i].value.length == 0) {
        formErrors.speciality[i] = Constants.FIELD_VALIDATION_ERROR;
      } else {
        formErrors.speciality[i] = "";
      }
      setFormErrors({ ...formErrors });
    }
    for (let i = 0; i < socialMediaDetails.length; i++) {
      if (socialMediaDetails[i].value.length == 0) {
        formErrors.socialMediaLink[i] = Constants.FIELD_VALIDATION_ERROR;
      } else {
        for (let i = 0; i < socialMediaType.length; i++) {
          if (socialMediaType[i].length == 0) {
            formErrors.socialMediaLink[i] =
              Constants.SOCIAL_MEDIA_TYPE_VALIDATION;
          } else {
            formErrors.socialMediaLink[i] = "";
            socialMediaDetails[i].type = socialMediaType[i];
          }
        }
      }
      setFormErrors({ ...formErrors });
    }

    for (let i = 0; i < mobileNumberDetails.length; i++) {
      if (mobileNumberDetails[i].value.length == 0) {
        formErrors.mobile[i] = Constants.FIELD_VALIDATION_ERROR;
      } else if (!regexMobileNumber.test(mobileNumberDetails[i].value)) {
        formErrors.mobile[i] = Constants.MOBILE_NUMBER_VALIDATION_ERROR;
      } else {
        formErrors.mobile[i] = "";
      }
      setFormErrors({ ...formErrors });
    }

    for (let i = 0; i < emailDetails.length; i++) {
      if (emailDetails[i].value.length == 0) {
        formErrors.email[i] = Constants.FIELD_VALIDATION_ERROR;
      } else if (!emailRegex.test(emailDetails[i].value)) {
        formErrors.email[i] = Constants.EMAIL_ID_VALIDATION_ERROR;
      } else {
        formErrors.email[i] = "";
      }
      setFormErrors({ ...formErrors });
    }

    const mobileErrorArray = formErrors.mobile.filter((a) => a);
    const emailErrorArray = formErrors.email.filter((a) => a);
    const nameErrorArray = formErrors.name.filter((a) => a);
    const specialityErrorArray = formErrors.speciality.filter((a) => a);
    const qualificationErrorArray = formErrors.qualification.filter((a) => a);
    const socialErrorArray = formErrors.socialMediaLink.filter((a) => a);
    const lineErrorArray = formErrors.address.line.filter((a) => a);
    const landmarkErrorArray = formErrors.address.landmark.filter((a) => a);
    const areaErrorArray = formErrors.address.area.filter((a) => a);
    const stateErrorArray = formErrors.address.state.filter((a) => a);
    const pincodeErrorArray = formErrors.address.pincode.filter((a) => a);
    const countryErrorArray = formErrors.address.country.filter((a) => a);
    const districtErrorArray = formErrors.address.district.filter((a) => a);
    if (
      socialErrorArray.length == 0 &&
      mobileErrorArray.length == 0 &&
      emailErrorArray.length == 0 &&
      nameErrorArray.length == 0 &&
      specialityErrorArray.length == 0 &&
      qualificationErrorArray.length == 0 &&
      lineErrorArray.length == 0 &&
      landmarkErrorArray.length == 0 &&
      areaErrorArray.length == 0 &&
      stateErrorArray.length == 0 &&
      pincodeErrorArray.length == 0 &&
      countryErrorArray.length == 0 &&
      districtErrorArray.length == 0
    ) {
      let payload = {
        contacts: mobileNumberDetails.concat(emailDetails),
        socials: socialMediaDetails,
        qualifications: qualificationDetails,
        names: nameDetails,
        specialities: specialityDetails,
        addresses: addressDetails,
        attachedHospitals: hospitalNameDetails,
      };

      Object.keys(payload).forEach((key) => {
        if ((payload as any)[key].length === 0) {
          delete (payload as any)[key];
        }
      });
      setShowSubmitDialog(false);
      loadingIndicator(true);
      let result = await saveDoctorDetails.save(doctorDetails._id, payload);
      if (result.success) {
        loadingIndicator(false);
        Swal.fire(result.data.message, "", "success");
        reloadList();
      } else {
        loadingIndicator(false);
        Swal.fire(result.message, "", "error");
      }
    }
  };

  const handlePendingContacts = (index: number, type: string) => {
    setShowContactDialog(!showContactDialog);
    setArrayIndex({
      id: index,
      type: type,
    });
  };

  const handlePendingSpeciality = (index: number, type: string) => {
    setShowSpecialityDialog(!showSpecialityDialog);
    setArrayIndex({
      id: index,
      type: type,
    });
  };

  const handlePendingName = (index: number, type: string) => {
    setShowNameDialog(!showNameDialog);
    setArrayIndex({
      id: index,
      type: type,
    });
  };

  const handlePendingAddress = (index: number, type: string) => {
    setShowAddressDialog(!showAddressDialog);
    setArrayIndex({
      id: index,
      type: type,
    });
  };

  const handlePendingSocialMediaLink = (index: number, type: string) => {
    setArrayIndex({
      id: index,
      type: type,
    });
  };

  const handlePendingQualification = (index: number, type: string) => {
    setShowQualificationDialog(!showQualificationDialog);
    setArrayIndex({
      id: index,
      type: type,
    });
  };

  const handleSubmit = () => {
    setShowSubmitDialog(!showSubmitDialog);
  };

  const handleVerifyContact = async () => {
    setShowContactDialog(false);
    loadingIndicator(true);

    let contactID =
      arrayIndex.type == "mobile"
        ? details.mobileContactID[arrayIndex.id]
        : details.emailContactID[arrayIndex.id];
    let result = await verifyDoctorDetails.verify(doctorDetails._id, contactID);
    if (result.success) {
      loadingIndicator(false);
      Swal.fire(result.data.message, "", "success");
      if (arrayIndex.type == "mobile") {
        isVerified.mobile[arrayIndex.id] = true;
      } else {
        isVerified.email[arrayIndex.id] = true;
      }
      setIsVerified({ ...isVerified });
    } else {
      loadingIndicator(false);
      Swal.fire(result.message, "", "error");
    }
  };

  const handleVerifyAddress = async () => {
    setShowAddressDialog(false);
    loadingIndicator(true);
    let addressID = details.addressID[arrayIndex.id];

    let result = await verifyAddress.verify(doctorDetails._id, addressID);
    if (result.success) {
      loadingIndicator(false);
      Swal.fire(result.data.message, "", "success");
      isVerified.addresses[arrayIndex.id] = true;
      setIsVerified({ ...isVerified });
    } else {
      loadingIndicator(false);
      Swal.fire(result.message, "", "error");
    }
  };

  const handleVerifyName = async () => {
    setShowNameDialog(false);
    loadingIndicator(true);
    let fullNameId = details.name[arrayIndex.id];

    let result = await verifyName.verify(doctorDetails._id, fullNameId);
    if (result.success) {
      loadingIndicator(false);
      Swal.fire(result.data.message, "", "success");
      isVerified.name[arrayIndex.id] = true;
      setIsVerified({ ...isVerified });
    } else {
      loadingIndicator(false);
      Swal.fire(result.message, "", "error");
    }
  };

  const handleVerifyQualification = async () => {
    setShowQualificationDialog(false);
    loadingIndicator(true);
    let qualificationId = details.qualification[arrayIndex.id];

    let result = await verifyQualification.verify(
      doctorDetails._id,
      qualificationId
    );
    if (result.success) {
      loadingIndicator(false);
      Swal.fire(result.data.message, "", "success");
      isVerified.qualification[arrayIndex.id] = true;
      setIsVerified({ ...isVerified });
    } else {
      loadingIndicator(false);
      Swal.fire(result.message, "", "error");
    }
  };

  const handleVerifySpeciality = async () => {
    setShowSpecialityDialog(false);
    loadingIndicator(true);
    let specialityId = details.speciality[arrayIndex.id];

    let result = await verifySpeciality.verify(doctorDetails._id, specialityId);
    if (result.success) {
      loadingIndicator(false);
      Swal.fire(result.data.message, "", "success");
      isVerified.speciality[arrayIndex.id] = true;
      setIsVerified({ ...isVerified });
    } else {
      loadingIndicator(false);
      Swal.fire(result.message, "", "error");
    }
  };

  return (
    <>
      <div style={{ display: "flex", gap: 20, marginTop: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <NameField
              existingNameDetails={doctorDetails.fullNames}
              isVerified={isVerified.name}
              handlePendingContacts={handlePendingName}
              removeNameField={removeNameField}
              nameDetails={nameDetails}
              handleNameChange={handleNameChange}
              formErrors={formErrors.name}
              addNameField={addNameField}
            />
          </Grid>

          <Grid item xs={4}>
            <MobileNumber
              existingMobileNumberDetails={existedMobileNumberDetails}
              isVerified={isVerified.mobile}
              handlePendingContacts={handlePendingContacts}
              removeMobileNumberField={removeMobileField}
              mobileNumberDetails={mobileNumberDetails}
              handleMobileNumberChange={handleMobileNumberChange}
              formErrors={formErrors.mobile}
              addMobileNumberField={addMobileField}
            />
          </Grid>
          <Grid item xs={4}>
            <HospitalNameField
              existingHospitalDetails={doctorDetails.attachedHospitals}
              removeHospitalField={removeHospitalNameField}
              hospitalNameDetails={hospitalNameDetails}
              handleHospitalNameChange={handleHospitalNameChange}
              formErrors={formErrors.hospital}
              addHospitalNameField={addHospitalNameField}
            />
          </Grid>
          <Grid item xs={4}>
            <Email
              existingEmailDetails={existedEmailDetails}
              isVerified={isVerified.email}
              handlePendingContacts={handlePendingContacts}
              removeEmailField={removeEmailField}
              emailDetails={emailDetails}
              handleEmailChange={handleEmailChange}
              formErrors={formErrors.mobile}
              addEmailField={addEmailField}
            />
          </Grid>
          <Grid item xs={4}>
            <SpecialityField
              existingSpecialityDetails={doctorDetails.specialities}
              isVerified={isVerified.speciality}
              handlePendingSpeciality={handlePendingSpeciality}
              removeSpecialityField={removeSpecialityField}
              specialityDetails={specialityDetails}
              handleSpecialityChange={handleSpecialityChange}
              formErrors={formErrors.speciality}
              addSpecialityField={addSpecialityField}
              specialities={specialities}
            />
          </Grid>
          <Grid item xs={4}>
            <QualificationField
              existingQualificationDetails={doctorDetails.qualification}
              isVerified={isVerified.qualification}
              handlePendingQualification={handlePendingQualification}
              removeQualificationField={removeQualificationField}
              qualificationDetails={qualificationDetails}
              handleQualificationChange={handleQualificationChange}
              formErrors={formErrors.qualification}
              addQualificationField={addQualificationField}
              qualifications={qualifications}
            />
          </Grid>
          <Grid item xs={4}>
            <SocialMediaLink
              existingSocialMediaLinkDetails={doctorDetails.socials}
              isVerified={isVerified.socials}
              handlePendingSocialMediaLink={handlePendingSocialMediaLink}
              removeSocialMediaLinkField={removeSocialMediaField}
              socialMediaLinkDetails={socialMediaDetails}
              handleSocialMediaLinkChange={handleSocialMediaChange}
              formErrors={formErrors.socialMediaLink}
              addSocialMediaLinkField={addSocialMediaField}
              handleChangeSocialMediaType={handleChangeSocialMediaType}
              socialMediaType={socialMediaType}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressField
              existingAddressDetails={doctorDetails.addresses}
              isVerified={isVerified.addresses}
              handlePendingAddress={handlePendingAddress}
              newaddressDetails={addressDetails}
              handleChange={handleAddressChange}
              removeAddressField={removeAddressField}
              addAddressField={addAddressField}
              formErrors={formErrors.address}
              postalAddress={postalAddress}
            />
          </Grid>
        </Grid>
      </div>
      {mobileNumberDetails.length > 0 ||
      emailDetails.length > 0 ||
      nameDetails.length > 0 ||
      specialityDetails.length > 0 ||
      qualificationDetails.length > 0 ||
      socialMediaDetails.length > 0 ||
      addressDetails.length > 0 ? (
        <Button
          variant="contained"
          color="warning"
          onClick={handleSaveDetails}
          fullWidth
          sx={{ marginTop: 10 }}
        >
          <div style={{ display: "flex", gap: 10 }}>
            {loading && <CircularProgress color="success" size={20} />}
            SAVE DETAILS
          </div>
        </Button>
      ) : (
        <Button
          variant="contained"
          color="warning"
          onClick={() => setShowSubmitDialog(true)}
          fullWidth
          sx={{ marginTop: 10 }}
        >
          <div style={{ display: "flex", gap: 10 }}>
            {loading && <CircularProgress color="success" size={20} />}
            SUBMIT DETAILS
          </div>
        </Button>
      )}

      <ConfirmApprove
        openDialog={showContactDialog}
        handleClose={handlePendingContacts}
        handleApprove={handleVerifyContact}
        name={"Contact"}
      />
      <ConfirmApprove
        openDialog={showAddressDialog}
        handleClose={handlePendingAddress}
        handleApprove={handleVerifyAddress}
        name={"Address"}
      />
      <ConfirmApprove
        openDialog={showNameDialog}
        handleClose={handlePendingName}
        handleApprove={handleVerifyName}
        name={"Name"}
      />
      <ConfirmApprove
        openDialog={showSpecialityDialog}
        handleClose={handlePendingSpeciality}
        handleApprove={handleVerifySpeciality}
        name={"Speciality"}
      />
      <ConfirmApprove
        openDialog={showQualificationDialog}
        handleClose={handlePendingQualification}
        handleApprove={handleVerifyQualification}
        name={"Qualification"}
      />
      <ConfirmSubmit
        openDialog={showSubmitDialog}
        handleClose={handleSubmit}
        handleApprove={handleSaveDetails}
      />
    </>
  );
};

export default DoctorBasicDetailsForm;
