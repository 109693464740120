import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import IMAGES from "../../../images";
import { pageRoutes } from "../../../routes";
import Header from "../../components/data-entry/Header";
import React from "react";
import { Grid } from "@mui/material";
import SideBar from "../../components/data-entry/SideBar";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import { AUTH_TOKEN, LOGGEDIN_USER } from "../../../base";
import { useHomePageMainStore } from "../../../store/main/home";
import ConfirmLogout from "../../components/data-entry/doctorProfilerVerifcation/ConfirmLogout";

type Props = {};
const HomePage: React.FC<Props> = ({}) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const navigate = useNavigate();
  const jsonStorage = LocalJsonStorage.getInstance();
  const storage = LocalJsonStorage.getInstance();
  const local = new LocalLoggedInUser(jsonStorage);
  const { handleExpandSideBar } = useHomePageMainStore();
  const [openLogoutConfirmModal, setOpenLogoutConfirmModal] = useState(false);

  const Admin: {
    title: string;
    icon: string;
    highlightedIcon: string;
    route: string;
    onTap: Function;
  }[] = [
    {
      title: "Verification Data Analytics",
      icon: IMAGES.GraphIcon2,
      highlightedIcon: IMAGES.GraphIcon1,
      route: pageRoutes.doctorsCountSummary,
      onTap: () =>
        onTapIcon(
          pageRoutes.doctorsCountSummary,
          "Verification Data Analytics"
        ),
    },
    {
      title: "Logout",
      icon: IMAGES.LogoutIcon2,
      highlightedIcon: IMAGES.LogoutIcon1,
      route: pageRoutes.dataEntryVerficationLogin,
      onTap: () => setOpenLogoutConfirmModal(true),
    },
  ];

  const nonAdmin: {
    title: string;
    icon: string;
    highlightedIcon: string;
    route: string;
    onTap: Function;
  }[] = [
    {
      title: "Doctor Profiler Verification",
      icon: IMAGES.DoctorIcon2,
      highlightedIcon: IMAGES.DoctorIcon1,
      route: pageRoutes.doctorVerification,
      onTap: () =>
        onTapIcon(
          pageRoutes.doctorVerification,
          "Doctor Profiler Verification"
        ),
    },

    {
      title: "Logout",
      icon: IMAGES.LogoutIcon2,
      highlightedIcon: IMAGES.LogoutIcon1,
      route: pageRoutes.dataEntryVerficationLogin,
      onTap: () => setOpenLogoutConfirmModal(true),
    },
  ];

  const onTapIcon = (route: string, title: string) => {
    setPageTitle(title);
    navigate(route);
  };
  const loggedInDetails = local.getLoggedInUser();
  useEffect(() => {
    console.log(loggedInDetails?.role);
    if (window.location.pathname == pageRoutes.doctorsCountSummary) {
      setPageTitle(
        loggedInDetails?.isAdmin ? Admin[0].title : nonAdmin[0].title
      );
    }
    if (!storage.get(AUTH_TOKEN)) {
      navigate(pageRoutes.dataEntryVerficationLogin);
    }
  }, []);

  const handleSideBar = () => {
    setShowSideBar(!showSideBar);
    handleExpandSideBar(!showSideBar);
  };
  const handleLogout = () => {
    local.logout();
    navigate(pageRoutes.dataEntryVerficationLogin);
  };
  const handleCloseLogoutModal = () => {
    setOpenLogoutConfirmModal(false);
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        position={"fixed"}
        width={showSideBar ? "85%" : "98%"}
        bgcolor={"white"}
        zIndex={50}
        marginLeft={showSideBar ? 27 : 5}
      >
        <Header title={pageTitle} />
      </Grid>

      <Grid
        item
        xs={showSideBar ? 2.5 : 0.5}
        position={"fixed"}
        bgcolor={"white"}
        zIndex={50}
      >
        <SideBar
          Menus={loggedInDetails?.isAdmin ? Admin : nonAdmin}
          showSideBar={showSideBar}
          handleExpandSideBar={handleSideBar}
        />
      </Grid>
      <Grid item xs={showSideBar ? 12 : 11.5} marginLeft={showSideBar ? 30 : 8}>
        <Outlet />
      </Grid>
      <ConfirmLogout
        openDialog={openLogoutConfirmModal}
        handleApprove={handleLogout}
        handleClose={handleCloseLogoutModal}
      />
    </Grid>
  );
};

export default HomePage;
