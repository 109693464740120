import { LoadingButton } from "@mui/lab";
import React from "react";
import { LoginFormFields } from "../../../domain/models/data-entry/loginFormFields";
import { Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

type Props = {
  sucessCallback: Function;
  loading: boolean;
};

const LoginForm: React.FC<Props> = ({ sucessCallback, loading }) => {
  const { handleSubmit, control, watch } = useForm<LoginFormFields>({
    mode: "onChange",
  });

  const onSubmit = (data: LoginFormFields) => {
    sucessCallback(data);
  };

  return (
    <Stack width={"70%"} spacing={2}>
      <Controller
        name="email"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            placeholder="Enter Email Address"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error?.message}
          />
        )}
        rules={{
          required: { value: true, message: "Field Required" },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid Username",
          },
        }}
      />
      <Controller
        name="password"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type="password"
            fullWidth
            disabled={watch("email") ? false : true}
            placeholder="Enter Password"
            value={value}
            error={!!error}
            helperText={error?.message}
            onChange={onChange}
          />
        )}
        rules={{
          required: { value: true, message: "Field Required" },
        }}
      />
      <LoadingButton
        loading={loading}
        loadingPosition="start"
        variant="contained"
        color="warning"
        disabled={watch("password") ? false : true}
        onClick={handleSubmit(onSubmit)}
      >
        <Typography>Login</Typography>
      </LoadingButton>
    </Stack>
  );
};

export default LoginForm;
