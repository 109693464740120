import { useCallback, useEffect, useState } from "react";
import { Doctor, DoctorFilters } from "../domain/models/doctor";
import { Result } from "../domain/models/result";
import { FetchDoctorList } from "../domain/usages/fetch-doctor-list";

const useDoctors = (fetchDoctors: FetchDoctorList) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [doctors, setDoctors] = useState<Doctor[]>();
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<DoctorFilters>({} as DoctorFilters);

  /**
   * Fetch doctor
   */
  const fetch = useCallback(async () => {
    setLoading(true);
    let result: Result = await fetchDoctors.fetch(filters);
    if (result.success) {
      setDoctors(result.doctors);
      setTotal(result.totalRecords);
      setLoading(false);
    }
  }, [fetchDoctors]);

  useEffect(() => {
    if (filters) fetch();
  }, [filters]);

  return [loading, doctors, total, setFilters] as const;
};

export default useDoctors;
