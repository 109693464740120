import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Hospital } from "../../domain/models/hospital";
import { CreateHospital } from "../../domain/usages/create-hospital";
import { FetchHospitalsNearMe } from "../../domain/usages/fetch-hospitals-near-me";
import AppBar from "../components/common/sections/app-bar";
import CreateHospitalForm from "../components/hospital/create-hospital-form";
import NearByHospitals from "../components/hospital/near-by-hospitals";

type Props = {
  createHospital: CreateHospital;
  fetchHospitalNearMe: FetchHospitalsNearMe;
};
const CreateHospitalPage: React.FC<Props> = ({
  createHospital,
  fetchHospitalNearMe,
}) => {
  const navigate = useNavigate();
  const [hospital, setHospital] = useState<Hospital>();

  return (
    <section className={`h-full ${!hospital ? "bg-primary_low" : "bg-white"} `}>
      <div className="md:container md:mx-auto md:px-5 h-full sm:w-screen">
        <AppBar
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              className="text-primary"
              onClick={() => navigate(-1)}
            />
          }
          title={
            <span className="text-primary font-bold drop-shadow-2xl">
              Let's profile new Hospital
            </span>
          }
        />

        {!hospital && (
          <NearByHospitals
            fetchHospitalNearMe={fetchHospitalNearMe}
            onHospitalSelection={setHospital}
          />
        )}

        {hospital && (
          <CreateHospitalForm
            hospital={hospital}
            createHospital={createHospital}
          />
        )}
      </div>
    </section>
  );
};

export default CreateHospitalPage;
