import { Controller, useForm } from "react-hook-form";
import { DoctorSearch } from "../../../domain/models/doctor-search";
import { Option } from "../inputs";
import { Button } from "../../ga-components/buttons";
import ButtonColor from "../../ga-components/buttons/button-color";
import SelectField from "../../ga-components/input/select";

type SearchProps = {
  onSubmit: (fields: DoctorSearchDetails) => void;
  statusOptions: string[];
};

export type DoctorSearchDetails = {
  status: { value: string; label: string };
};

const DoctorFilter = ({ onSubmit, statusOptions }: SearchProps) => {
  const { handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      status: { value: "", label: "" },
    } as DoctorSearchDetails,
  });

  return (
    <div>
      <span>
        <strong>Search:</strong>
      </span>
      <Controller
        name="status"
        control={control}
        render={({ field: { onChange, value } }) => (
          <SelectField
            label="Status"
            valueData={value}
            name=" Status"
            placeholder="Select Status"
            options={statusOptions.map((s) => ({ key: s, value: s } as Option))}
            onChange={(value: any) => onChange(value)}
          />
        )}
      />
      <Button
        onClick={handleSubmit(onSubmit)}
        color={ButtonColor.PRIMARY}
        text="Search"
        className="hover:bg-secondary hover:-translate-y-1 transform transition focus:bg-secondary-low focus:text-black"
      />
    </div>
  );
};
export default DoctorFilter;
