import { EmptyResponse, Result } from "../../domain/models/result";
import { AddDoctorChemist } from "../../domain/usages/add-doctor-chemist";
import { HttpPostClient } from "../protocols/http/http-post-client";

export class RemoteAddDoctorChemist implements AddDoctorChemist {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async create(id: string, params: AddDoctorChemist.Params): Promise<Result> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: "Server error. Please try after sometime." };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
