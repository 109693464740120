export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL!;
export const HOSPITAL_DOCTOR_SERVICE_API_URL =
  process.env.REACT_APP_HOSPITAL_DOCTOR_SERVICE_API_URL!;
export const POST_OFFICE_API = process.env.REACT_APP_POST_OFFICE_API!;
export const AUTH_TOKEN: string =
  process.env.REACT_APP_AUTH_TOKEN ?? "auth_token";
export const AUTH_HEADER: string = process.env.REACT_APP_AUTH_HEADER!;
export const AUTH_HEADER_VERIFICATION: string =
  process.env.REACT_APP_AUTH_HEADER_VERIFICATION!;

export const MASTER_MANAGEMENT_API_URL =
  process.env.REACT_APP_MASTER_MANAGEMENT_API_URL;

export const DOCTOR_SERVICE_API_URL =
  process.env.REACT_APP_DOCTOR_SERVICE_API_URL;
export const GOOGLE_CLOUD_API_KEY: string =
  process.env.REACT_APP_GOOGLE_CLOUD_API_KEY!;
export const AUTH_USER: string = process.env.MIX_AUTH_USER ?? "user";
export const LOGGEDIN_USER: string =
  process.env.REACT_APP_AUTH_LOGGEDIN_USER ?? "logged";
export const LOCATION_SERVICE_TOKEN =
  process.env.REACT_APP_LOCATION_SERVICE_TOKEN;
export const LOCATION_SERVICE_API_URL =
  process.env.REACT_APP_LOCATION_SERVICE_API_URL;
