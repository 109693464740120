import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Hospital } from "../../../domain/models/hospital";
import { Result } from "../../../domain/models/result";
import { CreateHospital } from "../../../domain/usages/create-hospital";
import { pageRoutes } from "../../../routes";
import TonalFixedButton from "../common/buttons/tonal-fixed-button";
import Header, { HeaderSize, HeaderType } from "../common/typography/header";
import { InputVariant, NumberField, TextField } from "../inputs/index";

type Props = {
  hospital: Hospital;
  createHospital: CreateHospital;
};
const CreateHospitalForm: React.FC<Props> = ({ hospital, createHospital }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: hospital });

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      let result: Result = await createHospital.create({
        full_name: data.full_name,
        addresses: data.addresses,
        govaxx_hospital: data.govaxx_hospital_flag ? "Yes" : "No",
      });
      if (!result.errors) {
        Swal.fire("Hospital created successfully.", "", "success");
        navigate("/home/" + pageRoutes.hospitals);
      } else {
        setLoading(false);
      }
    },
    [createHospital]
  );

  return (
    <div>
      <Header
        text={"Please fill all the details below to create a Hospital."}
        type={HeaderType.PRIMARY}
        size={HeaderSize.lg}
        className="font-thin"
      />
      <div className="p-4">
        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              placeholder="Hospital Name"
              error={error && error.message}
              label="Hospital Name"
            />
          )}
          rules={{ required: { value: true, message: "Name is required" } }}
        />

        <Controller
          name="addresses.0.pincode"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Pincode"
              label="Pincode"
            />
          )}
          rules={{
            pattern: {
              value: /^[1-9]{1}[0-9]{5}$/,
              message: "Invalid pincode",
            },
            required: { value: true, message: "Pincode is required" },
          }}
        />

        <Controller
          name="addresses.0.line"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Building, Colony"
              label="Building, Colony"
            />
          )}
          rules={{
            required: { value: true, message: "Address line is required" },
          }}
        />

        <Controller
          name="addresses.0.landmark"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              placeholder="Landmark"
              label="Landmark"
            />
          )}
          rules={{ required: { value: true, message: "Landmark is required" } }}
        />
      </div>
      <TonalFixedButton
        icon={faSave}
        onClick={handleSubmit(onSubmit)}
        text="Click here to create Hospital"
      />
    </div>
  );
};

export default CreateHospitalForm;
