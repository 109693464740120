import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Clinic } from "../../../domain/models/clinic";
import { Contact } from "../../../domain/models/contact";
import { Doctor } from "../../../domain/models/doctor";
import { HospitalDoctor } from "../../../domain/models/hospital-doctor";
import { Result } from "../../../domain/models/result";
import { AddDoctorClinic } from "../../../domain/usages/add-doctor-clinic";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { FetchHospitalsNearMe } from "../../../domain/usages/fetch-hospitals-near-me";
import { GenerateUploadUrl } from "../../../domain/usages/generate-upload-url";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import { UpdateDoctorClinic } from "../../../domain/usages/update-doctor-clinic";
import { UploadFile } from "../../../domain/usages/upload-file";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import TonalButton from "../../components/common/buttons/tonal-button";
import TonalFixedButton from "../../components/common/buttons/tonal-fixed-button";
import DoctorClinicForm from "../../components/doctor/doctor-clinic-form";
import NearByClinic from "../../components/doctor/near-by-clinic";
import DoctorClinicList from "../list/doctor-clinic-list";

type Props = {
  id: string;
  doctor: Doctor;
  addClinic: AddDoctorClinic;
  updateClinic: UpdateDoctorClinic;
  confirmFileUpload: ConfirmFileUpload;
  generateUploadUrl: GenerateUploadUrl;
  uploadFile: UploadFile;
  getReadLink: GetReadLink;
  successCallback: Function;
  fetchClinicsNearMe: FetchHospitalsNearMe;
};
const DoctorClinicDetailsForm: React.FC<Props> = ({
  id,
  doctor,
  addClinic,
  updateClinic,
  confirmFileUpload,
  generateUploadUrl,
  uploadFile,
  getReadLink,
  fetchClinicsNearMe,
  successCallback,
}) => {
  const [creating, setCreating] = useState(false);
  const [clinic, setClinic] = useState<Clinic>({} as Clinic);
  const [loading, setLoading] = useState(false);
  const [showNearByClinic, setShowNearByClinic] = useState(false);

  useEffect(() => {
    if (!doctor.clinics || doctor.clinics.length == 0) {
      setCreating(true);
    }
  }, [doctor]);

  useEffect(() => {
    if (creating == false) setClinic({} as Clinic);
  }, [creating]);

  const create = useCallback(
    async (data: any) => {
      setLoading(true);
      let result: Result = await addClinic.create(id, {
        full_name: data.full_name,
        mobile: data.mobile,
        no_of_staff: data.no_of_staff,
        billboard: data.billboard,
        lat_long: data.lat_long,
        addresses: data.addresses,
      });
      if (!result.errors) {
        Swal.fire("Clinic added to doctor successfully.", "", "success");
        successCallback();
        setCreating(false);
      } else {
        setLoading(false);
      }
    },
    [addClinic]
  );

  const update = useCallback(
    async (data: any) => {
      setLoading(true);
      let result: Result = await updateClinic.update(id, data._id, {
        full_name: data.full_name,
        mobile: data.mobile,
        no_of_staff: data.no_of_staff,
        billboard: data.billboard,
        lat_long: data.lat_long,
      });
      if (!result.errors) {
        Swal.fire("Clinic updated successfully.", "", "success");
        setCreating(false);
        successCallback();
      } else {
        setLoading(false);
      }
    },
    [updateClinic]
  );

  const handleAddNewClinic = () => {
    setCreating(true);
    setShowNearByClinic(true);
  };

  return (
    <div>
      {creating && doctor && (
        <>
          {doctor.clinics && doctor.clinics.length > 0 && (
            <div className="text-right">
              <TonalButton
                icon={faTimes}
                primary={false}
                text={"Close"}
                onClick={() => setCreating(false)}
              />
            </div>
          )}
          {!showNearByClinic && (
            <DoctorClinicForm
              clinic={clinic}
              generateUploadUrl={generateUploadUrl}
              uploadFile={uploadFile}
              confirmFileUpload={confirmFileUpload}
              successCallback={(data: HospitalDoctor) =>
                data._id ? update(data) : create(data)
              }
            />
          )}
          {showNearByClinic && (
            <NearByClinic
              fetchClinicNearMe={fetchClinicsNearMe}
              onClinicSelection={setClinic}
              setShowNearByClinic={setShowNearByClinic}
            />
          )}
        </>
      )}
      {!creating && (
        <DoctorClinicList
          doctor_id={doctor._id}
          clinics={doctor.clinics}
          onItemClick={(clinic: Clinic) => {
            setClinic(clinic);
            setCreating(true);
          }}
          getReadLink={getReadLink}
          successCallback={successCallback}
        />
      )}

      {!creating && doctor.clinics && doctor.clinics.length > 0 && (
        <TonalFixedButton
          loading={loading}
          icon={faPlus}
          primary={true}
          text={"ADD NEW CLINIC"}
          onClick={handleAddNewClinic}
        />
      )}
    </div>
  );
};

export default DoctorClinicDetailsForm;
