import React from "react";
import Select from "react-select";
import  {InputVariant}  from "../../components/inputs";
import InputGroup from "../../components/inputs/input-group";
import { Option } from "../../components/inputs/option";
import withTheme from "../theme/withTheme";

type Props = {
  theme: any;
  options: Option[];
  onChange: (selectedOption:any, key:any)=>void;
  valueData:any;
  name?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  variant?: InputVariant;
  label?: string;
};
const SelectField: React.FC<Props> = ({
  theme,
  options,
  valueData,
  onChange,
  name = "",
  placeholder,
  error,
  className,
  variant = InputVariant.FILLED,
  label=""
}) => {
  const classes = [className];
  if (error) classes.push(theme.input[variant].errorField);
  else classes.push(theme.input[variant].select);

  return (
    <InputGroup label={label} error={error}>
      <Select
        placeholder={placeholder}
        className={`${classes.join(" ")}`}
        name={name}
        value={valueData}
        options={options.map((o) => ({ value: o.value, label: o.key }))}
        onChange={onChange}
      />
  </InputGroup>
  );
};

export default withTheme(SelectField);
