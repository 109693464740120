import {
  faChartPie,
  faHospital,
  faStethoscope,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AUTH_TOKEN } from "../../base";
import { JsonStorage } from "../../data/protocols/storage/json-storage";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { pageRoutes } from "../../routes";
import Navigation from "../components/common/nav/nav";
import { NavMenuItem } from "../components/common/nav/nav-item";
import jwt_decode from "jwt-decode";
import { User } from "../../domain/models/user";

const HomeTabs = [
  {
    id: 1,
    title: "Dashboard",
    icon: faChartPie,
  } as NavMenuItem,
  {
    id: 2,
    title: "Doctor",
    icon: faStethoscope,
  } as NavMenuItem,
  {
    id: 3,
    title: "Hospital",
    icon: faHospital,
  } as NavMenuItem,
  {
    id: 4,
    title: "Profile",
    icon: faUserCircle,
  } as NavMenuItem,
];

type Props = { jsonStorage: JsonStorage };

const HomePage: React.FC<Props> = ({ jsonStorage }) => {
  const storage = LocalJsonStorage.getInstance();
  const token: string = storage.get(AUTH_TOKEN);

  const navigate = useNavigate();
  const [navItem, setNavItem] = useState<number>(1);
  const { geolocation } = navigator;
  const routeLocation = useLocation();
  const [isLocationAllowed, setIsLocationAllowed] = useState(true);
  const [location, setLocation] = useState<GeolocationCoordinates>();

  let jwtToken: User;
  let decodeData = "";

  if (token) {
    decodeData = atob(token);
    jwtToken = jwt_decode(decodeData);
  }

  const navRoutes: { [key: string]: string } = {
    1: pageRoutes.dashboard,
    2: pageRoutes.doctors,
    3: pageRoutes.hospitals,
    4: pageRoutes.myProfile,
  };

  useEffect(() => {
    if (routeLocation.pathname == navRoutes[1]) setNavItem(1);
    else if (routeLocation.pathname == navRoutes[2]) setNavItem(2);
    else if (routeLocation.pathname == navRoutes[3]) setNavItem(3);
    else if (routeLocation.pathname == navRoutes[4]) setNavItem(4);
  }, [routeLocation.pathname]);

  useEffect(() => {
    geolocation.getCurrentPosition(
      (position) => {
        setIsLocationAllowed(true);
        setLocation(position.coords);
        jsonStorage.add(
          "lat-long",
          position.coords.latitude + ", " + position.coords.longitude
        );
      },
      (error) => {
        setIsLocationAllowed(false);
      },
      {}
    );
  }, [location, jsonStorage, geolocation]);

  const setNavigationPage = (id: number) => {
    const selectedTab = HomeTabs.find((t) => t.id === id);
    if (selectedTab) {
      setNavItem(selectedTab.id);
      if (selectedTab.id === 3 && jwtToken.hq_code?.startsWith("PFBG")) {
        navigate("/home/" + pageRoutes.underMaintenance);
      } else {
        navigate(navRoutes[selectedTab.id]);
      }
    }
  };

  return (
    <section className="h-full bg-primary_low">
      <Outlet />
      <br></br>
      <Navigation
        items={HomeTabs}
        selectedItemId={navItem}
        setSelectedTabId={setNavigationPage}
      />
    </section>
  );
};

export default HomePage;
