import React, { useCallback, useEffect, useState } from "react";
import { Constants } from "../../../common/Constants";
import ChemistStatus from "../../../domain/enums/chemist-status";
import { Chemist } from "../../../domain/models/chemist";
import { Result } from "../../../domain/models/result";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import Clickable from "../../ga-components/clickable";
import FilledCard, { FilledColors } from "../common/cards/filled-card";

type HeaderProps = {
  title: string;
  status: string;
};
const DoctorChemistCardHeader: React.FC<HeaderProps> = ({ title, status }) => {
  const statusColor: Record<string, string> = {
    [ChemistStatus.PENDING]: Constants.PENDING_TEXT,
    [ChemistStatus.VERIFIED]: Constants.APPROVED_TEXT,
    [ChemistStatus.REJECTED]: Constants.REJECTED_TEXT,
  };
  const chemistStatus: Record<string, string> = {
    [ChemistStatus.PENDING]: Constants.PENDING,
    [ChemistStatus.VERIFIED]: Constants.VERIFIED,
    [ChemistStatus.REJECTED]: Constants.REJECT,
  };

  return (
    <div>
      {title}
      {status && (
        <span className={`${statusColor[status]} ml-5 text-base border p-2`}>
          {chemistStatus[status]}
        </span>
      )}
    </div>
  );
};

type Props = {
  chemist: Chemist;
  getReadLink: GetReadLink;
  onClick: Function;
};
const DoctorChemistCard: React.FC<Props> = ({
  chemist,
  getReadLink,
  onClick,
}) => {
  const [link, setLink] = useState<string>();

  const findReadLink = useCallback(async () => {
    let result: Result = await getReadLink.get(chemist.billboard[0].uuid, {});
    setLink(result.url);
  }, [getReadLink, chemist]);

  useEffect(() => {
    findReadLink();
  }, []);

  return (
    <Clickable onClick={onClick}>
      <FilledCard
        image={link && link}
        filledColor={chemist ? FilledColors.PRIMARY : FilledColors.SECONDARY}
        headline={
          <DoctorChemistCardHeader
            title={chemist.full_name}
            status={chemist.verification_status}
          />
        }
        subhead={chemist.mobile}
        supporting_text={
          <>
            <span>No. of Staff: </span>
            <span>{chemist.no_of_staff}</span>
          </>
        }
      />
    </Clickable>
  );
};

export default DoctorChemistCard;
