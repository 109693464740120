import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type Props = {
  text: any;
  icon?: any;
  onClick: Function;
  primary?: boolean;
  loading?: boolean;
  className?: string;
};
const TonalButton: React.FC<Props> = ({
  text,
  icon,
  primary = true,
  onClick,
  loading = false,
  className = "",
}) => {
  const classes = [className, "bottom-0 text-center h-10 pr-6 pl-4"];
  if (primary)
    return (
      <button
        className={` bg-primary ${classes.join(" ")}`}
        onClick={(e) => onClick(e)}
        disabled={loading}
      >
        {icon && !loading && (
          <span className="w-4 h-4">
            <FontAwesomeIcon icon={icon} className="text-white" />
          </span>
        )}
        {loading && (
          <span className="w-4 h-4">
            <FontAwesomeIcon icon={faSpinner} className="text-white" spin />
          </span>
        )}
        <span className="text-sm pl-2 text-white">{text}</span>
      </button>
    );
  else
    return (
      <button
        className={`bg-secondary ${classes.join(" ")}`}
        disabled={loading}
        onClick={(e) => onClick(e)}
      >
        {icon && !loading && (
          <span className="w-4 h-4">
            <FontAwesomeIcon icon={icon} className="text-white" />
          </span>
        )}
        {loading && (
          <span className="w-4 h-4">
            <FontAwesomeIcon icon={faSpinner} className="text-white" spin />
          </span>
        )}
        <span className="text-sm pl-2 text-white">{text}</span>
      </button>
    );
};

export default TonalButton;
