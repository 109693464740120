import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  existingMobileNumberDetails: Contacts[];
  isVerified: boolean[];
  handlePendingContacts: Function;
  removeMobileNumberField: Function;
  mobileNumberDetails: Contacts[];
  handleMobileNumberChange: Function;
  addMobileNumberField: Function;
  formErrors: string[];
};

type Contacts = {
  type: string;
  value: string;
  status: string;
};

const MobileNumber: React.FC<Props> = ({
  existingMobileNumberDetails,
  isVerified,
  handlePendingContacts,
  removeMobileNumberField,
  mobileNumberDetails,
  handleMobileNumberChange,
  formErrors,
  addMobileNumberField,
}) => {
  return (
    <div>
      <Typography variant="subtitle2">Mobile Number:</Typography>
      {existingMobileNumberDetails?.length > 0 &&
        existingMobileNumberDetails?.map((data, index) => {
          return (
            <div style={{ marginBottom: 10, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <TextField
                  value={data.value}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isVerified[index] ? (
                          <Tooltip title="Verified">
                            <CheckCircleOutlineIcon color="success" />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Pending, click to verify">
                            <ReportGmailerrorredIcon
                              color="warning"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handlePendingContacts(index, data.type)
                              }
                            />
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          );
        })}
      {mobileNumberDetails.map((data, index) => {
        return (
          <div style={{ marginBottom: 10, width: "100%" }}>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
              }}
            >
              <TextField
                name="mobile"
                placeholder="Enter Mobile Number"
                value={data.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleMobileNumberChange(index, event.target.value)
                }
                error={formErrors[index] ? true : false}
                helperText={formErrors[index]}
                fullWidth
              />
              <IconButton onClick={() => removeMobileNumberField(index)}>
                <Tooltip title="Cancel">
                  <RemoveCircleOutlineIcon color="error" />
                </Tooltip>
              </IconButton>
            </div>
          </div>
        );
      })}
      <Button
        variant="text"
        color="warning"
        onClick={()=>addMobileNumberField()}
      >
        <AddIcon /> ADD MOBILE NUMBER
      </Button>
    </div>
  );
};

export default MobileNumber;
