import React from "react";
import { Hospital } from "../../../domain/models/hospital";
import Clickable from "../../ga-components/clickable";
import FilledCard, { FilledColors } from "../common/cards/filled-card";

type HeaderProps = {
  title: string;
};
const DoctorHospitalCardHeader: React.FC<HeaderProps> = ({ title }) => {
  return <div>{title}</div>;
};

type Props = {
  hospital: Hospital;
  onClick: Function;
};
const DoctorHospitalCard: React.FC<Props> = ({ hospital, onClick }) => {
  return (
    <Clickable onClick={onClick}>
      <FilledCard
        filledColor={hospital ? FilledColors.PRIMARY : FilledColors.SECONDARY}
        headline={<DoctorHospitalCardHeader title={hospital.full_name} />}
        subhead={`Pincode: ${hospital.addresses[0].pincode}`}
        supporting_text={
          <>
            {hospital.addresses[0]?.line} {hospital.addresses[0]?.landmark}
            {hospital.addresses[0]?.area}
            {hospital.addresses[0]?.district}
          </>
        }
      />
    </Clickable>
  );
};

export default DoctorHospitalCard;
