import React, { useState, useEffect, useCallback } from "react";
import { Chemist } from "../../../domain/models/chemist";
import { Doctor } from "../../../domain/models/doctor";
import ListHandler from "../../components/common/list/ListHandler";
import SectionTitle from "../../ga-components/typography/section-title";
import { FindDoctor } from "../../../domain/usages/find-doctor";
import { Result } from "../../../domain/models/result";
import DoctorChemistCardList from "../list/doctor-chemist-card-list";
import { UpdateDoctorChemist } from "../../../domain/usages/update-doctor-chemist";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import DoctorChemistInformationForm from "../../components/doctor/doctor-chemist-information-form";
import { UpdateChemistVerification } from "../../../domain/usages/update-chemist-verification";
import ImageDisplay from "../../ga-components/image";

type ChemistDetailsProps = {
  id: string;
  getReadLink: GetReadLink;
  findDoctor: FindDoctor;
  updateChemist: UpdateDoctorChemist;
  updateChemistVerification: UpdateChemistVerification;
};

const ChemistDetails: React.FC<ChemistDetailsProps> = ({
  id,
  getReadLink,
  findDoctor,
  updateChemist,
  updateChemistVerification,
}) => {
  const [doctor, setDoctor] = useState<Doctor>({} as Doctor);
  const [chemist, setChemist] = useState<Chemist>();
  const [link, setLink] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [rotateImage, setRotateImage] = useState(0);

  const getDoctor = useCallback(async () => {
    let result: Result = await findDoctor.find(id);
    if (result.success) {
      setDoctor(result.doctor);
      result.doctor.chemists.map((chemistData) => {
        if (chemist?._id == chemistData._id) {
          setChemist(chemistData);
        }
      });
    }
  }, [findDoctor, id, chemist]);

  const findReadLink = useCallback(async () => {
    if (chemist) {
      let result: Result = await getReadLink.get(chemist.billboard[0].uuid, {});
      setLink(result.url);
    }
  }, [getReadLink, chemist]);

  useEffect(() => {
    getDoctor();
  }, [id, loading]);

  useEffect(() => {
    if (chemist) {
      findReadLink();
    }
  }, [chemist, rotateImage]);

  return (
    <>
      <div>
        <div className="grid grid-cols-3 gap-6">
          <div className="row-span-6">
            <>
              <div className="grid">
                <div className="flex justify-between">
                  <SectionTitle
                    title={`Chemist List (${doctor.chemists?.length})`}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 w-60 h-96 border-solid border-2 border-gray-400 rounded-lg overflow-y-scroll ">
                <ListHandler
                  total={doctor.chemists?.length}
                  list={doctor.chemists}
                  loading={false}
                >
                  <DoctorChemistCardList
                    chemists={doctor.chemists}
                    onItemClick={(chemist: Chemist) => {
                      setChemist(chemist);
                    }}
                  />
                </ListHandler>
              </div>
            </>
          </div>

          <div>
            {chemist && (
              <ImageDisplay
                imageLink={link}
                setRotateImage={setRotateImage}
                rotateImage={rotateImage}
              />
            )}
          </div>
          {chemist && (
            <div>
              <DoctorChemistInformationForm
                id={id}
                chemist={chemist}
                updateChemist={updateChemist}
                setLoading={setLoading}
                updateChemistVerification={updateChemistVerification}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChemistDetails;
