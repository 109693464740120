import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Address } from "../../../domain/models/address";
import { Clinic } from "../../../domain/models/clinic";
import { Image } from "../../../domain/models/image";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { GenerateUploadUrl } from "../../../domain/usages/generate-upload-url";
import { UploadFile } from "../../../domain/usages/upload-file";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import TonalFixedButton from "../common/buttons/tonal-fixed-button";
import { NumberField, TextField } from "../inputs/index";
import CameraImageField from "./camera-image-field";

type ClinicDetails = {
  _id: string;
  full_name: string;
  mobile: string;
  no_of_staff: number;
  billboard: Image;
  image: string;
  addresses: Address[];
  lat_long: string;
};

const convertClinicToDetails = (clinic: Clinic) => {
  const clinicDetils: ClinicDetails = {} as ClinicDetails;
  clinicDetils._id = clinic._id;
  clinicDetils.full_name = clinic.full_name;
  clinicDetils.mobile = clinic.mobile;
  clinicDetils.no_of_staff = clinic.no_of_staff;
  clinicDetils.addresses = [];
  clinicDetils.lat_long = "";
  if (clinic.billboard && clinic.billboard.length > 0)
    clinicDetils.image = clinic.billboard[0].image_path;
  return clinicDetils;
};

type Props = {
  clinic: Clinic;
  generateUploadUrl: GenerateUploadUrl;
  confirmFileUpload: ConfirmFileUpload;
  uploadFile: UploadFile;
  successCallback: Function;
};
const DoctorClinicForm: React.FC<Props> = ({
  clinic,
  uploadFile,
  generateUploadUrl,
  confirmFileUpload,
  successCallback,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<Image>();
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<ClinicDetails>({
    mode: "onChange",
    defaultValues: convertClinicToDetails(clinic),
  });

  const storage = LocalJsonStorage.getInstance();
  const latLongLocation = storage.get("lat-long");

  useEffect(() => {
    setValue("full_name", clinic.full_name);
    setValue("mobile", clinic.mobile);
    setValue("no_of_staff", clinic.no_of_staff);
    setValue("addresses", clinic.addresses);
    setValue("lat_long", latLongLocation);
  }, [clinic]);

  const onSubmit = (data: ClinicDetails) => {
    if (image) {
      data.billboard = image;
      successCallback(data);
    } else {
      setError("image", { type: "required", message: "Image is required" });
    }
  };

  return (
    <>
      <div className="p-4">
        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={error && error.message}
              placeholder="Clinic Name"
              label="Clinic Name"
            />
          )}
          rules={{ required: { value: true, message: "Name is required" } }}
        />

        <Controller
          name="mobile"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              error={error && error.message}
              onChange={onChange}
              placeholder="Mobile No."
              label="Mobile No."
              readOnly={clinic._id? true :false}
        
            />
          )}
          rules={{
            pattern: {
              value: /^[6-9]{1}[0-9]{9}$/,
              message: "Invalid mobile",
            },
            required: { value: true, message: "Required" },
          }}
        />

        <Controller
          name="no_of_staff"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              error={error && error.message}
              onChange={onChange}
              placeholder="No of Staff"
              label="No of Staff"
            />
          )}
          rules={{ required: { value: true, message: "Required" } }}
        />

        <Controller
          name="image"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CameraImageField
              title={"CLICK CLINIC PHOTO BELOW"}
              error={error && error.message}
              uploadFile={uploadFile}
              confirmFileUpload={confirmFileUpload}
              generateUploadUrl={generateUploadUrl}
              successCallback={(image) => {
                setImage(image);
                onChange(image.image_path);
              }}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
      </div>
      {isValid && isDirty && (
        <TonalFixedButton
          loading={loading}
          icon={faSave}
          onClick={handleSubmit(onSubmit)}
          text="Save Details"
        />
      )}
    </>
  );
};

export default DoctorClinicForm;
