import React from "react";
import { Clinic } from "../../../domain/models/clinic";
import Clickable from "../../ga-components/clickable";
import {
  Card,
  CardBody,
  CardColor,
  CardSupportingText,
} from "../../ga-components/cards";
import { ClinicDoctor } from "../../../domain/models/clinic-doctor";
import Icon from "../../ga-components/icon/icon";

type HeaderProps = {
  fullName: string;
  qualifications: string[];
  speciality: string;
  mobile: string;
};
const ClinicDoctorCardHeader: React.FC<HeaderProps> = ({
  fullName,
  qualifications,
  speciality,
  mobile,
}) => {
  return (
    <div className="text-xs">
      <strong className="text-sm text-black">
        {`${fullName} (${qualifications})`}
      </strong>
      <br />
      <strong className="text-gray-500">Speciality In:</strong> {speciality}
      <Icon icon="phone" className="ml-10" /> {mobile}
      <br />
    </div>
  );
};

type Props = {
  clinicDoctor: ClinicDoctor;
};
const ClinicDoctorCard: React.FC<Props> = ({ clinicDoctor }) => {
  return (
    <Card color={CardColor.SECONDARY_LOW} className=" w-full h-20 m-0">
      <CardBody>
        <CardSupportingText className="text-black">
          <strong>
            <ClinicDoctorCardHeader
              fullName={clinicDoctor.full_name}
              qualifications={clinicDoctor.qualifications}
              speciality={clinicDoctor.speciality}
              mobile={clinicDoctor.mobile}
            />
          </strong>
        </CardSupportingText>
      </CardBody>
    </Card>
  );
};

export default ClinicDoctorCard;
