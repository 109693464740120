import React from "react";
import { Clinic } from "../../../domain/models/clinic";
import ClinicCard from "../../components/doctor/clinic-card";

type Props = {
  clinics: Clinic[];
  onItemClick: Function;
};

const DoctorClinicCardList: React.FC<Props> = ({ clinics, onItemClick }) => {
  return (
    <div className="p-2">
      {clinics &&
        clinics.map((c) => (
          <ClinicCard key={c._id} clinic={c} onClick={() => onItemClick(c)} />
        ))}
    </div>
  );
};

export default DoctorClinicCardList;
