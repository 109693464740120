import { Constants } from "../../common/Constants";
import { EmptyResponse, Result } from "../../domain/models/result";
import { UpdateChemistVerification } from "../../domain/usages/update-chemist-verification";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteUpdateChemistVerification
  implements UpdateChemistVerification
{
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async update(
    chemist_id: string,
    params: UpdateChemistVerification.Params
  ): Promise<Result> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":chemist_id", chemist_id),
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: Constants.SERVER_ERROR };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
