import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { HospitalDoctor } from "../../../domain/models/hospital-doctor";
import { Speciality } from "../../../domain/models/speciality";
import TonalFixedButton from "../../components/common/buttons/tonal-fixed-button";
import {
  NumberField,
  SelectField,
  TextField,
} from "../../components/inputs/index";
import { Option } from "../../components/inputs";

type HospitalDoctorDetails = {
  _id: string;
  full_name: string;
  mobile: string;
  email: string;
  speciality: string;
};

const convertHospitalDoctorToDetails = (doctor: HospitalDoctor) => {
  const details: HospitalDoctorDetails = {} as HospitalDoctorDetails;
  if (doctor.contacts && doctor.contacts.length > 0) {
    let mobile = doctor.contacts.find((c) => c.type == "mobile");
    let email = doctor.contacts.find((c) => c.type == "email");
    if (mobile) details.mobile = mobile.value;
    if (email) details.email = email.value;
  }
  details._id = doctor._id;
  details.full_name = doctor.full_name;
  details.speciality = doctor.speciality;
  return details;
};

type Props = {
  doctor: HospitalDoctor;
  successCallback: Function;
  specialities: Speciality[];
};
const HospitalDoctorForm: React.FC<Props> = ({
  doctor,
  specialities,
  successCallback,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<HospitalDoctorDetails>({
    defaultValues: convertHospitalDoctorToDetails(doctor),
  });

  const onSubmit = (data: HospitalDoctorDetails) => {
    successCallback(data);
  };

  return (
    <>
      <div className="p-4">
        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={error && error.message}
              placeholder="Doctor's/ HCP Name"
              label="Doctor's/ HCP Name"
            />
          )}
          rules={{ required: { value: true, message: "Name is required" } }}
        />

        <Controller
          name="speciality"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectField
              value={{ value: value, key: value } as Option}
              placeholder="Doctor's Speciality"
              label="Doctor's Speciality"
              error={error && error.message}
              onChange={(value: any) => onChange(value.value)}
              options={specialities.map(
                (s) => ({ key: s.name, value: s.name } as Option)
              )}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />

        <Controller
          name="mobile"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              error={error && error.message}
              onChange={onChange}
              placeholder="Doctor's/ HCP Mobile No."
              label="Doctor's/ HCP Mobile No."
            />
          )}
          rules={{
            pattern: {
              value: /^[6-9]{1}[0-9]{9}$/,
              message: "Invalid mobile",
            },
          }}
        />

        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              value={value}
              error={error && error.message}
              onChange={onChange}
              placeholder="Doctor's/ HCP Email id"
              label="Doctor's/ HCP Email id"
            />
          )}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email",
            },
          }}
        />
      </div>
      <TonalFixedButton
        icon={faSave}
        onClick={handleSubmit(onSubmit)}
        text="Save Details"
      />
    </>
  );
};

export default HospitalDoctorForm;
