import { useCallback, useEffect, useState } from "react";
import { DoctorMaster } from "../domain/models/doctor-master";
import { Result } from "../domain/models/result";
import { FetchDoctorMasters } from "../domain/usages/fetch-doctor-masters";

const useMasters = (fetchDoctorMasters: FetchDoctorMasters) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [masters, setMasters] = useState<DoctorMaster>();

  /**
   * Fetch masters
   */
  const fetch = useCallback(async () => {
    setLoading(true);
    let result: Result = await fetchDoctorMasters.fetch({});
    setMasters({
      ageGroups: result.ageGroups,
      genders: result.genders,
      qualifications: result.qualifications,
      specialities: result.specialities,
    } as DoctorMaster);
  }, [fetchDoctorMasters]);

  useEffect(() => {
    fetch();
  }, []);

  return [loading, masters] as const;
};

export default useMasters;
