import React, { useEffect, useState, useCallback } from "react";
import { Clinic } from "../../../domain/models/clinic";
import { Button } from "../../ga-components/buttons";
import ButtonColor from "../../ga-components/buttons/button-color";
import { NumberField, TextField, SelectField, Option } from "../inputs/index";
import { Speciality } from "../../../domain/models/speciality";
import Swal from "sweetalert2";
import { Result } from "../../../domain/models/result";
import { Constants } from "../../../common/Constants";
import SweetAlertMessageType from "../../../common/constants-types/sweet-alert-message";
import { AddDoctorListToClinic } from "../../../domain/usages/add-doctor-list-to-clinic";
import { Qualification } from "../../../domain/models/qualification";
import { MultiSelectField } from "../../components/inputs/index";
import { DoctorMaster } from "../../../domain/models/doctor-master";
import Utils from "../../../common/Utils";

type DoctorData = {
  doctorName: string;
  doctorQualification: string[];
  doctorSpeciality: string;
  doctorContact: string;
  [prop: string]: number | string | string[];
};

type Props = {
  clinic: Clinic | undefined;
  addDoctorToClinic: AddDoctorListToClinic;
  qualifications: Qualification[];
  master: DoctorMaster;
  specialities: Speciality[];
  getDoctor: () => void;
};
const AddDoctorToClinicForm: React.FC<Props> = ({
  clinic,
  addDoctorToClinic,
  qualifications,
  master,
  specialities,
  getDoctor,
}) => {
  const [doctorDetails, setDoctorDetails] = useState<Array<DoctorData>>([]);
  const [updateDoctor, setUpdateDoctor] = useState(false);
  const [formErrors, setFormErrors] = useState({
    doctorContact: "",
  });

  const addDoctor = () => {
    doctorDetails.push({
      doctorName: "",
      doctorQualification: [],
      doctorSpeciality: "",
      doctorContact: "",
    });
    setDoctorDetails([...doctorDetails]);
  };

  const createDoctorToClinic = useCallback(async () => {
    let payload = doctorDetails.map((data) => {
      return {
        full_name: data.doctorName,
        qualifications: data.doctorQualification,
        speciality: data.doctorSpeciality,
        mobile: data.doctorContact,
      };
    });
    let result: Result = await addDoctorToClinic.create(clinic?._id, payload);
    if (!result.errors) {
      Swal.fire(
        Constants.DOCTOR_ADDED_TO_CLINIC_SUCCESS,
        "",
        SweetAlertMessageType.SUCCESS
      );
      await getDoctor();
      setDoctorDetails([]);
    } else {
      Swal.fire(
        Constants.DOCTOR_ADDED_TO_CLINIC_FAILED,
        "",
        SweetAlertMessageType.ERROR
      );
    }
  }, [addDoctorToClinic, addDoctor]);

  const handleChangeInput = (index: number, name: string, value: string) => {
    const values = [...doctorDetails];

    values[index][name] = value;
    setDoctorDetails(values);
  };

  const submitHandler = () => {
    doctorDetails.map((data, index) => {
      setFormErrors(validate(doctorDetails, index));
    });
    setUpdateDoctor(true);
  };

  const validate = (values: DoctorData[], index: number) => {
    const errors = {
      doctorContact: "",
    };
    const regexMobileNumber = /[98765]{1}[0-9]{9}/;
    if (!regexMobileNumber.test(values[index].doctorContact)) {
      errors.doctorContact = Constants.MOBILE_NUMBER_VALIDATION_ERROR;
    }
    return errors;
  };

  useEffect(() => {
    if (updateDoctor && Object.keys(formErrors.doctorContact).length === 0) {
      createDoctorToClinic();
    }
  }, [formErrors]);

  return (
    <>
      {doctorDetails.length > 0 && (
        <div>
          <span>
            <strong>Add your Doctors:</strong>
          </span>
        </div>
      )}
      <div className=" grid col-span-4">
        {doctorDetails.map((data, index) => {
          return (
            <div className="grid grid-cols-2 gap-6" key={index}>
              <TextField
                name="doctorName"
                value={data.doctorName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeInput(
                    index,
                    event.target.name,
                    event.target.value
                  )
                }
                placeholder="Doctor Name"
                label="Doctor's Name"
              />
              <div>
                <MultiSelectField
                  name="doctorQualification"
                  value={data.doctorQualification
                    .map((v: any) =>
                      master.qualifications.find((cv: any) => cv.name == v)
                    )
                    .map(
                      (v: any) => ({ key: v.name, value: v.name } as Option)
                    )}
                  placeholder="Select Qualifications"
                  label="Select Qualifications"
                  onChange={(value: any) =>
                    handleChangeInput(
                      index,
                      "doctorQualification",
                      value.map((v: any) => v.value)
                    )
                  }
                  options={qualifications.map(
                    (q) => ({ key: q.name, value: q.name } as Option)
                  )}
                />
              </div>
              <div>
                <SelectField
                  name="doctorSpeciality"
                  label="Select Speciality"
                  value={{ value: data.doctorSpeciality, key: "" } as Option}
                  placeholder="Select Speciality"
                  onChange={(value: any) =>
                    handleChangeInput(index, "doctorSpeciality", value.value)
                  }
                  options={specialities.map(
                    (s) => ({ key: s.name, value: s.name } as Option)
                  )}
                />
              </div>

              <NumberField
                value={data.doctorContact}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeInput(
                    index,
                    event.target.name,
                    event.target.value
                  )
                }
                error={formErrors.doctorContact}
                placeholder="Doctor's Mobile Number"
                label="Doctor's Mobile Number"
                name="doctorContact"
              />
            </div>
          );
        })}
      </div>
      {doctorDetails.length > 0 && (
        <Button
          onClick={submitHandler}
          color={ButtonColor.SECONDARY}
          text="Save Doctor Details"
        />
      )}
      {clinic && Utils.isClinicPending(clinic.verification_status) && (
        <div className=" grid col-span-4 mt-5">
          <Button
            onClick={addDoctor}
            color={ButtonColor.PRIMARY}
            text="Add your Doctors"
          />
        </div>
      )}
    </>
  );
};

export default AddDoctorToClinicForm;
