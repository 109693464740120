import React from "react";
import Typography from "@mui/material/Typography";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import { DoctorData } from "../../../../domain/models/doctor/doctorData";

type Props = {
  doctorDetails: DoctorData;
};

const DoctorHeader: React.FC<Props> = ({ doctorDetails }) => {
  let regex = /(^|\s)\S/g;
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "18px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
            {doctorDetails.fullNames[doctorDetails.fullNames.length - 1].name
              ?.toLowerCase()
              .replace(regex, (letter) => letter.toUpperCase())}
          </Typography>
        </div>
        <Chip
          label="Pending To Validate"
          size="small"
          color="warning"
          sx={{ color: "#F4A261", fontSize: "14px", padding: 1 }}
          variant="outlined"
        />
      </div>
      <Divider />
    </>
  );
};

export default DoctorHeader;
