import React, { useState } from "react";
import { Constants } from "../../../../../common/Constants";
import { Option } from "../../../../components/inputs";
import SelectField from "../../../../ga-components/input/select";
import ModalStatus from "../../../../../domain/enums/modal-status";

type modalType = {
  setModalOn: React.Dispatch<React.SetStateAction<boolean>>;
  successCallback: Function;
  showRejectMessage: boolean;
  reasonsForRejection: string[];
  data: string;
};

const ClinicChemistVerificationModal: React.FC<modalType> = ({
  setModalOn,
  successCallback,
  showRejectMessage,
  reasonsForRejection,
  data,
}) => {
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [fieldEmptyError, setFieldEmptyError] = useState("");
  let status = "";
  let rejectionReason = "";

  const handleClick = () => {
    if (showRejectMessage) {
      if (rejectionMessage == "") {
        setFieldEmptyError("Reason required");
      } else {
        status = ModalStatus.REJECTED;
        rejectionReason = rejectionMessage;
        setModalOn(false);
        successCallback(status, rejectionReason);
      }
    } else {
      status = ModalStatus.VERIFIED;
      setModalOn(false);
      successCallback(status, rejectionReason);
    }
  };
  const handleCancelClick = () => {
    setModalOn(false);
  };

  return (
    <div className="bg-zinc-200 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div className="flex-col justify-center  bg-white py-12 px-24 border-4 border-sky-500 rounded-xl ">
          <div className="flex  text-lg  text-zinc-600   mb-10">
            {showRejectMessage ? (
              <>
                <span className="mr-2">
                  Select Reason to Reject
                  <strong> {`${data}`}</strong>:
                </span>
                <div className="w-60">
                  <SelectField
                    label=""
                    valueData={rejectionMessage}
                    name=" rejectReason"
                    placeholder="Select Reason"
                    options={reasonsForRejection.map(
                      (reason) => ({ key: reason, value: reason } as Option)
                    )}
                    onChange={(value: any) => setRejectionMessage(value)}
                  />
                  <span className="text-red-500 text-xs">
                    {fieldEmptyError}
                  </span>
                </div>
              </>
            ) : (
              <span>
                Are you sure you want to mark
                <strong> {`${data}`}</strong> as approved
              </span>
            )}
          </div>
          <div className="flex">
            <button
              onClick={handleClick}
              className=" rounded px-4 py-2 text-white  bg-green-400 "
            >
              Ok
            </button>

            <button
              onClick={handleCancelClick}
              className="rounded px-4 py-2 ml-4 text-white bg-blue-500 "
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicChemistVerificationModal;
