import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FetchBatchSizes } from "../../../../domain/usages/data-entry/fetch-batch-sizes";
import { BatchSize } from "../../../../domain/models/data-entry/batchSize";
import { LoadingButton } from "@mui/lab";
import { CreateBatch } from "../../../../domain/usages/data-entry/create-batch";
import Swal from "sweetalert2";

type Props = {
  open: boolean;
  handleClose: Function;
  fetchBatchSizes: FetchBatchSizes;
  createBatch: CreateBatch;
  reloadList: Function;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SelectBatchSizeModal: React.FC<Props> = ({
  open,
  handleClose,
  fetchBatchSizes,
  createBatch,
  reloadList,
}) => {
  const [batchSizes, setBatchSizes] = useState<BatchSize[]>([]);
  const [fetchingBatchesLoading, setFetchingBatchesLoading] = useState(true);
  const [createBatchLoading, setCreateBatchLoading] = useState(false);
  const [value, setValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const getBatches = async () => {
    setFetchingBatchesLoading(true);
    let result = await fetchBatchSizes.fetch();
    if (result.success) {
      setBatchSizes(result.data.sizes);
      setFetchingBatchesLoading(false);
    }
  };

  const onSubmit = async () => {
    setCreateBatchLoading(true);
    let payload = {
      batchSize: parseInt(value),
      type: "data_entry_basic",
    };
    console.log(payload, "payload");
    let result = await createBatch.create(payload);
    if (result.success) {
      setCreateBatchLoading(false);
      Swal.fire({
        title: "Batch created successfully 😃",
        icon: "success",
        allowOutsideClick: false,
        timer: 2000,
        customClass: {
          container: "swal2-style",
        },
      }).then((response) => {
        window.location.reload();
        reloadList();
      });
    } else {
      setCreateBatchLoading(false);
      if (result.errors) {
        if (
          typeof result.errors.message === "object" &&
          result.errors.message !== null
        ) {
          Swal.fire({
            icon: "error",
            title: result.errors.message.message,
            customClass: {
              container: "swal2-style",
            },
          });
        } else if (Array.isArray(result.errors)) {
          let errorText = "";
          for (let x in result.errors) {
            errorText += result.errors[x] + "\n";
          }
          Swal.fire({
            icon: "error",
            title: errorText,
            customClass: {
              container: "swal2-style",
            },
          });
        } else {
          Swal.fire({
            icon: "error",
            title: result.errors.message,
            customClass: {
              container: "swal2-style",
            },
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
          customClass: {
            container: "swal2-style",
          },
        });
      }
    }
  };

  useEffect(() => {
    getBatches();
  }, []);
  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={style}>
        {!fetchingBatchesLoading ? (
          <Stack spacing={2}>
            <Typography variant="h6">Select Batch</Typography>
            <FormControl>
              <FormLabel>
                <Typography variant="subtitle2">
                  Choose how many doctors you want to verify ?
                </Typography>
              </FormLabel>
              <RadioGroup
                value={value}
                onChange={handleChange}
                row
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr", // Divide into two equal columns
                  gap: "10px", // Add some gap between the columns
                }}
              >
                {batchSizes.length > 0 &&
                  batchSizes.map((batch) => {
                    return (
                      <FormControlLabel
                        value={batch.size}
                        control={<Radio />}
                        label={batch.message}
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>
            <LoadingButton
              color="warning"
              variant="contained"
              disabled={value === ""}
              loading={createBatchLoading}
              onClick={onSubmit}
              loadingPosition="start"
            >
              <Typography color={"white"} textTransform={"none"}>
                Create Batch
              </Typography>
            </LoadingButton>
          </Stack>
        ) : (
          <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
            <CircularProgress color="warning" />
            <Typography variant="subtitle2">
              Fetching Data. Please Wait...
            </Typography>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default SelectBatchSizeModal;
