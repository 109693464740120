import {
  AUTH_HEADER,
  AUTH_TOKEN,
  HOSPITAL_DOCTOR_SERVICE_API_URL,
} from "../base";
import { RemoteCreateHospital } from "../data/usecases/remote-create-hospital";
import { RemoteFetchHospitalsNearMe } from "../data/usecases/remote-fetch-hospitals-near-me";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import CreateHospitalPage from "../presentation/pages/create-hospital-page";

type Props = {
  onSelect: Function;
  selected?: number;
};

export const CreateHospitalFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: `Bearer ${atob(token)}` });

  const remoteCreateHospital = new RemoteCreateHospital(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals`,
    axiosHttpClient
  );

  const remoteFetchHospitalsNearMe = new RemoteFetchHospitalsNearMe(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/geo-locations/hospitals/near-me`,
    axiosHttpClient
  );
  return (
    <CreateHospitalPage
      fetchHospitalNearMe={remoteFetchHospitalsNearMe}
      createHospital={remoteCreateHospital}
      {...props}
    />
  );
};
