import { faPlus, faUserNurse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DoctorStatus from "../../domain/enums/doctor-status";
import { Doctor } from "../../domain/models/doctor";
import { DoctorSearch } from "../../domain/models/doctor-search";
import { Result } from "../../domain/models/result";
import { AddDoctorForSampling } from "../../domain/usages/add-doctor-for-sampling";
import { FetchDoctorList } from "../../domain/usages/fetch-doctor-list";
import { RemoveDoctorFromSampling } from "../../domain/usages/remove-doctor-from-sampling";
import { pageRoutes } from "../../routes";
import FabButton from "../components/common/buttons/fab-button";
import PageLoading from "../components/common/page-loading";
import AppBar from "../components/common/sections/app-bar";
import DoctorFilter, {
  DoctorSearchDetails,
} from "../components/doctor/DoctorFilter";
import DoctorList from "../pages/list/doctor-list";

type Props = {
  fetchDoctorList: FetchDoctorList;
  addDoctorForSampling: AddDoctorForSampling;
  removeDoctorFromSampling: RemoveDoctorFromSampling;
};

const DoctorPage: React.FC<Props> = ({
  fetchDoctorList,
  addDoctorForSampling,
  removeDoctorFromSampling,
}) => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchDoctor, setSearchDoctor] = useState<DoctorSearch>(
    {} as DoctorSearch
  );
  const navigate = useNavigate();

  const fetchDoctors = useCallback(async () => {
    setLoading(true);
    let result: Result = await fetchDoctorList.fetch(searchDoctor);
    setDoctors(result.doctors);
    setTotalRecords(result.totalRecords);
    setLoading(false);
  }, [fetchDoctorList]);

  useEffect(() => {
    fetchDoctors();
  }, []);

  const onSuccessCallback = () => {
    fetchDoctors();
  };

  const goToDoctorProfile = (id: string) => {
    navigate("/home/" + pageRoutes.doctorProfile.replace(":id", id));
  };

  const goToCreateDoctor = () => {
    navigate("/home/" + pageRoutes.createDoctor);
  };

  if (loading && doctors && doctors.length == 0) {
    return <PageLoading />;
  }

  const handleSearch = (value: any) => {
    searchDoctor.status = value.status.value;
    setSearchDoctor(searchDoctor);
    fetchDoctors();
  };

  return (
    <div className="md:container md:mx-auto md:px-5 h-full sm:w-screen">
      <AppBar
        icon={<FontAwesomeIcon icon={faUserNurse} className="text-primary" />}
        title={
          <span className="text-primary font-bold drop-shadow-2xl">
            DOCTORS
          </span>
        }
      />
      <div className="pl-5 w-60">
        <DoctorFilter
          onSubmit={(value: DoctorSearchDetails) => handleSearch(value)}
          statusOptions={[
            DoctorStatus.PENDING,
            DoctorStatus.VERIFIED,
            DoctorStatus.REJECTED,
          ]}
        ></DoctorFilter>
      </div>
      <DoctorList
        doctors={doctors}
        addDoctorForSampling={addDoctorForSampling}
        removeDoctorFromSampling={removeDoctorFromSampling}
        onItemClick={goToDoctorProfile}
        successCallback={() => onSuccessCallback()}
      />
      <FabButton text="Create" icon={faPlus} onClick={goToCreateDoctor} />
    </div>
  );
};

export default DoctorPage;
