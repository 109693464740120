import { faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";
import Camera from "react-html5-camera-photo";
import { Image } from "../../../domain/models/image";
import { Result } from "../../../domain/models/result";
import { ConfirmFileUpload } from "../../../domain/usages/confirm-file-upload";
import { GenerateUploadUrl } from "../../../domain/usages/generate-upload-url";
import { UploadFile } from "../../../domain/usages/upload-file";
import IconButton from "../common/buttons/icon-button";
import TonalButton from "../common/buttons/tonal-button";
import { InputVariant } from "../inputs";
import InputError from "../inputs/input-error";

function urltoFile(url: string, filename: string) {
  const mimeType = (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

type Props = {
  title: string;
  error: string | undefined;
  generateUploadUrl: GenerateUploadUrl;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmFileUpload;
  successCallback: (image: Image) => void;
};

const CameraImageField: React.FC<Props> = ({
  title,
  error,
  generateUploadUrl,
  confirmFileUpload,
  uploadFile,
  successCallback,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [file, setFile] = useState<File>();
  const [dataUri, setDataUri] = useState<string>();
  const [uploaded, setUploaded] = useState<boolean>(false);

  const requestUrl = useCallback(async () => {
    if (!file) return;
    setLoading(true);
    let result: Result = await generateUploadUrl.generate({
      name: file.name,
      size: file.size,
      type: file.type,
    });
    if (result.url) {
      upload(result.url, result.uuid);
    } else {
      setLoading(false);
      setErrorMessage(result.errors.message);
    }
  }, [generateUploadUrl, file]);

  const upload = useCallback(
    async (url, uuid) => {
      if (!file) return;
      let result: Result = await uploadFile.upload({
        file: file,
        url: url,
      });
      console.log(result);
      if (result) {
        confirm(url, uuid);
      } else {
        setLoading(false);
        setErrorMessage("Something went wrong, Please try after sometime.");
      }
    },
    [uploadFile, file]
  );

  const confirm = useCallback(
    async (url, uuid) => {
      if (!file) return;
      let result: Result = await confirmFileUpload.confirm({
        uuid: uuid,
      });
      if (result.success) {
        setLoading(false);
        successCallback({
          image_path: url,
          image_size: file.size.toString(),
          image_type: file.type,
          uuid: uuid,
        } as Image);
        setUploaded(true);
      } else {
        setLoading(false);
        setErrorMessage(result.errors.message);
      }
    },
    [uploadFile, file]
  );

  const handleTakePhoto = (dataUri: any) => {
    setDataUri(dataUri);
    urltoFile(dataUri, `doctor-clinic-image-${+new Date()}.png`).then(function (
      file
    ) {
      setFile(file);
    });
  };

  return (
    <div className="p-2">
      <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden md:max-w-lg">
        <div className="md:flex">
          <div className="w-full">
            <div className="p-4">
              <span className="text-lg font-bold text-gray-600">
                {title}
                {error && (
                  <InputError message={error} variant={InputVariant.FILLED} />
                )}
              </span>
            </div>
            <div className="">
              <div className="mb-2">
                <div className="rounded-lg bg-white justify-center items-center">
                  <div className="w-full">
                    {!file && (
                      <Camera
                        idealFacingMode="environment"
                        isImageMirror={false}
                        onTakePhoto={(dataUri) => {
                          handleTakePhoto(dataUri);
                        }}
                      />
                    )}
                    {file && dataUri && (
                      <div className="w-full relative">
                        <img src={dataUri} className="" />
                        <div className="absolute top-2 right-2 bg-red-900">
                          <IconButton
                            text=""
                            icon={faTimes}
                            primary={false}
                            size="sm"
                            onClick={() => {
                              setDataUri(undefined);
                              setFile(undefined);
                              setUploaded(false);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="justify-between items-center text-gray-400">
                  <span>Accepted file type:.png, .jpeg only</span>
                </div>
              </div>
              {file && !uploaded && (
                <div className="mt-3 text-center pb-3">
                  <TonalButton
                    text="Upload"
                    loading={loading}
                    icon={faUpload}
                    onClick={() => requestUrl()}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraImageField;
