import withTheme from "../../../ga-components/theme/withTheme";

export enum LabelTypes {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  WHITE = "white",
}

export enum LabelSize {
  xs = "xs",
  sm = "sm",
  base = "base",
  lg = "lg",
  xl = "xl",
  xl2 = "xl2",
}

type Props = {
  theme: any;
  text: string;
  type: LabelTypes;
  size?: LabelSize;
  className?: string;
};

const Label: React.FC<Props> = ({
  theme,
  text,
  type,
  size = LabelSize.xs,
  className,
}) => {
  const classes = [className];
  classes.push(theme.text[type]);
  classes.push(theme.size[size]);
  return <label className={`truncate px-2 ${classes.join(" ")}`}>{text}</label>;
};

export default withTheme(Label);
