import React from "react";
import withTheme from "../../../ga-components/theme/withTheme";
import Tab, { TabItem, TabType } from "./tab";

type TabsProps = {
  theme: any;
  items: TabItem[];
  setSelectedTabId: Function;
  selectedItemId: number;
};
const Tabs: React.FC<TabsProps> = ({
  theme,
  items,
  selectedItemId,
  setSelectedTabId,
}) => {
  return (
    <div className="w-full h-70 h-16 flex justify-center">
      <ul className="flex w-full">
        {items.map((item) => (
          <Tab
            key={item.id}
            item={item}
            type={selectedItemId === item.id ? TabType.PRIMARY : TabType.WHITE}
            onClick={setSelectedTabId}
          />
        ))}
      </ul>
    </div>
  );
};

export default withTheme(Tabs);
