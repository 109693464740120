import { stringify } from "querystring";
import React, { useState } from "react";
import ModalStatus from "../../../../../domain/enums/modal-status";
import { OrganizationCode } from "../../../../../domain/models/organizationCode";
import { SamplingVerification } from "../../../../../domain/models/sampling_verification";
import { Option } from "../../../../components/inputs";
import SelectField from "../../../../ga-components/input/select";

type modalType = {
  setModalOn: React.Dispatch<React.SetStateAction<boolean>>;
  successCallback: Function;
  doctorName: string;
  showRejectMessage: boolean;
  organizationCodes: OrganizationCode[];
  reasonsForRejection: string[];
  columns: string[];
  rows: SamplingVerification[];
};
const OrgCodeSelectionModal: React.FC<modalType> = ({
  setModalOn,
  successCallback,
  doctorName,
  showRejectMessage,
  organizationCodes,
  reasonsForRejection,
  columns,
  rows,
}) => {
  const [orgCode, setOrgCode] = useState({ value: "", label: "" });
  const [fieldEmptyError, setFieldEmptyError] = useState("");
  const [rejectionMessage, setRejectionMessage] = useState({
    value: "",
    label: "",
  });

  let status;
  let SamplingOrgCode = "";
  let rejectionReason = {};
  const onAccept = () => {
    if (showRejectMessage) {
      if (orgCode.value == "" || rejectionMessage.value == "") {
        setFieldEmptyError("Field Required");
      } else {
        status = false;
        SamplingOrgCode = orgCode.value;
        rejectionReason = rejectionMessage.value;
        setModalOn(false);
        successCallback(status, SamplingOrgCode, rejectionReason);
      }
    } else {
      if (orgCode.value == "") {
        setFieldEmptyError("Field Required");
      } else {
        status = true;
        SamplingOrgCode = orgCode.value;
        rejectionReason = rejectionMessage.value;
        setModalOn(false);
        successCallback(status, SamplingOrgCode, rejectionReason);
      }
    }
  };
  const onCancel = () => {
    setModalOn(false);
  };

  return (
    <div className="bg-zinc-200 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div className="flex-col justify-center  bg-white py-12 px-24 border-4 border-sky-500 rounded-xl ">
          <div className="text-lg  text-zinc-600   mb-10">
            <>
              <span className="mr-2">
                Select Organization code for
                <strong> {`${doctorName}`}</strong>:
              </span>
              <div className="w-60">
                <SelectField
                  label=""
                  valueData={orgCode}
                  name=" organizationCode"
                  placeholder="Select org_code"
                  options={organizationCodes.map(
                    (code) => ({ key: code.name, value: code.name } as Option)
                  )}
                  onChange={(value: any) => setOrgCode(value)}
                />
                <span className="text-red-500 text-xs">{fieldEmptyError}</span>
              </div>
              {showRejectMessage && (
                <>
                  <span className="mr-2">
                    Select Reason to Reject Sampling for
                    <strong> {`${doctorName}`}</strong>:
                  </span>
                  <div className="w-60">
                    <SelectField
                      label=""
                      valueData={rejectionMessage}
                      name=" rejectReason"
                      placeholder="Select Reason"
                      options={reasonsForRejection.map(
                        (reason) => ({ key: reason, value: reason } as Option)
                      )}
                      onChange={(value: any) => setRejectionMessage(value)}
                    />
                    <span className="text-red-500 text-xs">
                      {fieldEmptyError}
                    </span>
                  </div>
                </>
              )}
            </>
          </div>
          <div className="flex">
            <button
              onClick={onAccept}
              className=" rounded px-4 py-2 text-white  bg-green-400 "
            >
              Ok
            </button>

            <button
              onClick={onCancel}
              className="rounded px-4 py-2 ml-4 text-white bg-blue-500 "
            >
              Cancel
            </button>
          </div>
          <table className="mt-10">
            <tr>
              {columns &&
                rows &&
                columns.map((column) => {
                  return <th className="px-10">{column}</th>;
                })}
            </tr>

            {rows &&
              rows.map((row) => {
                return (
                  <tr className="text-center">
                    <td>{row.org_code}</td>
                    <td
                      className={`${
                        row.sampling_data.sample_verification_flag
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {row.sampling_data.sample_verification_flag.toString()}
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrgCodeSelectionModal;
