import React from "react";
import { Chemist } from "../../../domain/models/chemist";
import ChemistCard from "../../components/doctor/chemist-card";

type Props = {
  chemists: Chemist[];
  onItemClick: Function;
};

const DoctorClinicCardList: React.FC<Props> = ({ chemists, onItemClick }) => {
  return (
    <div className="p-2">
      {chemists &&
        chemists.map((c) => (
          <ChemistCard key={c._id} chemist={c} onClick={() => onItemClick(c)} />
        ))}
    </div>
  );
};

export default DoctorClinicCardList;
