import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type Props = {
  text: any;
  icon: any;
  onClick: Function;
  size: SizeProp;
  primary?: boolean;
};
const IconButton: React.FC<Props> = ({
  text,
  icon,
  size,
  primary = true,
  onClick,
}) => {
  if (primary) {
    return (
      <button
        className="text-center h-10 px-4 rounded-full"
        onClick={() => onClick()}
      >
        {icon && (
          <span className="w-6 h-6">
            <FontAwesomeIcon icon={icon} className="text-primary" size={size} />
          </span>
        )}
      </button>
    );
  } else {
    return (
      <button
        className="text-center h-10 px-4 rounded-full "
        onClick={() => onClick()}
      >
        {icon && (
          <span className="w-6 h-6">
            <FontAwesomeIcon
              icon={icon}
              className="text-secondary"
              size={size}
            />
          </span>
        )}
      </button>
    );
  }
};

export default IconButton;
