import React from "react";
import { Hospital } from "../../../domain/models/hospital";
import HospitalCard from "../../components/hospital/hospital-card";

type Props = {
  hospitals: Hospital[];
  onItemClick: Function;
  showIconFields?: boolean;
};

const HospitalList: React.FC<Props> = ({ hospitals, onItemClick, showIconFields=true}) => {
  return (
    <div className="p-2">
      {hospitals &&
        hospitals.map((h, index) => (
          <HospitalCard
            key={h._id}
            hospital={h}
            showIconFields={showIconFields}
            onClick={() => onItemClick(h._id)}
          />
        ))}
    </div>
  );
};

export default HospitalList;
