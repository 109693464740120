const theme = {
  header: {
    container: "p-4 md:py-8 xl:px-0",
    base: "lg:flex lg:justify-between lg:items-center",
    logo: {
      base: "flex items-start gap-2 group",
      icon: "bg-primary text-white p-2 rounded-md group-hover:bg-blue-800",
      title: "text-sm font-light",
      boldTitle: "text-base block font-bold tracking-wide",
    },
  },
  section: {
    title:
      "text-xs md:text-sm mb-2 text-gray-700 font-bold tracking-wide md:tracking-wider",
  },

  heading: {
    title:
      "text-xs md:text-sm mb-2 text-gray-700 font-bold tracking-wide md:tracking-wider",
    doctorName:
      "text-xs md:text-sm mb-2 text-gray-700 tracking-wide md:tracking-wider",
  },
  nav: {
    account: "flex items-center gap-6",
    base: "flex items-center space-x-4 text-sm font-semibold",
    item: {
      base: "p-2 xl:px-4 rounded-md hover:bg-gray-200",
      active: "text-gray-800",
      inActive: "text-gray-600",
    },
  },
  tab: {
    base: "flex items-center my-4",
    item: {
      base: "cursor-pointer py-2 px-4 text-gray-500 border-b-2",
      active: "text-primary border-primary",
      inActive: "",
    },
  },
  card: {
    base: "card m-1",
    body: "card-body",
    headline: "text-4xl",
    subHeadline: "text-base",
    supportingText: "text-xs text-gray-500 font-light",
    cardRightContent: "card-right-content",
    actions: "py-4 w-full",
    tags: "p-4 w-full border-t border-gray-100",
    image: "bg-cover bg-center",
  },
  input: {
    primary: {
      field:
        "bg-primary_low pl-4 pr-3 h-12 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-primary_medium",
      select:
        "appearance-none bg-primary_low h-12 w-full h-full rounded-md focus:outline-none focus:ring-2 focus:ring-primary_medium",
      errorField:
        "bg-primary_low pl-4 pr-3 h-12 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-red-900",
      label: "text-xs font-semibold text-primary",
      error: "text-xs text-red-900",
      helpText: "text-xs text-gray-400",
    },
    error: "text-xs text-red-900",
  },
  size: {
    xs: "text-xs",
    sm: "text-sm",
    base: "text-base",
    lg: "text-lg",
    xl: "text-xl",
    xl2: "text-2xl",
  },
  bg: {
    primary: "bg-primary",
    primaryMedium: "bg-primary_medium",
    primaryLow: "bg-primary_low",
    secondary: "bg-secondary",
    secondaryMedium: "bg-secondary_medium",
    secondaryLow: "bg-secondary_low",
    white: "bg-white",
    error: "bg-red-500",
    success: "bg-green-500",
  },
  text: {
    primary: "text-primary",
    primaryMedium: "text-primary_medium",
    primaryLow: "text-primary_low",
    secondary: "text-secondary",
    secondaryMedium: "text-secondary_medium",
    secondaryLow: "text-secondary_low",
    white: "text-white",
  },
  chip: {
    base: "chip text-sm",
    suggestion: "border border-gray-400",
    filter: "",
    text: "px-2 leading-5",
    colors: {
      primary: "bg-primary text-white",
      primaryMedium: "bg-primary_medium",
      primaryLow: "bg-primary_low text-primary",
      secondary: "bg-secondary text-white",
      secondaryMedium: "bg-secondary_medium",
      secondaryLow: "bg-secondary_low text-secondary",
      white: "bg-white text-gray-800",
      gray: "bg-gray-200",
      danger: "bg-red-200",
    },
  },
  button: {
    base: "rounded-full pr-6 pl-4 h-10 text-sm",
    name: "pl-2",
    icon: "w-4",
    elevated: "drop-shadow",
    filled: "",
    tonal: "",
    outlined: "border",
    text: "",
    colors: {
      primary: "bg-primary text-white",
      primaryMedium: "bg-primary_medium",
      primaryLow: "bg-primary_low text-primary",
      secondary: "bg-secondary text-white",
      secondaryMedium: "bg-secondary_medium",
      secondaryLow: "bg-secondary_low text-secondary",
      white: "bg-white text-primary",
      error: "bg-red-500",
      success: "bg-green-500",
    },
  },
};

export default theme;
