import React, { useState } from "react";
import { Chemist } from "../../../domain/models/chemist";
import { Clinic } from "../../../domain/models/clinic";
import { Doctor } from "../../../domain/models/doctor";
import { FetchDoctorList } from "../../../domain/usages/fetch-doctor-list";
import { FetchDoctorMasters } from "../../../domain/usages/fetch-doctor-masters";
import AppHeader from "../../components/app-header";
import ListHandler from "../../components/common/list/ListHandler";
import Clickable from "../../ga-components/clickable";
import { Icon, IconColor } from "../../ga-components/icon";
import { Tab, TabModel, Tabs } from "../../ga-components/tabs";
import SectionTitle from "../../ga-components/typography/section-title";
import {
  DoctorList,
  ClinicList,
  ChemistList,
} from "../../pages/sections/doctor";
import useDoctors from "../../useDoctors";
import useMasters from "../../useMasters";
import DoctorsFilterForm from "../sections/doctor/doctors-filter-form";

const tabItems = ["Clinics", "Chemists"];

type Props = {
  fetchDoctorList: FetchDoctorList;
  fetchMasters: FetchDoctorMasters;
};

const DataEntryAdminDashboardPage: React.FC<Props> = ({
  fetchDoctorList,
  fetchMasters,
}) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [doctor, setDoctor] = useState<Doctor>();
  const [clinic, setClinic] = useState<Clinic>();
  const [chemist, setChemist] = useState<Chemist>();
  const [fetchingDoctors, doctors, total, setFilters] =
    useDoctors(fetchDoctorList);
  const [fetchingMasters, masters] = useMasters(fetchMasters);
  const [tab, setTab] = useState<string>(tabItems[0]);

  return (
    <>
      <AppHeader></AppHeader>
      <div className="mx-w-7xl mx-auto py-4 h-full md:mx-4">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-8 px-4 xl:p-0 gap-4 h-full">
          <div className="md:col-span-1 lg:col-span-2 h-full overflow-scroll">
            <>
              <div className="grid grid-cols-1 gap-4">
                <div className="flex justify-between">
                  <SectionTitle title={`Doctors (${total})`} />
                  <div>
                    <Clickable onClick={() => setShowFilter(!showFilter)}>
                      {!showFilter && (
                        <Icon icon="filter" color={IconColor.PRIMARY} />
                      )}
                      {showFilter && (
                        <Icon icon="cancel" color={IconColor.SECONDARY} />
                      )}
                    </Clickable>
                  </div>
                </div>
              </div>
              {showFilter && masters && (
                <div className="grid grid-cols-1 gap-4 mb-4">
                  <DoctorsFilterForm
                    onSubmit={setFilters}
                    qualifications={masters.qualifications}
                    specialities={masters.specialities}
                  />
                </div>
              )}
              <div className="grid grid-cols-1 gap-4 h-full overflow-scroll">
                <ListHandler
                  total={total}
                  list={doctors}
                  loading={fetchingDoctors}
                >
                  <DoctorList
                    doctors={doctors}
                    selectedDoctor={doctor}
                    onItemClick={(doctor: Doctor) => setDoctor(doctor)}
                  />
                </ListHandler>
              </div>
            </>
          </div>
          {doctor && (
            <div className="md:col-span-3 lg:col-span-6 h-full">
              <div>
                <Tabs>
                  {tabItems.map((t) => (
                    <Tab
                      item={
                        {
                          text: t,
                        } as TabModel
                      }
                      active={tab == t}
                      onClick={() => setTab(t)}
                    />
                  ))}
                </Tabs>
                {tab == "Clinics" && (
                  <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-8 px-4 xl:p-0 gap-4 h-full">
                    <ListHandler
                      total={doctor.clinics.length}
                      list={doctor.clinics}
                      loading={false}
                    >
                      <ClinicList
                        clinics={doctor.clinics}
                        selectedClinic={clinic}
                        onItemClick={setClinic}
                      />
                    </ListHandler>
                  </div>
                )}
                {tab == "Chemists" && (
                  <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-8 px-4 xl:p-0 gap-4 h-full">
                    <ListHandler
                      total={doctor.chemists.length}
                      list={doctor.chemists}
                      loading={false}
                    >
                      <ChemistList
                        clinics={doctor.chemists}
                        selectedChemist={chemist}
                        onItemClick={setChemist}
                      />
                    </ListHandler>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DataEntryAdminDashboardPage;
