import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React, {useState } from "react";
import { Icon, IconColor, IconSize } from "./icon";
import Clickable from "./clickable";

type Props = {
  imageLink: any;
  setRotateImage: React.Dispatch<React.SetStateAction<number>>;
  rotateImage: number;
};
const ImageDisplay: React.FC<Props> = ({
  imageLink,
  setRotateImage,
  rotateImage,
}) => {
  const [flip, setFlip] = useState(false);

  const rotate = () => {
    if (rotateImage < 360) {
      setRotateImage(rotateImage + 90);
    } else {
      setRotateImage(90);
    }
  };

  return (
    <TransformWrapper initialScale={1}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <TransformComponent>
            <div
              style={
                flip ? { transform: "scaleX(-1)" } : { transform: "scaleX(1)" }
              }
            >
              <img
                style={{ transform: `rotate(${rotateImage}deg)` }}
                className={"mt-10 h-96 mb-5"}
                src={imageLink}
                alt=""
              ></img>
            </div>
          </TransformComponent>
          <div>
            <div className=" flex border divide-x place-content-center">
              <div className="p-2">
                <Clickable
                  onClick={() => {
                    setFlip(!flip);
                  }}
                >
                  <span className="border rounded-lg text-xs p-2 bg-gray-500 text-white cursor-pointer">
                    FLIP IMAGE
                  </span>
                </Clickable>
              </div>
              <div className="p-2 cursor-pointer">
                <Clickable onClick={() => zoomIn()}>
                  <Icon
                    icon="zoomIn"
                    color={IconColor.PRIMARY}
                    size={IconSize.xl2}
                  />
                </Clickable>
              </div>
              <div className="p-2 cursor-pointer">
                <Clickable onClick={() => zoomOut()}>
                  <Icon
                    icon="zoomOut"
                    color={IconColor.PRIMARY}
                    size={IconSize.xl2}
                  />
                </Clickable>
              </div>
              <div className="p-2 cursor-pointer">
                <Clickable onClick={rotate}>
                  <Icon
                    icon="rotate"
                    color={IconColor.PRIMARY}
                    size={IconSize.xl2}
                  />
                </Clickable>
              </div>
              <div className="p-2 cursor-pointer">
                <Clickable onClick={() => resetTransform()}>
                  <Icon
                    icon="reset"
                    color={IconColor.PRIMARY}
                    size={IconSize.xl2}
                  />
                </Clickable>
              </div>
              <div className=" p-2 cursor-pointer">
                <a href={imageLink} target="_blank">
                  <Icon
                    icon="expand"
                    color={IconColor.PRIMARY}
                    size={IconSize.xl2}
                  />
                </a>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};

export default ImageDisplay;
