import { Constants } from "../../../../common/Constants";
import { EmptyResponse } from "../../../../domain/models/result";
import { VerifyDoctorSpeciality } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-speciality";
import { HttpConstants } from "../../../protocols/http/http-constants";
import { HttpPostClient } from "../../../protocols/http/http-post-client";

export class RemoteVerifyDoctorSpeciality implements VerifyDoctorSpeciality {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async verify(
    id: string,
    specialityId: string,
    params?: VerifyDoctorSpeciality.Params
  ): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url.replace(":id", id).replace(":specialityId", specialityId),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: Constants.HTTP_ERROR_MESSAGE };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
