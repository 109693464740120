import React, { useReducer } from "react";
import { User } from "./domain/models/user";

export enum LoginAction {
  SET_TOKEN = "SET_TOKEN",
  LOGOUT = "LOGOUT",
}

type Action = {
  type: LoginAction;
  payload: State;
};

type State = {
  token: string | null;
  user: User | null;
};

type UserContext = {
  state: State;
  dispatch: Function;
};

const AppUserContext = React.createContext<UserContext>({
  state: { token: null, user: null },
  dispatch: () => {},
});
const authReducer = (state: State, action: Action): State => {
  const { type, payload } = action;
  switch (type) {
    case LoginAction.SET_TOKEN:
      return {
        ...state,
        token: payload.token,
        user: payload.user,
      } as State;
    case LoginAction.LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
      } as State;
    default:
      return state;
  }
};

const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    token: null,
    user: null,
  });

  return (
    <AppUserContext.Provider value={{ state, dispatch }}>
      {children}
    </AppUserContext.Provider>
  );
};

export { AppUserContext, AuthProvider };
