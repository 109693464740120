import React from "react";
import withTheme from "../../../ga-components/theme/withTheme";
import ListItem, { ListItemModel, ListItemType } from "./list-item";

type Props = {
  theme: any;
  items: ListItemModel[];
  className?: string;
  onItemClick: Function;
};

const List: React.FC<Props> = ({
  items,
  className = "",
  onItemClick,
}) => {
  return (
    <ul className={`p-4 ${className}`}>
      {items.map((item) => (
        <ListItem
          key={item.id}
          item={item}
          type={ListItemType.PRIMARY}
          onClick={onItemClick}
        />
      ))}
    </ul>
  );
};

export default withTheme(List);
