import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoggedInUser } from "../../domain/usages/logged-in-user";
import { pageRoutes } from "../../routes";
import { AppUserContext, LoginAction } from "../../UserContext";

type Props = {
  authToken: string;
  loggedInUser: LoggedInUser;
};

const AuthenticatePage: React.FC<Props> = React.memo(
  ({ loggedInUser, authToken }) => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(AppUserContext);
    const [location, setLocation] = useState<GeolocationCoordinates>();
    const [isLocationAllowed, setisLocationAllowed] = useState(true);

    useEffect(() => {
      const { geolocation } = navigator;

      if (!geolocation) {
        console.log("Geolocation is not supported.");
        return;
      }

      geolocation.getCurrentPosition(
        (position) => {
          setisLocationAllowed(true);
          setLocation(position.coords);
          localStorage.setItem("latitude", position.coords.latitude.toString());
          localStorage.setItem(
            "longitude",
            position.coords.longitude.toString()
          );
        },
        (error) => {
          setisLocationAllowed(false);
        },
        {}
      );
    }, []);

    useEffect(() => {
      loggedInUser.setToken(authToken);
      dispatch({ type: LoginAction.SET_TOKEN, payload: authToken });
      navigate("/home/" + pageRoutes.dashboard);
    }, [state.token]);

    return (
      <div className="container h-full flex flex-col">
        <div className="flex-grow"></div>
        <div className="flex-grow-0">
          {isLocationAllowed && (
            <h3 className="text-center text-indigo-800 text-3xl">
              Please wait while we are cooking something special for you...
            </h3>
          )}
          {!isLocationAllowed && (
            <h3 className="text-center text-indigo-800 text-3xl">
              Kindly allow location access if you want to do the customer
              profiling.
            </h3>
          )}
        </div>
        <div className="flex-grow"></div>
      </div>
    );
  }
);

export default AuthenticatePage;
