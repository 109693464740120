import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Hospital } from "../../../domain/models/hospital";
import { HospitalDoctor } from "../../../domain/models/hospital-doctor";
import { Result } from "../../../domain/models/result";
import { Speciality } from "../../../domain/models/speciality";
import { AddHospitalDoctor } from "../../../domain/usages/add-hospital-doctor";
import { UpdateHospitalDoctor } from "../../../domain/usages/update-hospital-doctor";
import HospitalDoctorForm from "../sections/hospital-doctor-form";
import HospitalDoctorList from "../list/hospital-doctor-list";
import TonalButton from "../../components/common/buttons/tonal-button";
import TonalFixedButton from "../../components/common/buttons/tonal-fixed-button";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Contact } from "../../../domain/models/contact";
import { AddHospitalDoctorToGoVaxx } from "../../../domain/usages/add-hospital-doctor-to-govaxx";
import { RemoveHospitalDoctorFromGoVaxx } from "../../../domain/usages/remove-hospital-doctor-from-govaxx";
import { HospitalDoctorVaccinator } from "../../../domain/usages/add-hospital-doctor-to-vaccinator";

type Props = {
  id: string;
  hospital: Hospital;
  specialities: Speciality[];
  addDoctor: AddHospitalDoctor;
  updateDoctor: UpdateHospitalDoctor;
  addHospitalDoctorToGoVaxx: AddHospitalDoctorToGoVaxx;
  removeHospitalDoctorFromGoVaxx: RemoveHospitalDoctorFromGoVaxx;
  HospitalDoctorVaccinator: HospitalDoctorVaccinator;
  successCallback: Function;
};
const HospitalCustomerDetailsForm: React.FC<Props> = ({
  id,
  hospital,
  specialities,
  addDoctor,
  updateDoctor,
  addHospitalDoctorToGoVaxx,
  removeHospitalDoctorFromGoVaxx,
  HospitalDoctorVaccinator,
  successCallback,
}) => {
  const [creating, setCreating] = useState(false);
  const [doctor, setDoctor] = useState<HospitalDoctor>({} as HospitalDoctor);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!hospital.doctors || hospital.doctors.length == 0) {
      setCreating(true);
    }
  }, [hospital]);

  const create = useCallback(
    async (data: any) => {
      setLoading(true);
      const contacts = [];
      if (data.mobile) {
        contacts.push({ value: data.mobile, type: "mobile" } as Contact);
      }
      if (data.email) {
        contacts.push({ value: data.email, type: "email" } as Contact);
      }
      let result: Result = await addDoctor.create(id, {
        full_name: data.full_name,
        speciality: data.speciality,
        contacts: contacts,
      });
      if (!result.errors) {
        Swal.fire("Doctor added to hospital successfully.", "", "success");
        successCallback();
        setCreating(false);
      } else {
        setLoading(false);
      }
    },
    [addDoctor]
  );

  const update = useCallback(
    async (data: any) => {
      setLoading(true);
      const contacts = [];
      if (data.mobile) {
        contacts.push({ value: data.mobile, type: "mobile" } as Contact);
      }
      if (data.email) {
        contacts.push({ value: data.email, type: "email" } as Contact);
      }
      let result: Result = await updateDoctor.update(id, data._id, {
        full_name: data.full_name,
        speciality: data.speciality,
        contacts: contacts,
      });
      if (!result.errors) {
        Swal.fire("Doctor updated successfully.", "", "success");
        setCreating(false);
        successCallback();
      } else {
        setLoading(false);
      }
    },
    [updateDoctor]
  );

  return (
    <div>
      {creating && doctor && (
        <>
          {hospital.doctors && hospital.doctors.length > 0 && (
            <div className="text-right">
              <TonalButton
                icon={faTimes}
                primary={false}
                text={"Close"}
                onClick={() => setCreating(false)}
              />
            </div>
          )}

          <HospitalDoctorForm
            doctor={doctor}
            specialities={specialities}
            successCallback={(data: HospitalDoctor) =>
              data._id ? update(data) : create(data)
            }
          />
        </>
      )}
      {!creating && (
        <HospitalDoctorList
          hosopital_id={hospital._id}
          doctors={hospital.doctors}
          addHospitalDoctorToGoVaxx={addHospitalDoctorToGoVaxx}
          removeHospitalDoctorFromGoVaxx={removeHospitalDoctorFromGoVaxx}
          HospitalDoctorVaccinator={HospitalDoctorVaccinator}
          onItemClick={(doctor: HospitalDoctor) => {
            setDoctor(doctor);
            setCreating(true);
          }}
          successCallback={successCallback}
        />
      )}

      {!creating && hospital.doctors && hospital.doctors.length > 0 && (
        <TonalFixedButton
          icon={faPlus}
          primary={true}
          text={"ADD NEW DOCTOR"}
          onClick={() => setCreating(true)}
        />
      )}
    </div>
  );
};

export default HospitalCustomerDetailsForm;
