import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { CommercialType } from "../../../domain/models/commercial-type";
import { Hospital } from "../../../domain/models/hospital";
import { PatientFocus } from "../../../domain/models/patient-focus";
import { Result } from "../../../domain/models/result";
import { UpdateHospitalCommercialDetails } from "../../../domain/usages/update-hospital-commercial-details";
import { pageRoutes } from "../../../routes";
import TonalFixedButton from "../../components/common/buttons/tonal-fixed-button";
import {
  InputVariant,
  NumberField,
  TextField,
  SelectField,
  Option,
  OptionInput,
  MultiSelectField,
} from "../../components/inputs/index";

type CommercialDetails = {
  avg_opd_per_day: number;
  hospital_type: string;
  predominant_patient_focus: string[];
  stocks_vaccine: boolean;
  no_of_beds: number;
  no_of_icu_beds: number;
};

type Props = {
  id: string;
  hospital: Hospital;
  patientFocuses: PatientFocus[];
  commercialTypes: CommercialType[];
  updateCommercialDetails: UpdateHospitalCommercialDetails;
  successCallback: Function;
};
const HospitalCommercialDetailsForm: React.FC<Props> = ({
  id,
  hospital,
  patientFocuses,
  commercialTypes,
  updateCommercialDetails,
  successCallback,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CommercialDetails>({
    defaultValues: {
      avg_opd_per_day: hospital.avg_opd_per_day ?? 0,
      hospital_type: hospital.hospital_type,
      predominant_patient_focus: hospital.predominant_patient_focus,
      stocks_vaccine: hospital.stocks_vaccine == "Yes" ? true : false,
      no_of_beds: hospital.no_of_beds,
      no_of_icu_beds: hospital.no_of_icu_beds,
    },
  });

  const onSubmit = useCallback(
    async (data: CommercialDetails) => {
      setLoading(true);
      let result: Result = await updateCommercialDetails.update(id, {
        hospital_type: data.hospital_type,
        predominant_patient_focus: data.predominant_patient_focus,
        avg_opd_per_day: data.avg_opd_per_day,
        stocks_vaccine: data.stocks_vaccine ? "Yes" : "No",
        no_of_beds: data.no_of_beds,
        no_of_icu_beds: data.no_of_icu_beds,
      });
      if (!result.errors) {
        Swal.fire("Hospital details updated successfully.", "", "success");
        successCallback();
      } else {
        setLoading(false);
      }
    },
    [updateCommercialDetails]
  );
  return (
    <>
      <div className="p-4">
        <Controller
          name="hospital_type"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectField
              className="p-0"
              value={{ value: value, key: value }}
              placeholder="Select Hospital Commercial Type"
              label="Select Hospital Commercial Type"
              onChange={(value: any) => onChange(value.value)}
              error={error && error.message}
              options={commercialTypes.map(
                (ct) => ({ key: ct.name, value: ct.name } as Option)
              )}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />

        <Controller
          name="predominant_patient_focus"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <MultiSelectField
              value={value
                .map((v: any) => patientFocuses.find((cv: any) => cv.name == v))
                .map((v: any) => ({ key: v.name, value: v.name } as Option))}
              placeholder="Select Predominant patient focus"
              label="Select Predominant patient focus"
              onChange={(value: any) =>
                onChange(value.map((v: any) => v.value))
              }
              error={error && error.message}
              options={patientFocuses.map(
                (pf) => ({ key: pf.name, value: pf.name } as Option)
              )}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />

        <Controller
          name="no_of_beds"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              placeholder="Number of beds"
              label="Number of beds"
              onChange={onChange}
              error={error && error.message}
            />
          )}
          rules={{
            required: {
              value: true,
              message: "Required",
            },
            min: {
              value: 1,
              message: "Cannot be zero",
            },
          }}
        />

        <Controller
          name="no_of_icu_beds"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              placeholder="Number of ICU beds"
              label="Number of ICU beds"
              onChange={onChange}
              error={error && error.message}
            />
          )}
        />

        <Controller
          name="avg_opd_per_day"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <NumberField
              value={value}
              placeholder="Avg. OPD per day"
              label="Avg. OPD per day"
              onChange={onChange}
              error={error && error.message}
            />
          )}
          rules={{
            required: {
              value: true,
              message: "Average OPD per day is required",
            },
            min: {
              value: 1,
              message: "Avg OPD cannot be zero",
            },
          }}
        />

        <Controller
          name="stocks_vaccine"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <OptionInput
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              label="Stocks Vaccine?"
            />
          )}
        />
      </div>
      <TonalFixedButton
        icon={faSave}
        onClick={handleSubmit(onSubmit)}
        text="Click here to save details"
      />
    </>
  );
};

export default HospitalCommercialDetailsForm;
