import React from "react";
import "../../../../date.extension";
import { Chemist } from "../../../../domain/models/chemist";
import {
  Card,
  CardBody,
  CardColor,
  CardRightContent,
  CardSubHeadline,
  CardSupportingText,
  CardTags,
} from "../../../ga-components/cards";
import { Chip, ChipColor, ChipType } from "../../../ga-components/chip";
import Clickable from "../../../ga-components/clickable";

type Props = {
  chemist: Chemist;
  onClick: () => void;
  active?: boolean;
};

const ChemistItem: React.FC<Props> = ({ chemist, onClick, active = false }) => {
  return (
    <Clickable onClick={onClick}>
      <Card color={!active ? CardColor.WHITE : CardColor.PRIMARY_LOW}>
        <CardBody className="pb-0">
          <CardSupportingText>#{chemist._id}</CardSupportingText>
          <CardSubHeadline>{chemist.full_name}</CardSubHeadline>
          <CardRightContent>
            <CardSupportingText className="text-right">
              <span className="">{chemist.full_name} </span>
              <div>Mobile: {chemist.mobile}</div>
            </CardSupportingText>
          </CardRightContent>

          <CardSupportingText className="mt-2">
            <span className="mr-2">CREATED AT:</span>
          </CardSupportingText>
        </CardBody>
        <CardTags>
          <Chip
            type={ChipType.SUGGESTION}
            color={ChipColor.PRIMARY_MEDIUM}
            text={chemist.status}
            className="uppercase"
          />
        </CardTags>
      </Card>
    </Clickable>
  );
};

export default ChemistItem;
