import { EmptyResponse, Result } from "../../domain/models/result";
import { ConfirmFileUpload } from "../../domain/usages/confirm-file-upload";
import { GetReadLink } from "../../domain/usages/get-read-link";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteGetReadLink implements GetReadLink {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(uuid: string, params: ConfirmFileUpload.Params): Promise<Result> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":id", uuid),
      query: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = {
        message: "Server error. Please try after sometime.",
      };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
