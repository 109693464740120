import React, { useState, useCallback, useEffect } from "react";
import { FetchDoctorList } from "../../../domain/usages/fetch-doctor-list";
import AppHeader from "../../components/app-header";
import ListHandler from "../../components/common/list/ListHandler";
import SectionTitle from "../../ga-components/typography/section-title";
import ClinicDetails from "./ClinicDetails";
import ChemistDetails from "./ChemistDetails";
import DoctorSampling from "./DoctorSampling";
import DoctorListCard from "../../components/doctor/doctor-list-card";
import { FindDoctor } from "../../../domain/usages/find-doctor";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import { FetchDoctorMasters } from "../../../domain/usages/fetch-doctor-masters";
import { DoctorMaster } from "../../../domain/models/doctor-master";
import { Result } from "../../../domain/models/result";
import { Doctor } from "../../../domain/models/doctor";
import { UpdateDoctorClinic } from "../../../domain/usages/update-doctor-clinic";
import { Button } from "../../ga-components/buttons";
import ButtonColor from "../../ga-components/buttons/button-color";
import { UpdateDoctorChemist } from "../../../domain/usages/update-doctor-chemist";
import { AddDoctorListToClinic } from "../../../domain/usages/add-doctor-list-to-clinic";
import { UpdateDoctorVerification } from "../../../domain/usages/update-doctor-verification";
import { UpdateClinicVerification } from "../../../domain/usages/update-clinic-verification";
import { UpdateChemistVerification } from "../../../domain/usages/update-chemist-verification";
import Clickable from "../../ga-components/clickable";
import DoctorVerificationModal from "../sections/doctor/modal/DoctorVerificationModal";
import { Pagination } from "../../ga-components/pagination";
import Swal from "sweetalert2";
import { Constants } from "../../../common/Constants";
import Utils from "../../../common/Utils";
import SweetAlertMessageType from "../../../common/constants-types/sweet-alert-message";
import { Icon, IconColor } from "../../ga-components/icon";
import DoctorSearchFilter, {
  DoctorSearchDetails,
} from "../sections/doctor/DoctorSearchFilter";
import { DoctorSearch } from "../../../domain/models/doctor-search";
import FetchState from "../../../domain/enums/fetch-state-type";
import DoctorStatus from "../../../domain/enums/doctor-status";
import DoctorTitle from "../../components/doctor/DoctorTitle";
import { UpdateDoctorSamplingVerification } from "../../../domain/usages/update-doctor-sampling-verification";

type Props = {
  fetchDoctorList: FetchDoctorList;
  getReadLink: GetReadLink;
  findDoctor: FindDoctor;
  fetchMasters: FetchDoctorMasters;
  updateClinic: UpdateDoctorClinic;
  updateChemist: UpdateDoctorChemist;
  addDoctorToClinic: AddDoctorListToClinic;
  updateDoctorVerification: UpdateDoctorVerification;
  updateClinicVerification: UpdateClinicVerification;
  updateChemistVerification: UpdateChemistVerification;
  updateDoctorSamplingVerification: UpdateDoctorSamplingVerification;
};

const DoctorInfoPage: React.FC<Props> = ({
  fetchDoctorList,
  getReadLink,
  findDoctor,
  fetchMasters,
  updateClinic,
  updateChemist,
  addDoctorToClinic,
  updateDoctorVerification,
  updateClinicVerification,
  updateChemistVerification,
  updateDoctorSamplingVerification,
}) => {
  const [modalOn, setModalOn] = useState(false);
  const [isShowClinicDetails, setIsShowClinicDetails] = useState(false);
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [loading, setLoading] = useState(false);
  const [isShowChemistDetails, setIsShowChemistDetails] = useState(false);
  const [master, setMaster] = useState<DoctorMaster>({} as DoctorMaster);
  const [selectedDoctorDetails, setSelectedDoctorDetails] = useState<Doctor>(
    {} as Doctor
  );
  const [isDoctorRejected, setIsDoctorRejected] = useState(false);
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [currentPage, setCurrentPage] = useState(Constants.INTIAL_PAGE_NUMBER);
  const [searchDoctorList, setSearchDoctorList] = useState<DoctorSearch>(
    {} as DoctorSearch
  );
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [totalDoctorsCount, setTotalDoctorsCount] = useState<number>(0);
  const INTIAL_MAX_PAGE_NUMBER_LIMIT = 2;
  const INITAL_MIN_PAGE_NUMBER_LIMIT = 0;
  const PAGE_NUMBER_LIMIT = 2;
  const DOCTORS_PER_PAGE = 200;

  const fetchMasterData = useCallback(async () => {
    let result: Result = await fetchMasters.fetch({});
    if (result.success) {
      setMaster({
        ageGroups: result.ageGroups,
        genders: result.genders,
        qualifications: result.qualifications,
        specialities: result.specialities,
        orgCodes: result.orgCodes,
      } as DoctorMaster);
    }
  }, [fetchMasters]);

  const fetchDoctorDetails = useCallback(async () => {
    searchDoctorList.page = currentPage;
    try {
      setFetchState(FetchState.LOADING);
      let result: Result = await fetchDoctorList.fetch(searchDoctorList);

      if (result.success == true && result.total > 0) {
        setDoctors(result.doctors);
        setTotalDoctorsCount(result.total);
        setFetchState(FetchState.SUCCESS);
      } else {
        setFetchState(FetchState.NO_RESULT);
      }
    } catch (err) {
      setFetchState(FetchState.ERROR);
    }
  }, [fetchDoctorList, currentPage]);

  useEffect(() => {
    fetchDoctorDetails();
  }, [loading, currentPage]);

  const showClinicDetails = () => {
    setIsShowClinicDetails(true);
    setIsShowChemistDetails(false);
  };

  const showChemistDetails = () => {
    setIsShowClinicDetails(false);
    setIsShowChemistDetails(true);
  };

  useEffect(() => {
    fetchMasterData();
  }, []);

  const onDoctorSelected = (index: number) => {
    selectedDoctorDetails._id = doctors[index]._id;
    selectedDoctorDetails.full_name = doctors[index].full_name;
    selectedDoctorDetails.verification_status =
      doctors[index].verification_status;
    selectedDoctorDetails.speciality = doctors[index].speciality;
    selectedDoctorDetails.qualifications = doctors[index].qualifications;
    selectedDoctorDetails.addresses = doctors[index].addresses ?? [];
    selectedDoctorDetails.rejection_reason = doctors[index].rejection_reason;
    selectedDoctorDetails.verification_flags =
      doctors[index].verification_flags;
    setSelectedDoctorDetails({ ...selectedDoctorDetails });
    setIsShowClinicDetails(false);
    setIsShowChemistDetails(false);
  };

  const updateStatus = async (status: string, rejectionReason: any) => {
    setLoading(true);
    let result: Result = await updateDoctorVerification.update(
      selectedDoctorDetails._id,
      {
        verification_status: status,
        rejection_reason: rejectionReason.value,
      }
    );
    if (!result.errors) {
      selectedDoctorDetails.verification_status = status;
      selectedDoctorDetails.rejection_reason = rejectionReason.value;
      setSelectedDoctorDetails(selectedDoctorDetails);
      setLoading(false);
      Swal.fire(
        Constants.DOCTOR_VERFICATION_SUCCESS,
        "",
        SweetAlertMessageType.SUCCESS
      );
    } else {
      Swal.fire(result.errors.message, "", SweetAlertMessageType.ERROR);
    }
  };

  const handleSearch = (value: any) => {
    searchDoctorList.q = value.q;
    searchDoctorList.status = value.status.value;
    searchDoctorList.sampling = value.sampling;
    setCurrentPage(Constants.INTIAL_PAGE_NUMBER);
    setSearchDoctorList(searchDoctorList);
    fetchDoctorDetails();
  };

  const onAppove = () => {
    setModalOn(true);
    setIsDoctorRejected(false);
  };

  const onReject = () => {
    setModalOn(true);
    setIsDoctorRejected(true);
  };

  return (
    <>
      <AppHeader></AppHeader>
      <div>
        <div className="flex justify-between ml-2">
          <SectionTitle title={`Doctors (${doctors.length})`} />
        </div>

        <div className="grid xl:grid-cols-5 gap-10">
          <div className="row-span-6 ml-0.5 h-screen border-solid border-r-2 border-gray-400 rounded-lg overflow-y-scroll w-64 ml-2">
            <div className="grid">
              <div className="ml-56">
                <Clickable onClick={() => setShowFilter(!showFilter)}>
                  {!showFilter && (
                    <Icon icon="filter" color={IconColor.PRIMARY} />
                  )}
                  {showFilter && (
                    <Icon icon="cancel" color={IconColor.SECONDARY} />
                  )}
                </Clickable>
              </div>

              <div className="ml-2 w-56">
                {showFilter && (
                  <DoctorSearchFilter
                    onSubmit={(value: DoctorSearchDetails) =>
                      handleSearch(value)
                    }
                    statusOptions={[
                      DoctorStatus.PENDING,
                      DoctorStatus.VERIFIED,
                      DoctorStatus.REJECTED,
                    ]}
                  />
                )}
              </div>
            </div>
            <div className="grid grid-cols-1">
              {fetchState === FetchState.LOADING && <p>fetching loading...</p>}
              {fetchState === FetchState.ERROR && (
                <>
                  <p>something went wrong</p>
                </>
              )}
              <Pagination
                postsPerPage={DOCTORS_PER_PAGE}
                totalPosts={totalDoctorsCount}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                initialMinPageNumberLimit={INITAL_MIN_PAGE_NUMBER_LIMIT}
                initialMaxPageNumberLimit={INTIAL_MAX_PAGE_NUMBER_LIMIT}
                pageNumberLimit={PAGE_NUMBER_LIMIT}
              />
              {fetchState === FetchState.NO_RESULT && <p>No records found</p>}
              {fetchState === FetchState.SUCCESS && (
                <ListHandler
                  total={doctors.length}
                  list={doctors}
                  loading={false}
                >
                  {fetchState === FetchState.SUCCESS &&
                    doctors &&
                    doctors.map((doctor, index) => (
                      <DoctorListCard
                        key={doctor._id}
                        doctor={doctor}
                        onClick={() => {
                          onDoctorSelected(index);
                        }}
                        active={
                          selectedDoctorDetails &&
                          selectedDoctorDetails._id == doctor._id
                        }
                      />
                    ))}
                </ListHandler>
              )}
            </div>
          </div>
          {Object.keys(selectedDoctorDetails).length != 0 && (
            <>
              <div className="col-span-2 font-bold">
                <DoctorTitle
                  title={`Doctor's Information`}
                  doctorDetails={selectedDoctorDetails}
                />
              </div>
              <div className="gap-5">
                <div className="mt-3 flex items-center">
                  <label>
                    <strong>Status:</strong>
                  </label>

                  {Utils.isDoctorPending(
                    selectedDoctorDetails.verification_status
                  ) && (
                    <div className="flex gap-x-2 ml-5 mb-5">
                      <Button
                        onClick={onAppove}
                        color={ButtonColor.SUCCESS}
                        text="Approve"
                        className="hover:bg-secondary hover:-translate-y-1 transform transition  focus:bg-secondary_low focus:text-primary mr-2"
                      />
                      <Button
                        onClick={onReject}
                        color={ButtonColor.ERROR}
                        text="Reject"
                        className="hover:bg-secondary hover:-translate-y-1 transform transition  focus:bg-secondary_low focus:text-primary"
                      />
                    </div>
                  )}
                  {Utils.isDoctorVerified(
                    selectedDoctorDetails.verification_status
                  ) && (
                    <>
                      <span className="text-green-600 text-lg ml-3">
                        <strong>{Constants.APPROVED}</strong>
                      </span>
                    </>
                  )}
                  {Utils.isDoctorRejected(
                    selectedDoctorDetails.verification_status
                  ) && (
                    <>
                      <span className="text-red-600 text-lg mr-2 ml-3">
                        <strong>{Constants.REJECTED}</strong>
                      </span>
                      <br />
                    </>
                  )}

                  {modalOn && (
                    <DoctorVerificationModal
                      setModalOn={setModalOn}
                      successCallback={updateStatus}
                      doctorName={selectedDoctorDetails.full_name}
                      showRejectMessage={isDoctorRejected}
                      reasonsForRejection={[
                        Constants.ADDRESS_MISMATCH_WITH_CLINIC_IMAGE,
                      ]}
                    />
                  )}
                </div>

                {Utils.isDoctorRejected(
                  selectedDoctorDetails.verification_status
                ) && (
                  <span>
                    <strong className="mr-2">Reason:</strong>
                    {selectedDoctorDetails.rejection_reason}
                  </span>
                )}
              </div>
              <br />

              <div className="grid grid-flow-col auto-cols-max gap-x-10 col-span-4">
                <Button
                  onClick={showClinicDetails}
                  color={ButtonColor.PRIMARY}
                  text="CLINIC DETAILS"
                  className="hover:bg-secondary hover:-translate-y-1 transform transition focus:bg-secondary_low focus:text-primary"
                />
                <Button
                  onClick={showChemistDetails}
                  color={ButtonColor.PRIMARY}
                  text="CHEMIST DETAILS"
                  className="hover:bg-secondary hover:-translate-y-1 transform transition  focus:bg-secondary_low focus:text-primary"
                />
                <div className="ml-52">
                  <DoctorSampling
                    setLoading={setLoading}
                    selectedDoctorDetails={selectedDoctorDetails}
                    updateDoctorSamplingVerification={
                      updateDoctorSamplingVerification
                    }
                    orgCodes={master.orgCodes}
                  />
                </div>
              </div>

              <div className="col-span-4">
                {master &&
                  (isShowClinicDetails ? (
                    <ClinicDetails
                      id={selectedDoctorDetails._id}
                      findDoctor={findDoctor}
                      getReadLink={getReadLink}
                      specialities={master.specialities}
                      qualifications={master.qualifications}
                      updateClinic={updateClinic}
                      addDoctorToClinic={addDoctorToClinic}
                      updateClinicVerification={updateClinicVerification}
                      master={master}
                    />
                  ) : null)}
                {isShowChemistDetails ? (
                  <ChemistDetails
                    id={selectedDoctorDetails._id}
                    findDoctor={findDoctor}
                    getReadLink={getReadLink}
                    updateChemist={updateChemist}
                    updateChemistVerification={updateChemistVerification}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DoctorInfoPage;
