import { EmptyResponse, Result } from "../../domain/models/result";
import { GenerateUploadUrl } from "../../domain/usages/generate-upload-url";
import { HttpPostClient } from "../protocols/http/http-post-client";

export class RemoteGenerateUploadUrl implements GenerateUploadUrl {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async generate(params: GenerateUploadUrl.Params): Promise<Result> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = {
        message: "Server error. Please try after sometime.",
      };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
