import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Constants } from "../../../common/Constants";
import pneumococcalVaccineTypes from "../../../common/constants-types/pneumococcal-vaccine";
import { CompetitorVaccine } from "../../../domain/models/competitor-vaccine";
import { Hospital } from "../../../domain/models/hospital";
import { Result } from "../../../domain/models/result";
import { UpdateHospitalCompetitionDetails } from "../../../domain/usages/update-hospital-competition-details";
import TonalFixedButton from "../../components/common/buttons/tonal-fixed-button";
import { NumberField } from "../../components/inputs";
import { InputVariant, OptionInput } from "../../components/inputs/index";
import Clickable from "../../ga-components/clickable";

type CompetitionDetails = {
  stocks_flu_vaccine: boolean;
  uses_prevnar: boolean;
  synflorix: boolean;
  pneumovax: boolean;
  pneumosil: boolean;
  none: boolean;
  others: boolean;
  synflorixQuantity: number;
  pneumosilQuantity: number;
  pneumovaxQuantity: number;
};

const defaultVaccineValues = (hospital: Hospital) => {
  const vaccineDetails: CompetitionDetails = {} as CompetitionDetails;
  if (hospital.stocks_flu_vaccine == Constants.IS_STOCKS_FLU_VACCINE_TRUE) {
    vaccineDetails.stocks_flu_vaccine = true;
  } else {
    vaccineDetails.stocks_flu_vaccine = false;
  }
  if (hospital.uses_prevnar == Constants.IS_USES_PREVNAR_TRUE) {
    vaccineDetails.uses_prevnar = true;
  } else {
    vaccineDetails.uses_prevnar = false;
  }
  hospital.uses_other_pneumococal_vaccine.map((vaccine) => {
    if (vaccine.name == pneumococcalVaccineTypes.SYNFLORIX) {
      vaccineDetails.synflorix = true;
      vaccineDetails.synflorixQuantity = vaccine.quantity;
    }
    if (vaccine.name == pneumococcalVaccineTypes.PNEUMOSIL) {
      vaccineDetails.pneumosil = true;
      vaccineDetails.pneumosilQuantity = vaccine.quantity;
    }

    if (vaccine.name == pneumococcalVaccineTypes.PNEUMOVAX) {
      vaccineDetails.pneumovax = true;
      vaccineDetails.pneumovaxQuantity = vaccine.quantity;
    }
    if (vaccine.name == pneumococcalVaccineTypes.OTHERS) {
      vaccineDetails.others = true;
    }
    if (vaccine.name == pneumococcalVaccineTypes.NONE) {
      vaccineDetails.none = true;
    }
  });

  return vaccineDetails;
};
type Props = {
  id: string;
  hospital: Hospital;
  competitorVaccines: CompetitorVaccine[];
  updateCompetitionDetails: UpdateHospitalCompetitionDetails;
  successCallback: Function;
};
const HospitalCompetitionDetailsForm: React.FC<Props> = ({
  id,
  hospital,
  competitorVaccines,
  updateCompetitionDetails,
  successCallback,
}) => {
  const [showQuanity, setShowQuanity] = useState(false);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<CompetitionDetails>({
    mode: "onChange",
    defaultValues: defaultVaccineValues(hospital),
  });

  const onSubmit = useCallback(
    async (data) => {
      const usesOtherPneumococalVaccine = [
        {
          name: data.synflorix ? pneumococcalVaccineTypes.SYNFLORIX : "",
          quantity: Number(data.synflorixQuanity),
        },
        {
          name: data.pneumosil ? pneumococcalVaccineTypes.PNEUMOSIL : "",
          quantity: Number(data.pneumosilQuanity),
        },
        {
          name: data.pneumovax ? pneumococcalVaccineTypes.PNEUMOVAX : "",
          quantity: Number(data.pneumovaxQuanity),
        },
        {
          name: data.others ? pneumococcalVaccineTypes.OTHERS : "",
          quantity: 0,
        },
        {
          name: data.none ? pneumococcalVaccineTypes.NONE : "",
          quantity: 0,
        },
      ];

      const filteredUsesOtherPneumococalVaccine =
        usesOtherPneumococalVaccine.filter((vaccine) => vaccine.name !== "");
      let result: Result = await updateCompetitionDetails.update(id, {
        stocks_flu_vaccine: data.stocks_flu_vaccine
          ? Constants.IS_STOCKS_FLU_VACCINE_TRUE
          : Constants.IS_STOCKS_FLU_VACCINE_FALSE,
        uses_prevnar: data.uses_prevnar
          ? Constants.IS_USES_PREVNAR_TRUE
          : Constants.IS_USES_PREVNAR_FALSE,
        uses_other_pneumococal_vaccine: filteredUsesOtherPneumococalVaccine,
      });
      if (!result.errors) {
        Swal.fire("Hospital details updated successfully.", "", "success");
        successCallback();
      }
    },
    [updateCompetitionDetails]
  );

  const handleClick = () => {
    setShowQuanity(!showQuanity);
  };

  return (
    <>
      <div className="p-4">
        <Controller
          name="stocks_flu_vaccine"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <OptionInput
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              label="Influvac available"
            />
          )}
        />

        <Controller
          name="uses_prevnar"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <OptionInput
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              label="Uses Prevnar 13?"
            />
          )}
        />
        <Clickable onClick={handleClick}>
          <Controller
            name="synflorix"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <OptionInput
                value={value}
                onChange={onChange}
                variant={InputVariant.FILLED}
                error={error && error.message}
                label="Synflorix"
              />
            )}
          />
        </Clickable>

        {getValues("synflorix") && (
          <Controller
            name="synflorixQuantity"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <NumberField
                value={value}
                error={error && error.message}
                onChange={onChange}
                placeholder="Enter Quantity"
                label="Synflorix Quantity"
              />
            )}
          />
        )}

        <Clickable onClick={handleClick}>
          <Controller
            name="pneumosil"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <OptionInput
                value={value}
                onChange={onChange}
                variant={InputVariant.FILLED}
                error={error && error.message}
                label="Pneumosil"
              />
            )}
          />
        </Clickable>

        {getValues("pneumosil") && (
          <Controller
            name="pneumosilQuantity"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <NumberField
                value={value}
                error={error && error.message}
                onChange={onChange}
                placeholder="Enter Quantity"
                label="Pneumosil Quantity"
              />
            )}
          />
        )}
        <Clickable onClick={handleClick}>
          <Controller
            name="pneumovax"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <OptionInput
                value={value}
                onChange={onChange}
                variant={InputVariant.FILLED}
                error={error && error.message}
                label="Pneumovax"
              />
            )}
          />
        </Clickable>

        {getValues("pneumovax") && (
          <Controller
            name="pneumovaxQuantity"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <NumberField
                value={value}
                error={error && error.message}
                onChange={onChange}
                placeholder="Enter Quantity"
                label="Pneumovax Quantity"
              />
            )}
          />
        )}

        <Controller
          name="others"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <OptionInput
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              label="Others"
            />
          )}
        />
        <Controller
          name="none"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <OptionInput
              value={value}
              onChange={onChange}
              variant={InputVariant.FILLED}
              error={error && error.message}
              label="None"
            />
          )}
        />
      </div>
      <TonalFixedButton
        icon={faSave}
        onClick={handleSubmit(onSubmit)}
        text="Click here to save details"
      />
    </>
  );
};

export default HospitalCompetitionDetailsForm;
