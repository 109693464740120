import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { GetImageRejectionReasons } from "../../../../domain/usages/data-entry/doctor-verification/get-image-rejection-reasons";

type Props = {
  openDialog: boolean;
  handleVerify: Function;
  id: string;
  getRejectionReasons: GetImageRejectionReasons;
  handleClose: Function;
};

type rejectionReasons = {
  code: string;
  message: string;
};

const RejectionReasonsList: React.FC<Props> = ({
  openDialog,
  handleVerify,
  id,
  getRejectionReasons,
  handleClose,
}) => {
  const [selectedRejectionReason, setSelectedRejectionReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [rejectionReasons, setRejectionReasons] = useState<rejectionReasons[]>(
    []
  );

  const getReasons = async () => {
    let result = await getRejectionReasons.get();
    if (result.success) {
      setRejectionReasons(result.data.reasons);
    }
  };

  useEffect(() => {
    getReasons();
  }, [id, openDialog]);

  const handleItemClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRejectionReason((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog open={openDialog}>
      <DialogTitle>Select Image Rejection Reason:</DialogTitle>
      <List sx={{ pt: 0 }}>
        {rejectionReasons.map((reason, index) => {
          return (
            <ListItem button key={index}>
              <RadioGroup
                value={selectedRejectionReason}
                onChange={(event) => handleItemClick(event)}
              >
                <FormControlLabel
                  value={reason.code}
                  control={<Radio color="warning" />}
                  label={
                    <Typography>{reason.message?.toUpperCase()}</Typography>
                  }
                />
              </RadioGroup>
            </ListItem>
          );
        })}
      </List>
      {selectedRejectionReason == "OTHER" && (
        <div style={{ marginInline: 20, marginBottom: 10 }}>
          <TextField
            fullWidth
            value={otherReason}
            label="Enter Reason"
            onChange={(e) => setOtherReason(e.target.value)}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          gap: 10,
          justifyContent: "center",
          paddingBottom: 10,
        }}
      >
        <Button
          variant="text"
          color="warning"
          onClick={() => handleVerify(selectedRejectionReason, otherReason)}
        >
          Confirm Rejection
        </Button>
        <Button variant="text" color="error" onClick={() => handleClose()}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export default RejectionReasonsList;
