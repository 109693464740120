import {
  AUTH_HEADER,
  AUTH_TOKEN,
  HOSPITAL_DOCTOR_SERVICE_API_URL,
} from "../base";
import { RemoteAddDoctorForSampling } from "../data/usecases/remote-add-doctor-for-sampling";
import { RemoteFetchDoctorList } from "../data/usecases/remote-fetch-doctor-list";
import { RemoteRemoveDoctorFromSampling } from "../data/usecases/remote-remove-doctor-from-sampling";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import DoctorPage from "../presentation/pages/doctor-page";

type Props = {
  onSelect: Function;
  selected?: number;
};

export const DoctorPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: `Bearer ${atob(token)}` });

  const remoteFetchDoctorList = new RemoteFetchDoctorList(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors`,
    axiosHttpClient
  );

  const remoteAddDoctorForSampling = new RemoteAddDoctorForSampling(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/add-for-sampling`,
    axiosHttpClient
  );

  const remoteRemoveDoctorFromSampling = new RemoteRemoveDoctorFromSampling(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/doctors/:id/remove-from-sampling`,
    axiosHttpClient
  );

  return (
    <DoctorPage
      fetchDoctorList={remoteFetchDoctorList}
      addDoctorForSampling={remoteAddDoctorForSampling}
      removeDoctorFromSampling={remoteRemoveDoctorFromSampling}
      {...props}
    />
  );
};
