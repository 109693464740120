import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

type Props = {
  openDialog: boolean;
  handleClose: Function;
  handleApprove: Function;
};

const ConfirmSubmit: React.FC<Props> = ({
  openDialog,
  handleClose,
  handleApprove,
}) => {
  return (
    <Dialog open={openDialog}>
      <DialogTitle>
        {" "}
        Are you sure you want to submit details?{" "}
      </DialogTitle>

      <div style={{ display: "flex", justifyContent: "end", margin: 10 }}>
        <Button variant="text" color="warning" onClick={() => handleApprove()}>
          Confirm
        </Button>
        <Button variant="text" color="error" onClick={() => handleClose()}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export default ConfirmSubmit;
