import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Doctor } from "../../../domain/models/doctor";
import { Qualification } from "../../../domain/models/qualification";
import { Result } from "../../../domain/models/result";
import { Speciality } from "../../../domain/models/speciality";
import { UpdateDoctorPracticeDetails } from "../../../domain/usages/update-doctor-practice-details";
import TonalFixedButton from "../../components/common/buttons/tonal-fixed-button";
import { Option, SelectField } from "../../components/inputs";
import { MultiSelectField } from "../../components/inputs/index";

type PracticeDetails = {
  speciality: string;
  qualifications: string[];
};
type Props = {
  id: string;
  doctor: Doctor;
  specialities: Speciality[];
  qualifications: Qualification[];
  updatePracticeDetails: UpdateDoctorPracticeDetails;
  successCallback: Function;
};
const DoctorPracticeDetailsForm: React.FC<Props> = ({
  id,
  doctor,
  specialities,
  qualifications,
  updatePracticeDetails,
  successCallback,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PracticeDetails>({
    defaultValues: {
      speciality: doctor.speciality,
      qualifications: doctor.qualifications,
    },
  });

  const onSubmit = useCallback(
    async (data: PracticeDetails) => {
      setLoading(true);
      console.log(data);
      let result: Result = await updatePracticeDetails.update(id, {
        speciality: data.speciality,
        qualifications: data.qualifications,
      });
      if (!result.errors) {
        Swal.fire("Hospital details updated successfully.", "", "success");
        successCallback();
      } else {
        setLoading(false);
      }
    },
    [updatePracticeDetails]
  );
  return (
    <>
      <div className="p-4">
        <Controller
          name="speciality"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectField
              className="p-0"
              value={{ value: value, key: value }}
              placeholder="Select Speciality"
              label="Select Speciality"
              onChange={(value: any) => onChange(value.value)}
              error={error && error.message}
              options={specialities.map(
                (s) => ({ key: s.name, value: s.name } as Option)
              )}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />

        <Controller
          name="qualifications"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <MultiSelectField
              value={value
                .map((v: any) => qualifications.find((cv: any) => cv.name == v))
                .map((v: any) => ({ key: v.name, value: v.name } as Option))}
              placeholder="Select Qualifications"
              label="Select Qualifications"
              onChange={(value: any) =>
                onChange(value.map((v: any) => v.value))
              }
              error={error && error.message}
              options={qualifications.map(
                (q) => ({ key: q.name, value: q.name } as Option)
              )}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
      </div>
      <TonalFixedButton
        icon={faSave}
        onClick={handleSubmit(onSubmit)}
        text="Click here to save details"
      />
    </>
  );
};

export default DoctorPracticeDetailsForm;
