import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import SectionTitle from "../ga-components/typography/section-title";

const UnderMaintenancePage = () => {
  const renderUnAuthorizedIcon = () => {
    return (
      <FontAwesomeIcon className={""} icon={faExclamationTriangle} size="8x" />
    );
  };

  const renderUnAuthorizedTitle = () => {
    return <SectionTitle title="Under Maintenance!!" />;
  };

  return (
    <div className="flex h-screen justify-center items-center">
      <div className="text-center">
        <span className="text-xl"> {renderUnAuthorizedIcon()}</span>
        <span className="text-3xl">{renderUnAuthorizedTitle()}</span>
      </div>
    </div>
  );
};

export default UnderMaintenancePage;
