import React, { useCallback } from "react";
import { Doctor } from "../../../domain/models/doctor";
import { AddDoctorForSampling } from "../../../domain/usages/add-doctor-for-sampling";
import { RemoveDoctorFromSampling } from "../../../domain/usages/remove-doctor-from-sampling";
import DoctorCard from "../../components/doctor/doctor-card";
import { Result } from "../../../domain/models/result";
import Swal from "sweetalert2";

type Props = {
  doctors: Doctor[];
  addDoctorForSampling: AddDoctorForSampling;
  removeDoctorFromSampling: RemoveDoctorFromSampling;
  onItemClick: Function;
  successCallback: Function;
};

const DoctorList: React.FC<Props> = ({ doctors, addDoctorForSampling, removeDoctorFromSampling, onItemClick,   successCallback, }) => {
  const addForSampling = useCallback(
    async (id: any) => {
      let result: Result = await addDoctorForSampling.add(
        id,
        {}
      );
      if (result.success == true) {
        Swal.fire(result.message, "", "success");
        successCallback();
      } else {
        Swal.fire(result.errors.message, "", "error");
      }
    },
    [addDoctorForSampling]
  );

  const removeFromSampling = useCallback(
    async (id: any) => {
      let result: Result = await removeDoctorFromSampling.remove(
        id,
        {}
      );
      if (result.success == true) {
        Swal.fire(result.message, "", "success");
        successCallback();
      } else {
      }
    },
    [removeDoctorFromSampling]
  );


  return (
    <div className="p-2">
      {doctors &&
        doctors.map((d, index) => (
          <DoctorCard
            key={d._id}
            doctor={d}
            addForSampling={addForSampling}
            removeFromSampling={removeFromSampling}
            onClick={() => onItemClick(d._id)}
          />
        ))}
    </div>
  );
};

export default DoctorList;
