import React from "react";
import { Clinic } from "../../../../domain/models/clinic";
import ClinicItem from "./clinic-item";

type Props = {
  clinics: Clinic[] | undefined;
  onItemClick: (clinic: Clinic) => void;
  selectedClinic?: Clinic;
};

const ClinicList: React.FC<Props> = ({
  clinics,
  selectedClinic,
  onItemClick,
}) => {
  return (
    <>
      {clinics &&
        clinics.map((c) => (
          <ClinicItem
            clinic={c}
            key={c._id}
            onClick={() => onItemClick(c)}
            active={selectedClinic && selectedClinic._id == c._id}
          />
        ))}
    </>
  );
};

export default ClinicList;
