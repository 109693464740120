import React, { useEffect } from "react";
import { Doctor } from "../../../domain/models/doctor";
import Clickable from "../../ga-components/clickable";
import {
  Card,
  CardBody,
  CardColor,
  CardSupportingText,
} from "../../ga-components/cards";
import ApproveIcon from "../../../images/approve.svg";
import PendingIcon from "../../../images/pending.svg";
import RejectIcon from "../../../images/rejected.svg";
import DoctorStatus from "../../../domain/enums/doctor-status";

type HeaderProps = {
  title: string;
  speciality: string;
  doctorVerificationStatus: string;
  id: string;
};
const HospitalHeader: React.FC<HeaderProps> = ({
  title,
  speciality,
  id,
  doctorVerificationStatus,
}) => {
  const statusIcons: Record<string, string> = {
    [DoctorStatus.PENDING]: PendingIcon,
    [DoctorStatus.VERIFIED]: ApproveIcon,
    [DoctorStatus.REJECTED]: RejectIcon,
  };

  return (
    <div>
      #{id}
      <br />
      <div className="text-black text-base">
        <strong>{title}</strong>
      </div>
      {speciality && <div className="text-primary ">{speciality}</div>}
      {doctorVerificationStatus && (
        <div className="text-primary w-6 text-base absolute right-0 top-0">
          <img src={statusIcons[doctorVerificationStatus]} alt=""></img>
        </div>
      )}
    </div>
  );
};

type Props = {
  doctor: Doctor;
  onClick: Function;
  active?: boolean;
};

const DoctorListCard: React.FC<Props> = ({
  doctor,
  onClick,
  active = false,
}) => {
  return (
    <>
      <Clickable onClick={onClick}>
        <Card color={!active ? CardColor.PRIMARY_LOW : CardColor.SECONDARY_LOW}>
          <CardBody>
            <CardSupportingText>
              <HospitalHeader
                id={doctor._id}
                title={doctor.full_name}
                speciality={doctor.speciality}
                doctorVerificationStatus={doctor.verification_status}
              />
            </CardSupportingText>
          </CardBody>
        </Card>
      </Clickable>
    </>
  );
};

export default DoctorListCard;
