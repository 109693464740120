import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { Address } from "../../../../../domain/models/doctor/address";
import { PostOffice } from "../../../../../domain/models/post-office";
import { AddressBasedOnPincode } from "../../../../../domain/models/address-based-on-pincode";

type Props = {
  existingAddressDetails: Address[];
  isVerified: boolean[];
  handlePendingAddress: Function;
  newaddressDetails: NewAddressType[];
  handleChange: Function;
  removeAddressField: Function;
  addAddressField: Function;
  formErrors: FormError;
  postalAddress: AddressBasedOnPincode[];
};

type NewAddressType = {
  line: string;
  landmark: string;
  pincode: number;
  area: string;
  district: string;
  state: string[];
  country: string[];
};

type FormError = {
  line: string[];
  landmark: string[];
  pincode: string[];
  area: string[];
  district: string[];
  state: string[];
  country: string[];
};

const AddressField: React.FC<Props> = ({
  existingAddressDetails,
  isVerified,
  handlePendingAddress,
  newaddressDetails,
  handleChange,
  removeAddressField,
  addAddressField,
  formErrors,
  postalAddress,
}) => {
  let regex = /(^|\s)\S/g;

  const landmarkRegrex = /^(?=(?:.*[a-zA-Z0-9]){15,}).*$/;
  const lineRegrex = /^(?=(?:.*[a-zA-Z0-9]){15,}).*$/;
  return (
    <div>
      <Typography variant="subtitle2">Address:</Typography>
      {existingAddressDetails?.length > 0 &&
        existingAddressDetails?.map((address, index) => {
          if (address.line) {
            return (
              <div
                style={{
                  marginBlock: 10,
                  border: "1px solid gray",
                  borderRadius: "8px",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="subtitle2">Address:</Typography>
                  {isVerified[index] ? (
                    <Tooltip title="Verified">
                      <CheckCircleOutlineIcon color="success" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Pending, click to verify">
                      <ReportGmailerrorredIcon
                        color="warning"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handlePendingAddress(index, "address")}
                      />
                    </Tooltip>
                  )}
                </div>
                <Typography>
                  {[
                    address?.line,
                    address?.landmark,
                    address?.area,
                    address?.district,
                    address?.state,
                    address?.pincode,
                  ]
                    .filter((el) => {
                      return el != null && el != "";
                    })
                    .join(", ")
                    ?.toLowerCase()
                    .replace(regex, (letter) => letter.toUpperCase())}
                </Typography>
              </div>
            );
          }
        })}
      {newaddressDetails.map((data, index) => {
        return (
          <Grid container spacing={2} sx={{ marginBottom: 5 }}>
            <Grid item xs={4}>
              <TextField
                name="Line"
                label="Line"
                placeholder="Enter Line..."
                value={data.line}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(index, "line", event.target.value)
                }
                fullWidth
                error={
                  (data.line && !lineRegrex.test(data.line)) ||
                  formErrors?.line[index]
                    ? true
                    : false
                }
                helperText={
                  data.line && !lineRegrex.test(data.line)
                    ? "Enter minimum 15 alphanumeric characters"
                    : formErrors?.line[index]
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="Lankmark"
                placeholder="Enter Landmark..."
                label="Landmark"
                value={data.landmark}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(index, "landmark", event.target.value)
                }
                fullWidth
                error={
                  (data.landmark && !landmarkRegrex.test(data.landmark)) ||
                  formErrors?.landmark[index]
                    ? true
                    : false
                }
                helperText={
                  data.landmark && !landmarkRegrex.test(data.landmark)
                    ? "Enter minimum 15 alphanumeric characters"
                    : formErrors?.landmark[index]
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="Area"
                placeholder="Enter Area..."
                label="Area"
                value={data.area}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(index, "area", event.target.value)
                }
                fullWidth
                error={formErrors?.area[index] ? true : false}
                helperText={formErrors?.area[index]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="District"
                placeholder="Enter District..."
                label="District"
                value={
                  postalAddress[index]?.district
                    ? postalAddress[index]?.district
                    : ""
                }
                fullWidth
                error={formErrors?.district[index] ? true : false}
                helperText={formErrors?.district[index]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="State"
                label="State"
                value={
                  postalAddress[index]?.state ? postalAddress[index]?.state : ""
                }
                fullWidth
                error={formErrors?.state[index] ? true : false}
                helperText={formErrors?.state[index]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="Pincode"
                placeholder="Enter Pincode..."
                label="Pincode"
                value={data.pincode ? data.pincode.toString() : null}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(index, "pincode", event.target.value)
                }
                fullWidth
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={formErrors?.pincode[index] ? true : false}
                helperText={formErrors?.pincode[index]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="Country"
                label="Country"
                value={
                  postalAddress[index]?.country
                    ? postalAddress[index]?.country
                    : ""
                }
                fullWidth
                error={formErrors?.country[index] ? true : false}
                helperText={formErrors?.country[index]}
              />
            </Grid>
            <IconButton onClick={() => removeAddressField(index)}>
              <Tooltip title="Cancel">
                <RemoveCircleOutlineIcon color="error" />
              </Tooltip>
            </IconButton>
          </Grid>
        );
      })}

      <Button variant="text" color="warning" onClick={() => addAddressField()}>
        <AddIcon /> ADD ADDRESS
      </Button>
    </div>
  );
};

export default AddressField;
