import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../../../routes";
import { Stack, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

type SideBarProps = {
  Menus: {
    title: string;
    icon: string;
    highlightedIcon?: string;
    route: string;
    onTap: Function;
  }[];
  showSideBar: boolean;
  handleExpandSideBar: Function;
};
const SideBar = ({ Menus, showSideBar, handleExpandSideBar }: SideBarProps) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    // if (window.location.pathname == pageRoutes.doctorsCountSummary) {
    Menus[0].onTap();
    setSelectedMenuItem(0);
    // }
  }, []);

  const handleItemClick = (index: number) => {
    setSelectedMenuItem(index);
    Menus[index].onTap();
    // navigate(Menus[index].route);
  };

  return (
    <div className="scrollBarStyles">
      <Stack
        direction={"column"}
        spacing={2}
        border={"1px solid lightgrey"}
        height="100vh"
        paddingTop={2}
      >
        <Stack marginLeft={1} sx={{ cursor: "pointer" }}>
          <MenuIcon color="warning" onClick={() => handleExpandSideBar()} />
        </Stack>

        {Menus.map((item, index) => {
          return (
            <Stack
              sx={{ cursor: "pointer" }}
              direction={"row"}
              spacing={1.5}
              key={index}
              padding={2}
              bgcolor={selectedMenuItem === index ? "warning.light" : "white"}
              onClick={() => handleItemClick(index)}
            >
              <img
                src={
                  selectedMenuItem === index ? item.highlightedIcon : item.icon
                }
                width={20}
                height={30}
              />
              {showSideBar && (
                <Typography
                  variant="subtitle2"
                  fontWeight={550}
                  color={selectedMenuItem === index ? "white" : "grey"}
                >
                  {item.title}
                </Typography>
              )}
            </Stack>
          );
        })}
      </Stack>
    </div>
  );
};
export default SideBar;
