import { Constants } from "../../common/Constants";
import { EmptyResponse, Result } from "../../domain/models/result";
import { RemoveDoctorFromSampling } from "../../domain/usages/remove-doctor-from-sampling";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteRemoveDoctorFromSampling
  implements RemoveDoctorFromSampling
{
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async remove(
    id: string,
    params: RemoveDoctorFromSampling.Params
  ): Promise<Result> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: Constants.SERVER_ERROR };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
