import { debounce, min } from "lodash";
import { useState } from "react";
import { Constants } from "../../common/Constants";

type PaginationProps = {
  postsPerPage: number;
  totalPosts: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  pageNumberLimit: number;
  initialMaxPageNumberLimit: number;
  initialMinPageNumberLimit: number;
};

export const Pagination = ({
  postsPerPage,
  totalPosts,
  setCurrentPage,
  currentPage,
  initialMinPageNumberLimit,
  initialMaxPageNumberLimit,
  pageNumberLimit,
}: PaginationProps) => {
  let pageNumbers = [];
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState<number>(
    initialMaxPageNumberLimit
  );
  const [minPageNumberLimit, setMinPageNumberLimit] = useState<number>(
    initialMinPageNumberLimit
  );
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleClick = (event: any) => {
    setCurrentPage(Number(event.target.id));
  };

  const onNextButton = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const onPreviousButton = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageNumberLimit(currentPage);
      setMinPageNumberLimit(currentPage - 2);
    } else {
      setMaxPageNumberLimit(maxPageNumberLimit - 1);
      setMinPageNumberLimit(minPageNumberLimit - 1);
    }
  };

  const renderPageNumber = pageNumbers.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number.toString()}
          onClick={handleClick}
          className={`h-8 p-1 border-2 border-solid border-blue-300 cursor-pointer ${
            currentPage === number ? "bg-blue-300" : ""
          } `}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  let pageIncrementBtn = null;
  if (pageNumbers.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={onNextButton}>&hellip;</li>;
  }

  let pageDecrementBtn = null;
  if (pageNumbers.length > maxPageNumberLimit) {
    pageDecrementBtn = <li onClick={onPreviousButton}>&hellip;</li>;
  }
  const onLastPageButton = () => {
    setCurrentPage(pageNumbers.length);
    setMaxPageNumberLimit(pageNumbers.length);
    setMinPageNumberLimit(pageNumbers.length - pageNumberLimit);
  };

  const onFirstPageButton = () => {
    setCurrentPage(1);
    setMaxPageNumberLimit(initialMaxPageNumberLimit);
    setMinPageNumberLimit(initialMinPageNumberLimit);
  };

  const updateCurrentPage = (e: any) => {
    setCurrentPage(Number(e.target.value));
    setMaxPageNumberLimit(Number(e.target.value));
    setMinPageNumberLimit(Number(e.target.value - pageNumberLimit));
  };
  const debouncedOnChange = debounce(
    updateCurrentPage,
    Constants.PAGINATION_DEBOUNCE_TIME
  );

  return (
    <div>
      <span className="m-1 text-sm">
        Page:{" "}
        <strong>
          {currentPage} of {pageNumbers.length}
        </strong>
      </span>
      <span className="text-sm">| Go To Page</span>
      <input
        type="text"
        defaultValue={1}
        onChange={debouncedOnChange}
        className="w-8 m-1 border border-black"
      />
      <ul className="flex text-sm">
        <li>
          <button
            onClick={onFirstPageButton}
            disabled={currentPage == pageNumbers[0] ? true : false}
            className={
              "h-8 border-2 border-solid border-blue-300 cursor-pointer rounded-l-xl"
            }
          >
            {"<<"}
          </button>
          <button
            onClick={onPreviousButton}
            disabled={currentPage == pageNumbers[0] ? true : false}
            className={
              "p-1 border-2 border-solid border-blue-300 cursor-pointer"
            }
          >
            previous
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumber}
        {pageIncrementBtn}
        <li>
          <button
            onClick={onNextButton}
            className={
              "p-1 border-2 border-solid border-blue-300 cursor-pointer"
            }
            disabled={
              currentPage == pageNumbers[pageNumbers.length - 1] ? true : false
            }
          >
            next
          </button>
          <button
            onClick={onLastPageButton}
            className={
              "h-8 border-2 border-solid border-blue-300 cursor-pointer rounded-r-xl"
            }
            disabled={
              currentPage == pageNumbers[pageNumbers.length - 1] ? true : false
            }
          >
            {">>"}
          </button>
        </li>
      </ul>
    </div>
  );
};
