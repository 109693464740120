import {
  AUTH_HEADER,
  AUTH_TOKEN,
  HOSPITAL_DOCTOR_SERVICE_API_URL,
} from "../base";
import { RemoteFetchDashboardCount } from "../data/usecases/remote-fetch-dashboard-count";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import DashboardPage from "../presentation/pages/dashboard-page";

type Props = {};

export const DashboardPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();

  const token = storage.get(AUTH_TOKEN);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: `Bearer ${atob(token)}` });

  const remoteFetchDashboardCount = new RemoteFetchDashboardCount(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/dashboard/counts`,
    axiosHttpClient
  );
  return (
    <DashboardPage fetchDashboardCount={remoteFetchDashboardCount} {...props} />
  );
};
