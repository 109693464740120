import { constants } from "buffer";
import React, { useCallback } from "react";
import Swal from "sweetalert2";
import { Constants } from "../../../common/Constants";
import SweetAlertMessageType from "../../../common/constants-types/sweet-alert-message";
import { HospitalDoctor } from "../../../domain/models/hospital-doctor";
import { Result } from "../../../domain/models/result";
import { AddHospitalDoctorToGoVaxx } from "../../../domain/usages/add-hospital-doctor-to-govaxx";
import { HospitalDoctorVaccinator } from "../../../domain/usages/add-hospital-doctor-to-vaccinator";
import { RemoveHospitalDoctorFromGoVaxx } from "../../../domain/usages/remove-hospital-doctor-from-govaxx";
import HospitalDoctorCard from "../../components/hospital/hospital-doctor-card";

type Props = {
  hosopital_id: string;
  doctors: HospitalDoctor[];
  addHospitalDoctorToGoVaxx: AddHospitalDoctorToGoVaxx;
  removeHospitalDoctorFromGoVaxx: RemoveHospitalDoctorFromGoVaxx;
  HospitalDoctorVaccinator: HospitalDoctorVaccinator;
  onItemClick: Function;
  successCallback: Function;
};

const HospitalDoctorList: React.FC<Props> = ({
  hosopital_id,
  doctors,
  addHospitalDoctorToGoVaxx,
  removeHospitalDoctorFromGoVaxx,
  HospitalDoctorVaccinator,
  onItemClick,
  successCallback,
}) => {
  const addToGoVaxx = useCallback(
    async (doctor_id: any) => {
      let result: Result = await addHospitalDoctorToGoVaxx.add(
        hosopital_id,
        doctor_id,
        {}
      );
      if (!result.errors) {
        Swal.fire(
          Constants.DOCTOR_ADDED_TO_GOVAXX,
          "",
          SweetAlertMessageType.SUCCESS
        );
        successCallback();
      } else {
      }
    },
    [addHospitalDoctorToGoVaxx]
  );

  const removeFromGoVaxx = useCallback(
    async (doctor_id: any) => {
      let result: Result = await removeHospitalDoctorFromGoVaxx.remove(
        hosopital_id,
        doctor_id,
        {}
      );
      if (!result.errors) {
        Swal.fire(
          Constants.DOCTOR_REMOVED_FROM_GOVAXX,
          "",
          SweetAlertMessageType.SUCCESS
        );
        successCallback();
      } else {
      }
    },
    [removeHospitalDoctorFromGoVaxx]
  );

  const hospitalVaccinator = useCallback(
    async (doctor_id: any, vaccinator: boolean) => {
      let result: Result = await HospitalDoctorVaccinator.add(
        hosopital_id,
        doctor_id,
        { vaccinator }
      );
      if (!result.errors) {
        Swal.fire(
          Constants.DOCTOR_ADDED_TO_VACCINATOR,
          "",
          SweetAlertMessageType.SUCCESS
        );
        successCallback();
      } else {
        Swal.fire(
          Constants.DOCTOR_FAILED_TO_ADD_VACCINATOR,
          "",
          SweetAlertMessageType.ERROR
        );
      }
    },
    [addHospitalDoctorToGoVaxx]
  );

  return (
    <div className="p-2">
      {doctors &&
        doctors.map((d) => (
          <HospitalDoctorCard
            key={d._id}
            doctor={d}
            addToGovaxx={addToGoVaxx}
            removeFromGoVaxx={removeFromGoVaxx}
            hospitalVaccinator={hospitalVaccinator}
            onClick={() => onItemClick(d)}
          />
        ))}
    </div>
  );
};

export default HospitalDoctorList;
