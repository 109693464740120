import React from "react";

export enum FilledColors {
  PRIMARY,
  PRIMARY_LOW,
  SECONDARY,
  SECONDARY_LOW,
}

type Props = {
  headline: any;
  subhead: string;
  supporting_text: any;
  filledColor: FilledColors;
  actions?: any;
  image?: string;
};
const FilledCard: React.FC<Props> = ({
  headline,
  subhead,
  supporting_text,
  filledColor,
  actions,
  image,
}) => {
  let content = (
    <>
      {image && (
        <div
          className="bg-cover bg-center h-60"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      )}
      <div className="text-2xl font-light pb-2">{headline}</div>
      <div className="text-base font-normal text-gray-500 pb-2">{subhead}</div>
      <div className="text-xs text-gray-500 font-light">{supporting_text}</div>
      <div className="py-4 w-full">
        <div>{actions}</div>
      </div>
    </>
  );

  if (filledColor === FilledColors.PRIMARY)
    return (
      <div className={`rounded-lg px-4 pt-4 mb-2 bg-primary_medium`}>
        {content}
      </div>
    );
  else if (filledColor === FilledColors.PRIMARY_LOW)
    return (
      <div className={`rounded-lg px-4 pt-4 mb-2 bg-primary_low`}>
        {content}
      </div>
    );
  else if (filledColor === FilledColors.SECONDARY)
    return (
      <div className={`rounded-lg px-4 pt-4 mb-2 bg-secondary_medium`}>
        {content}
      </div>
    );
  else if (filledColor === FilledColors.SECONDARY_LOW)
    return (
      <div className={`rounded-lg px-4 pt-4 mb-2 bg-secondary_low`}>
        {content}
      </div>
    );
  return <div></div>;
};

export default FilledCard;
