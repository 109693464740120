import React from "react";
import withTheme from "../theme/withTheme";

type Props = {
  theme: any;
  className?: string;
  children?: React.ReactNode;
};
const Tabs: React.FC<Props> = ({ theme, className = "", children = <></> }) => {
  const cssClass = [className, theme.tab.base].join(" ");
  return <ul className={cssClass}>{children}</ul>;
};

export default withTheme(Tabs);
