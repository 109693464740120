import {
  AUTH_HEADER,
  AUTH_TOKEN,
  HOSPITAL_DOCTOR_SERVICE_API_URL,
} from "../base";
import { RemoteFetchHospitalList } from "../data/usecases/remote-fetch-hospital-list";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import HospitalPage from "../presentation/pages/hospital-page";

type Props = {
  onSelect: Function;
  selected?: number;
};

export const HospitalPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: `Bearer ${atob(token)}` });

  const remoteFetchHospitalList = new RemoteFetchHospitalList(
    `${HOSPITAL_DOCTOR_SERVICE_API_URL}/hospitals`,
    axiosHttpClient
  );

  return (
    <HospitalPage fetchHospitalList={remoteFetchHospitalList} {...props} />
  );
};
