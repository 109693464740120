import { DOCTOR_SERVICE_API_URL } from "../../base";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import Endpoints from "../../domain//endpoints";
import LoginPage from "../../presentation/pages/data-entry/LoginPage";
import { RemoteLoginUser } from "../../data/usecases/data-entry/remote-login-user";
import { LocalLoggedInUser } from "../../data/usecases/local-logged-in-user";

type Props = {};

export const LoginAuthenticateFactory = (props: Props) => {
  const jsonStorage = LocalJsonStorage.getInstance();
  const axiosHttpClient = AxiosHttpClient.getInstance();

  const remoteLogin = new RemoteLoginUser(
    `${DOCTOR_SERVICE_API_URL}${Endpoints.DATA_ENTRY_VERIFICATION_LOGIN}`,

    axiosHttpClient
  );

  const localLoggedInUser = new LocalLoggedInUser(jsonStorage);

  return <LoginPage userLogin={remoteLogin} loggedInUser={localLoggedInUser} />;
};
