import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type Props = {
  text: any;
  icon: any;
  onClick: Function;
  primary?: boolean;
};
const FabButton: React.FC<Props> = ({
  text,
  icon,
  onClick,
  primary = true,
}) => {
  if (primary) {
    return (
      <button
        className="fixed right-3 bottom-20 text-center h-12 px-4 rounded-xl bg-primary shadow-lg"
        onClick={() => onClick()}
      >
        {icon && (
          <span className="w-5 h-5">
            <FontAwesomeIcon icon={icon} className="text-white" />
          </span>
        )}
      </button>
    );
  } else {
    return (
      <button
        className="fixed right-3 bottom-20 text-center h-12 px-4 rounded-xl bg-primary shadow-lg"
        onClick={() => onClick()}
      >
        {icon && (
          <span className="w-4 h-4">
            <FontAwesomeIcon icon={icon} className="text-secondary" />
          </span>
        )}
      </button>
    );
  }
};

export default FabButton;
