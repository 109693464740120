import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  existingEmailDetails: Contacts[];
  isVerified: boolean[];
  handlePendingContacts: Function;
  removeEmailField: Function;
  emailDetails: Contacts[];
  handleEmailChange: Function;
  addEmailField: Function;
  formErrors: string[];
};

type Contacts = {
  type: string;
  value: string;
  status: string;
};

const Email: React.FC<Props> = ({
  existingEmailDetails,
  isVerified,
  handlePendingContacts,
  removeEmailField,
  emailDetails,
  handleEmailChange,
  formErrors,
  addEmailField,
}) => {
  return (
    <div>
      <Typography variant="subtitle2">Email ID:</Typography>
      {existingEmailDetails?.length > 0 &&
        existingEmailDetails?.map((data, index) => {
          return (
            <div style={{ marginBottom: 10, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <TextField
                  value={data.value}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isVerified[index] ? (
                          <Tooltip title="Verified">
                            <CheckCircleOutlineIcon color="success" />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Pending, click to verify">
                            <ReportGmailerrorredIcon
                              color="warning"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handlePendingContacts(index, data.type)
                              }
                            />
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          );
        })}
      <div>
        {emailDetails.map((data, index) => {
          return (
            <div style={{ marginBottom: 10, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <TextField
                  name="email"
                  placeholder="Enter Email ID"
                  value={data.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleEmailChange(index, event.target.value)
                  }
                  error={formErrors[index] ? true : false}
                  helperText={formErrors[index]}
                  fullWidth
                />
                <IconButton onClick={() => removeEmailField(index)}>
                  <Tooltip title="Cancel">
                    <RemoveCircleOutlineIcon color="error" />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          );
        })}
        <Button variant="text" color="warning" onClick={() => addEmailField()}>
          <AddIcon /> ADD Email ID
        </Button>
      </div>
    </div>
  );
};

export default Email;
