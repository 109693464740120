import { EmptyResponse, Result } from "../../domain/models/result";
import { UploadFile } from "../../domain/usages/upload-file";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteUploadFile implements UploadFile {
  constructor(private readonly httpPutClient: HttpPutClient) {}

  async upload(params: UploadFile.Params): Promise<Result> {
    const httpResponse = await this.httpPutClient.put({
      url: params.url,
      body: params.file,
      headers: {
        "Content-Type": params.file.type,
      },
      authHeaders: false,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = {
        message: "Server error. Please try after sometime.",
      };
      return response;
    }
    if (httpResponse.status == 200) {
      return {
        success: true,
        message: "Image uploaded successfully",
      } as Result;
    }
    return { success: false } as Result;
  }
}
