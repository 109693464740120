import React from "react";

type Props = {
  title: any;
  icon: any;
};
const AppBar: React.FC<Props> = ({ title, icon }) => {
  return (
    <div className="p-4">
      <span className="w-6 h-6">{icon}</span>
      <span className="ml-8">{title}</span>
    </div>
  );
};

export default AppBar;
