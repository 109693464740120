import {
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { DoctorSpeciality } from "../../../../../domain/models/doctor/speciality";
import { Speciality } from "../../../../../domain/models/speciality";

type Props = {
  existingSpecialityDetails: DoctorSpeciality[];
  isVerified: boolean[];
  handlePendingSpeciality: Function;
  removeSpecialityField: Function;
  specialityDetails: SpecialityType[];
  handleSpecialityChange: Function;
  addSpecialityField: Function;
  formErrors: string[];
  specialities: Speciality[];
};

type SpecialityType = {
  value: string;
};

const SpecialityField: React.FC<Props> = ({
  existingSpecialityDetails,
  isVerified,
  handlePendingSpeciality,
  removeSpecialityField,
  specialityDetails,
  handleSpecialityChange,
  formErrors,
  addSpecialityField,
  specialities,
}) => {
  return (
    <div>
      <Typography variant="subtitle2">Speciality:</Typography>
      {existingSpecialityDetails?.length > 0 &&
        existingSpecialityDetails?.map((data, index) => {
          return (
            <div style={{ marginBottom: 10, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <TextField
                  value={data.value}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isVerified[index] ? (
                          <Tooltip title="Verified">
                            <CheckCircleOutlineIcon color="success" />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Pending, click to verify">
                            <ReportGmailerrorredIcon
                              color="warning"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handlePendingSpeciality(index, "speciality")
                              }
                            />
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          );
        })}
      <div>
        {specialityDetails.map((data, index) => {
          return (
            <div style={{ marginBottom: 10, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <TextField
                  name="Speciality"
                  select
                  placeholder="Select Speciality"
                  value={data.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleSpecialityChange(index, event.target.value)
                  }
                  error={formErrors[index] ? true : false}
                  helperText={formErrors[index]}
                  fullWidth
                >
                  {specialities.map((speciality, index) => (
                    <MenuItem key={speciality.name} value={speciality.name}>
                      {speciality.name}
                    </MenuItem>
                  ))}
                </TextField>
                <IconButton onClick={() => removeSpecialityField(index)}>
                  <Tooltip title="Cancel">
                    <RemoveCircleOutlineIcon color="error" />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          );
        })}
        <Button
          variant="text"
          color="warning"
          onClick={() => addSpecialityField()}
        >
          <AddIcon /> ADD SPECIALITY
        </Button>
      </div>
    </div>
  );
};

export default SpecialityField;
