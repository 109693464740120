import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SplashFactory } from "./Splash";
import { AuthProvider } from "./UserContext";
import GaThemeProvider from "./presentation/ga-components/theme/GaThemeProvider";
import App from "./App";

require("dotenv").config();

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <GaThemeProvider>
      <AuthProvider>
        <BrowserRouter>
        <App/>
          {/* <SplashFactory /> */}
        </BrowserRouter>
      </AuthProvider>
    </GaThemeProvider>
  </React.StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
