import React from "react";
import { Chemist } from "../../../domain/models/chemist";
import Clickable from "../../ga-components/clickable";
import {
  Card,
  CardBody,
  CardColor,
  CardSupportingText,
} from "../../ga-components/cards";

type HeaderProps = {
  title: string;
  id: string;
};
const DoctorClinicCardHeader: React.FC<HeaderProps> = ({ title, id }) => {
  return (
    <>
      <strong className="text-black text-base">{title}</strong>
      <br />#{id}
    </>
  );
};

type Props = {
  chemist: Chemist;
  onClick: Function;
};
const ChemistCard: React.FC<Props> = ({ chemist, onClick }) => {
  return (
    <Clickable onClick={onClick}>
      <Card color={CardColor.PRIMARY_LOW} className="m-1">
        <CardBody>
          <CardSupportingText>
            <DoctorClinicCardHeader
              title={chemist.full_name}
              id={chemist._id}
            />
          </CardSupportingText>
        </CardBody>
      </Card>
    </Clickable>
  );
};

export default ChemistCard;
