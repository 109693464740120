import React, { useCallback, useEffect, useState } from "react";
import { Clinic } from "../../../domain/models/clinic";
import { Doctor } from "../../../domain/models/doctor";
import ListHandler from "../../components/common/list/ListHandler";
import SectionTitle from "../../ga-components/typography/section-title";
import { FindDoctor } from "../../../domain/usages/find-doctor";
import DoctorClinicCardList from "../list/doctor-clinic-card-list";
import { Result } from "../../../domain/models/result";
import { GetReadLink } from "../../../domain/usages/get-read-link";
import { Qualification } from "../../../domain/models/qualification";
import { Speciality } from "../../../domain/models/speciality";
import { UpdateDoctorClinic } from "../../../domain/usages/update-doctor-clinic";
import DoctorClinicInformationForm from "../../components/doctor/doctor-clinic-information-form";
import AddDoctorToClinicForm from "../../components/doctor/add-doctor-to-clinic-form";
import { AddDoctorListToClinic } from "../../../domain/usages/add-doctor-list-to-clinic";
import { DoctorMaster } from "../../../domain/models/doctor-master";
import { UpdateClinicVerification } from "../../../domain/usages/update-clinic-verification";
import ClinicDoctorCard from "../../components/doctor/clinic-doctor-card";
import ImageDisplay from "../../ga-components/image";

type ClinicDetailsProps = {
  id: string;
  findDoctor: FindDoctor;
  getReadLink: GetReadLink;
  specialities: Speciality[];
  qualifications: Qualification[];
  updateClinic: UpdateDoctorClinic;
  addDoctorToClinic: AddDoctorListToClinic;
  master: DoctorMaster;
  updateClinicVerification: UpdateClinicVerification;
};

const ClinicDetails: React.FC<ClinicDetailsProps> = ({
  id,
  findDoctor,
  getReadLink,
  specialities,
  qualifications,
  updateClinic,
  addDoctorToClinic,
  updateClinicVerification,
  master,
}) => {
  const [doctor, setDoctor] = useState<Doctor>({} as Doctor);
  const [clinic, setClinic] = useState<Clinic>();
  const [link, setLink] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [rotateImage, setRotateImage] = useState(0);

  const getDoctor = useCallback(async () => {
    let result: Result = await findDoctor.find(id);
    if (result.success) {
      setDoctor(result.doctor);
      result.doctor.clinics.map((clinicData) => {
        if (clinic?._id == clinicData._id) {
          setClinic(clinicData);
        }
      });
    }
  }, [findDoctor, id, clinic]);

  const findReadLink = useCallback(async () => {
    if (clinic) {
      let result: Result = await getReadLink.get(clinic.billboard[0].uuid, {});
      setLink(result.url);
    }
  }, [getReadLink, clinic]);

  useEffect(() => {
    getDoctor();
  }, [id, loading]);

  useEffect(() => {
    if (clinic) {
      findReadLink();
    }
  }, [clinic, rotateImage]);

  const onClickClinicDetails = (clinic: Clinic) => {
    setClinic(clinic);
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-6">
        <div className="row-span-6">
          <div className="grid">
            <div className="flex justify-between">
              <SectionTitle title={`Clinic List (${doctor.clinics?.length})`} />
            </div>
          </div>
          <div className="grid grid-cols-1 w-60 h-96 border-solid border-2 border-gray-400 rounded-lg overflow-y-scroll">
            <ListHandler
              total={doctor.clinics?.length}
              list={doctor.clinics}
              loading={false}
            >
              <DoctorClinicCardList
                clinics={doctor.clinics}
                onItemClick={(clinic: Clinic) => {
                  onClickClinicDetails(clinic);
                }}
              />
            </ListHandler>
          </div>
        </div>
        <div className="col-span-1">
          {clinic && (
            <ImageDisplay
              imageLink={link}
              setRotateImage={setRotateImage}
              rotateImage={rotateImage}
            />
          )}
        </div>

        <div>
          {clinic && (
            <div>
              <DoctorClinicInformationForm
                id={id}
                updateClinic={updateClinic}
                clinic={clinic}
                specialities={specialities}
                setLoading={setLoading}
                updateClinicVerification={updateClinicVerification}
              />
            </div>
          )}
        </div>
        <div className="col-start-2  col-span-4">
          <div>
            {clinic?.clinic_doctors && (
              <>
                <span>
                  <strong>{`Doctor Details: (${clinic.clinic_doctors.length})`}</strong>
                  <br />
                </span>
                <div className="w-3/5 h-96 border-solid border-2 border-gray-400 rounded-lg overflow-y-scroll">
                  <ListHandler
                    total={doctor.clinics?.length}
                    list={doctor.clinics}
                    loading={false}
                  >
                    {clinic?.clinic_doctors.map((clinicDoctor) => {
                      return <ClinicDoctorCard clinicDoctor={clinicDoctor} />;
                    })}
                  </ListHandler>
                </div>
              </>
            )}

            <br></br>
          </div>
          <AddDoctorToClinicForm
            addDoctorToClinic={addDoctorToClinic}
            qualifications={qualifications}
            clinic={clinic}
            master={master}
            getDoctor={getDoctor}
            specialities={specialities}
          />
        </div>
      </div>
    </>
  );
};

export default ClinicDetails;
