import { Avatar, Card, CardHeader, Stack, Typography } from "@mui/material";
import React from "react";
import { orange } from "@mui/material/colors";

type Props = {
  title: string;
  count: number;
  type: string;
  avatar: React.ReactNode;
};

const DoctorSummaryCard: React.FC<Props> = ({ title, count, type, avatar }) => {
  return (
    <Card variant="outlined" raised>
      <CardHeader
        title={
          <Typography variant="h6" color="grey">
            {title}
          </Typography>
        }
        subheader={
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography variant="h5" color={"black"}>
              {count}
            </Typography>
            <Typography variant="body1" color="grey">
              {type}
            </Typography>
          </Stack>
        }
        avatar={
          <Avatar
            sx={{ width: 60, height: 60, bgcolor: orange[200] }}
            variant="square"
          >
            {avatar}
          </Avatar>
        }
      />
    </Card>
  );
};

export default DoctorSummaryCard;
