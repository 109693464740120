import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Places from "google-places-web";
import React, { useCallback, useEffect, useState } from "react";
import { GOOGLE_CLOUD_API_KEY } from "../../../base";
import { Address } from "../../../domain/models/address";
import { Hospital } from "../../../domain/models/hospital";
import { Result } from "../../../domain/models/result";
import { FetchHospitalsNearMe } from "../../../domain/usages/fetch-hospitals-near-me";
import { Location } from "../../models/location";
import TonalFixedButton from "../common/buttons/tonal-fixed-button";
import List from "../common/list/list";
import { ListItemModel } from "../common/list/list-item";
import Header, { HeaderSize, HeaderType } from "../common/typography/header";
import withCurrentLocation from "../hoc/withCurrentLocation";

type Props = {
  location: GeolocationCoordinates;
  isLocationAllowed: boolean;
  onHospitalSelection: Function;
  fetchHospitalNearMe: FetchHospitalsNearMe;
};
const NearByHospitals: React.FC<Props> = ({
  location,
  isLocationAllowed,
  onHospitalSelection,
  fetchHospitalNearMe,
}) => {
  Places.apiKey = GOOGLE_CLOUD_API_KEY;
  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isLocationAllowed && location) {
      fetchGeoHospitals(location);
    }
  }, [location, isLocationAllowed]);

  /**
   * Fetch hospitals near me
   */
  const fetchGeoHospitals = useCallback(
    async (location) => {
      setLoading(true);
      let result: Result = await fetchHospitalNearMe.fetch({
        lat_long: location.latitude + "," + location.longitude,
      });
      setLocations(
        result.geo_locations.map((gl) => {
          return {
            name: gl.name,
            formattedAddress: gl.vicinity,
            icon: gl.icon,
            location: {
              lat: gl.geometry.location.lat,
              long: gl.geometry.location.lng,
            },
            placeId: gl.place_id,
          } as Location;
        })
      );
      if (result.geo_locations.length == 0) onHospitalSelection({} as Hospital);
      setLoading(false);
    },
    [fetchHospitalNearMe]
  );

  const onItemSelection = (item: ListItemModel) => {
    const pincode = (item.data.formattedAddress.match(/\d{6}/) || [false])[0];
    const address = {
      line: item.data.formattedAddress,
      lat_long: item.data.location.lat + ", " + item.data.location.long,
      pincode: pincode,
    } as Address;
    const hospital: Hospital = {
      full_name: item.data.name,
      addresses: [address],
      govaxx_hospital_flag: false,
    } as Hospital;
    onHospitalSelection(hospital);
  };

  if (loading) return <div>Fetching locations...</div>;

  if (locations && locations.length == 0) return <div>No Hospitals found.</div>;
  return (
    <div className="h-full">
      <Header
        text={
          "Please select the hospital which you would like to add to your list."
        }
        type={HeaderType.PRIMARY}
        size={HeaderSize.lg}
        className="font-thin"
      />
      <div className="h-5/6 overflow-auto">
        <List
          className=""
          items={locations.map(
            (l) =>
              ({
                id: l.placeId,
                title: l.name,
                subTitle: l.formattedAddress,
                description: "",
                data: l,
              } as ListItemModel)
          )}
          onItemClick={onItemSelection}
        />
      </div>
      <TonalFixedButton
        icon={faArrowRight}
        onClick={() =>
          onHospitalSelection({
            id: "",
            title: "",
            subTitle: "",
            description: "",
            data: {},
          } as ListItemModel)
        }
        text="Click here if Hospital not in list"
      />
    </div>
  );
};

export default withCurrentLocation(NearByHospitals);
