import { EmptyResponse, Result } from "../../domain/models/result";
import { ConfirmFileUpload } from "../../domain/usages/confirm-file-upload";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteConfirmFileUpload implements ConfirmFileUpload {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async confirm(params: ConfirmFileUpload.Params): Promise<Result> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url,
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = {
        message: "Server error. Please try after sometime.",
      };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
