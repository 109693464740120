import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import Chip from "@mui/material/Chip";
import { DoctorData } from "../../../../domain/models/doctor/doctorData";
import Status from "../../../../domain/enums/status";

type Props = {
  doctorDetails: DoctorData;
  onClick: () => void;
  active: boolean;
};

const DoctorCard: React.FC<Props> = ({ doctorDetails, onClick, active }) => {
  let regex = /(^|\s)\S/g;

  const backgroundColor: Record<string, string> = {
    [Status.REJECTED]: "#fee2e2",
    [Status.PENDING]: "#ffedd5",
    [Status.APPROVED]: "#dcfce7",
  };

  return (
    <div style={{ margin: 20 }}>
      <Card
        variant="outlined"
        onClick={onClick}
        sx={{
          borderRadius: "10px",
          border: "1px solid #FFDAB9",
          cursor: "pointer",
          backgroundColor: !active ? "white" : backgroundColor[Status.PENDING],
        }}
      >
        <CardHeader
          title={
            <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                {doctorDetails.fullNames[
                  doctorDetails.fullNames?.length - 1
                ].name
                  ?.toLowerCase()
                  .replace(regex, (letter) => letter.toUpperCase())}
              </Typography>
              {doctorDetails.qualification?.map((data, index) => {
                return (
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: 500 }}
                    key={index}
                  >
                    {index == 0 && "("}
                    {index > 0 && ", "}
                    {data.value}{" "}
                    {index == doctorDetails.qualification.length - 1 && ")"}
                  </Typography>
                );
              })}
            </div>
          }
          subheader={doctorDetails.specialities[
            doctorDetails.specialities.length - 1
          ].value
            ?.toLowerCase()
            .replace(regex, (letter) => letter.toUpperCase())}
          subheaderTypographyProps={{ fontSize: "12px", fontWeight: 400 }}
        />
        <CardContent sx={{ paddingTop: 0 }}>
          <Chip
            label="Pending"
            size="small"
            color="warning"
            sx={{ color: "#F4A261", fontSize: "10px", padding: 1 }}
            variant="outlined"
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default DoctorCard;
